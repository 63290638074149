import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Approve from "../Approve";
import CTMInvoices from "../CTMInvoices";
import NotFound from "./NotFound";
import Dashboard from "../Dashboard";
import DocsAndLinks from "../DocsAndLinks";
import FareForecaster from "../FareForecaster";
import LoadingPage from "../LoadingPage";
import TravelerTracker from "../TravelerTracker";
import { scrollToTop } from "src/common";

export const TRAVELER_TRACKER_ROUTE = "travelertracker";
export const FARE_FORECASTER_ROUTE = "fareforecaster";
export const DOCS_AND_LINKS_ROUTE = "docandlinktool";
export const CTM_APPROVE_ROUTE = "ctmapprove";
export const CTM_INVOICES_ROUTE = "ctminvoices";

export const fullPages = {
  DOCS_AND_LINKS_ROUTE,
  TRAVELER_TRACKER_ROUTE,
  FARE_FORECASTER_ROUTE,
  CTM_APPROVE_ROUTE,
  CTM_INVOICES_ROUTE,
};

const Routes = ({ loading, user, location }) => {
  let hasTT, hasFF, hasDL, hasApprove, hasCTMInvoices;
  if (user && user.state && user.state.features && user.state.features.length) {
    user.state.features.forEach((f) => {
      if (f.identifier === "Elysium_Traveler_Tracker_Widget") hasTT = true;
      if (f.identifier === "Elysium_Fare_Forecaster_Widget") hasFF = true;
      if (f.identifier === "Elysium_Docs_And_Link") hasDL = true;
      if (f.identifier === "approve") hasApprove = true;
      if (f.identifier === "ctminvoices") hasCTMInvoices = true;
    });
  }

  useEffect(() => {
    scrollToTop();
  }, [location.pathname, location.search]);

  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />

      {hasTT && (
        <Route
          path={`/${TRAVELER_TRACKER_ROUTE}`}
          component={TravelerTracker}
        />
      )}
      {hasFF && (
        <Route path={`/${FARE_FORECASTER_ROUTE}`} component={FareForecaster} />
      )}
      {hasDL && (
        <Route path={`/${DOCS_AND_LINKS_ROUTE}`} component={DocsAndLinks} />
      )}
      {hasApprove && (
        <Route path={`/${CTM_APPROVE_ROUTE}`} component={Approve} />
      )}
      {hasCTMInvoices && (
        <Route path={`/${CTM_INVOICES_ROUTE}`} component={CTMInvoices} />
      )}
      {loading ? (
        <Route component={LoadingPage} />
      ) : (
        <Route component={NotFound} />
      )}
    </Switch>
  );
};

export default Routes;
