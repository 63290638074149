import SVGIcon from "src/components/SVGIcon";

const ChatMessageIcon = ({ title = "ChatMessage", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 34 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M4.424 0.823c-1.825 0-3.319 1.493-3.319 3.319v17.908c0 0 0 0 0 0 0 0.458 0.371 0.83 0.83 0.83 0.229 0 0.437-0.093 0.587-0.243l3.561-3.561h14.933c1.825 0 3.319-1.493 3.319-3.319v-11.615c0-1.825-1.493-3.319-3.319-3.319h-16.593zM27.654 9.119v6.637c0 3.666-2.971 6.637-6.637 6.637h-9.956v1.659c0 1.825 1.493 3.319 3.319 3.319h14.933l3.561 3.561c0.15 0.15 0.358 0.243 0.587 0.243 0.458 0 0.83-0.371 0.83-0.83 0-0 0-0 0-0v0-17.908c0-1.825-1.493-3.319-3.319-3.319h-3.319z"
      ></path>
    </SVGIcon>
  );
};

export default ChatMessageIcon;
