import React from "react";
import { Segment, Image } from "semantic-ui-react";
import { setGoogleAnalyticsEvent } from "src/common";

const curYear = new Date().getFullYear();

const Footer = ({ contactInfo }) => {
  const filterContact = (contact) => {
    return (
      contact.name &&
      contact.name.trim().length &&
      contact.number &&
      contact.number.trim().length
    );
  };

  const filteredContacts =
    contactInfo && contactInfo.length ? contactInfo.filter(filterContact) : [];
  return (
    <Segment as="footer" className="footer-main">
      <div className="footer-container">
        <div className="top-section">
          <div className="footer-segment">
            <Image
              src="/images/ctm-logo-landscape.png"
              title="Corporate Travel Management"
              alt="CTM Logo"
            />
          </div>
          <div className="footer-segment">
            <ul className="ctm-links">
              <li>
                <a
                  href="https://www.travelctm.com/global/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://www.travelctm.com/global/terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
          {filteredContacts.length > 0 && (
            <div className="footer-segment">
              <p id="contacts-list">Contacts</p>
              <ul className="ctm-links" aria-labelledby="contacts-list">
                {filteredContacts.map((c, i) => {
                  return (
                    <li key={i}>
                      <span className="link-name">{c.name}: </span>{" "}
                      <a
                        href={`tel:${c.number}`}
                        onClick={() => {
                          setGoogleAnalyticsEvent({
                            action: "portal_dashboard_contact_info_click",
                          });
                        }}
                      >
                        {c.number}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        <p className="copyright">
          Copyright {curYear}. Corporate Travel Management is a leading global
          travel management company, with presence currently throughout
          Australia & New Zealand, the USA, Asia and Europe.
        </p>
      </div>
    </Segment>
  );
};

export default Footer;
