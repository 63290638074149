import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const Impersonating = React.lazy(() => import("./Impersonating"));

function ImpersonatingPage(props) {
  usePageInit("Smart Portal - Impersonating");
  return (
    <Suspense fallback={<Loading addContainer active loadingText="Loading" />}>
      <Impersonating {...props} />
    </Suspense>
  );
}

export default ImpersonatingPage;
