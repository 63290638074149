import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const IESplashPage = React.lazy(() => import("./IESplashPage"));

function IESplashPageWrapper(props) {
  usePageInit("Smart Portal - IE");
  return (
    <Suspense fallback={<Loading addContainer active loadingText="Loading" />}>
      <IESplashPage {...props} />
    </Suspense>
  );
}

export default IESplashPageWrapper;
