import SVGIcon from "src/components/SVGIcon";

const InvoicesIcon = ({ title = "Invoices", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M4.153 0v32h23.695v-25.893h-5.13c-0.366 0-0.733-0.366-0.733-0.733v-5.374h-17.832zM23.45 0.733v3.786h3.664l-3.664-3.786zM10.382 2.199c0.366 0 0.733 0.366 0.733 0.733v0.489c0.977 0.244 1.71 0.855 2.076 1.588 0.244 0.366 0.122 0.855-0.244 1.099s-0.855 0.122-0.977-0.244v0c-0.122-0.244-0.489-0.611-0.855-0.733v2.321c0.366 0.122 0.733 0.244 1.221 0.489 0.611 0.366 1.099 1.099 1.099 1.954s-0.366 1.466-0.855 1.954c-0.366 0.366-0.855 0.489-1.343 0.611v0.489c0 0.366-0.366 0.733-0.733 0.733s-0.733-0.366-0.733-0.733v-0.489c-0.977-0.244-1.71-0.855-2.076-1.588-0.244-0.366-0.122-0.855 0.244-1.099s0.855-0.122 0.977 0.244c0.122 0.244 0.489 0.611 0.855 0.733v-2.321c-0.366-0.122-0.733-0.244-1.221-0.489-0.611-0.366-1.099-1.099-1.099-1.954s0.366-1.588 0.855-1.954c0.366-0.366 0.855-0.489 1.343-0.611v-0.366c0-0.489 0.366-0.855 0.733-0.855zM9.649 5.008c-0.122 0-0.244 0.122-0.366 0.244-0.244 0.244-0.366 0.489-0.366 0.855s0.122 0.489 0.366 0.611c0.122 0.122 0.244 0.122 0.366 0.244v-1.954zM11.237 9.038v1.832c0.122 0 0.244-0.122 0.366-0.244 0.244-0.244 0.366-0.489 0.366-0.733 0-0.366-0.122-0.489-0.366-0.611-0.122-0.122-0.244-0.122-0.366-0.244zM17.466 15.145h5.985c0.366 0 0.733 0.366 0.733 0.733s-0.366 0.733-0.733 0.733h-5.985c-0.366 0-0.733-0.366-0.733-0.733s0.366-0.733 0.733-0.733zM8.55 20.519h14.779c0.366 0 0.733 0.366 0.733 0.733s-0.366 0.733-0.733 0.733h-14.779c-0.366 0-0.733-0.366-0.733-0.733s0.366-0.733 0.733-0.733zM8.55 27.359c-0.366 0-0.733-0.366-0.733-0.733s0.366-0.733 0.733-0.733h14.779c0.366 0 0.733 0.366 0.733 0.733s-0.366 0.733-0.733 0.733z"
      />
    </SVGIcon>
  );
};

export default InvoicesIcon;
