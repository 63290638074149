import { UserI } from "src/types/User";
import {
  ACCESS_TOKEN,
  AUTH_CONFIG,
  DEBTOR_ID,
  EXPIRES_AT,
  ID_TOKEN,
  SCOPES,
} from "src/common/Auth/auth0-variables";
import { User } from "@auth0/auth0-react";
import { DECREMENT_ACTIVE_SESSION, handleErrors, put } from "src/common";
import { getConfig } from "src/apiConfig";

type Params = {
  returnTo: string;
  federated?: boolean;
};

export const decrementUserSession = () => {
  const url = getConfig(DECREMENT_ACTIVE_SESSION).url;
  if (url) {
    return put({ url })
      .then(handleErrors)
      .catch((e) => console.error("error", e));
  } else {
    return Promise.reject(new Error(`Missing ${DECREMENT_ACTIVE_SESSION} url`));
  }
};

export const logoutUser = ({
  user,
  authUser,
  logout,
  redirect,
}: {
  user: UserI;
  authUser: User;
  logout: ({ logoutParams }: { logoutParams: Params }) => void;
  redirect?: string;
}) => {
  decrementUserSession();
  const logoutUrl = authUser["https://www.ctmsmart.com/logoutUrl"];
  const returnTo = redirect || logoutUrl || AUTH_CONFIG.loginUrl;
  const logoutParams: Params = { returnTo };
  if (user.ssoHardLogout) logoutParams.federated = true;
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(EXPIRES_AT);
  localStorage.removeItem(SCOPES);
  localStorage.removeItem(DEBTOR_ID);
  logout({
    logoutParams,
  });
};
