import { useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Loader, Dimmer } from "semantic-ui-react";
import { AUTH_CONFIG } from "src/common/Auth/auth0-variables";
import {
  ACCESS_TOKEN,
  DEBTOR_ID,
  REDIRECT,
  ID_TOKEN,
  EXPIRES_AT,
  SCOPES,
} from "src/common/Auth/auth0-variables";

const AuthCallback = ({ history }) => {
  const { error, user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        history.push("/logout");
      }, 5000);
    }
  }, [error, history]);

  const handleSuccessfulLogin = () => {
    const redirectUrl = localStorage.getItem(REDIRECT);
    if (redirectUrl) {
      localStorage.removeItem(REDIRECT);
      history.push(redirectUrl);
    } else {
      history.push("/");
    }
  };

  const setTokenSession = useCallback(
    (authData) => {
      const { access_token, expires_in, id_token, scope } = authData;

      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify(
        expires_in * 1000 + new Date().getTime()
      );
      // If there is a value on the `scope` param from the authResult,
      // use it to set scopes in the session for the user. Otherwise
      // use the scopes as requested. If no scopes were requested,
      // set it to nothing
      const scopes = scope || AUTH_CONFIG.scope || "";

      localStorage.setItem(ACCESS_TOKEN, access_token);
      localStorage.setItem(ID_TOKEN, id_token);
      localStorage.setItem(EXPIRES_AT, expiresAt);
      localStorage.setItem(SCOPES, JSON.stringify(scopes));
      localStorage.setItem(DEBTOR_ID, user["https://www.ctmsmart.com/debtor"]);

      handleSuccessfulLogin();
    },
    [user]
  );

  useEffect(() => {
    const handleTokenData = async () => {
      const data = await getAccessTokenSilently({ detailedResponse: true });
      setTokenSession(data);
    };
    if (isAuthenticated) {
      handleTokenData();
    }
  }, [isAuthenticated, setTokenSession, getAccessTokenSilently]);
  return (
    <Container fluid style={{ height: "100%" }}>
      <Dimmer active>
        {error ? (
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <span>
              <p>There was an error with your user.</p>
              <p>Redirecting to logout page.</p>
            </span>
          </div>
        ) : (
          <Loader>Please Wait</Loader>
        )}
      </Dimmer>
    </Container>
  );
};

export default AuthCallback;
