import React, { Suspense } from "react";
import { connect } from "react-redux";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const CTMInvoices = React.lazy(() => import("./CTMInvoices"));

function CTMInvoicesPage(props) {
  usePageInit("Smart Portal - Invoices");
  return (
    <Suspense
      fallback={
        <Loading addContainer active loadingText="Loading My Trip Receipts" />
      }
    >
      <CTMInvoices {...props} key={props.location.search} />
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    lightMode: state.application.lightMode,
  };
};

export default connect(mapStateToProps)(CTMInvoicesPage);
