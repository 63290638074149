//import {AIRLINES} from 'src/common/constants';
export const ACTION_TYPE = "TRAVELER_TRACKER";
export const GET_STATUSES = `${ACTION_TYPE}_GET_STATUSES`;
export const GET_STATUSES_INVOKED = `${ACTION_TYPE}_GET_STATUSES_INVOKED`;
export const GET_STATUSES_SUCCESS = `${ACTION_TYPE}_GET_STATUSES_SUCCESS`;

export const getStatuses = (id) => ({ type: GET_STATUSES, id });
export const getStatusesInvoked = (id) => ({ type: GET_STATUSES_INVOKED, id });
export const getStatusesSuccess = (statuses, id) => ({
  type: GET_STATUSES_SUCCESS,
  statuses,
  id,
});
