import {
  fetchAirlinesFailed,
  fetchAirlinesSuccess,
  AIRLINES_FETCH_DATA,
} from "src/redux/actions/airlines";
import { put, call, take, fork, select } from "redux-saga/effects";
import { fetchAirlines } from "src/services/airlines";

export const getAirlinesState = (state) => state.airlines;

export function* fetchAirlinessSaga() {
  try {
    const payload = yield call(fetchAirlines);
    yield put(fetchAirlinesSuccess(payload));
  } catch (ex) {
    yield put(fetchAirlinesFailed(ex));
  }
}

function* airlinesSaga() {
  yield take(AIRLINES_FETCH_DATA);
  const airlines = yield select(getAirlinesState);
  if (!airlines || !airlines.data || !airlines.data.length)
    yield fork(fetchAirlinessSaga);
}

export default airlinesSaga;
