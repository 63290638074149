import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { STATE } from "src/common/constants";
import { handleErrors } from "src/common";

export const getState = () => {
  const url = getConfig(STATE).url;
  if (url) {
    return get({ url })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${STATE} url`));
  }
};
