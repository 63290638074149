import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const DocsandLinks = React.lazy(() => import("./DocsandLinks"));

function DocsandLinksWidget(props) {
  usePageInit("Smart Portal - Docs and Links");
  return (
    <Suspense
      fallback={
        <Loading addContainer active loadingText="Loading Docs and Links" />
      }
    >
      <DocsandLinks {...props} />
    </Suspense>
  );
}

export default DocsandLinksWidget;
