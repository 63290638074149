import { combineReducers } from "redux";

import { default as reducers } from "./reducers";

export { default as rootSaga } from "./sagas";

export const rootReducer = () =>
  combineReducers({
    ...reducers,
  });
