import React, { Suspense } from "react";
import { connect } from "react-redux";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const TravelerTracker = React.lazy(() => import("./TravelerTracker"));

function TravelerTrackerPage(props) {
  usePageInit("Smart Portal - Traveler Tracker");
  return (
    <Suspense
      fallback={
        <Loading
          addContainer
          active
          loadingText={
            props.isGlobalRiskMap
              ? "Loading Risk Map"
              : "Loading Traveler Tracker"
          }
        />
      }
    >
      <TravelerTracker
        {...props}
        // Use a key to force the map to remount when changing lightmode
        key={props.lightMode}
        lightMode={props.lightMode}
      />
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    lightMode: state.application.lightMode,
  };
};

export default connect(mapStateToProps)(TravelerTrackerPage);
