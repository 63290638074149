import { CONTACT_INFO_SET_DATA } from "src/redux/actions/contactInfo";

export interface ContactI {
  name: string;
  number: string;
}

const contactInfo = (
  state: ContactI[] = null,
  action: {
    type: string;
    payload: ContactI[];
  }
) => {
  switch (action.type) {
    case CONTACT_INFO_SET_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default contactInfo;
