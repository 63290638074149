import {
  fetchStateFailed,
  fetchStateSuccess,
  fetchStateInvoked,
  STATE_FETCH_DATA,
} from "src/redux/actions/user";
import { put, call, takeLatest } from "redux-saga/effects";
import { getState } from "src/services/user";

function* fetchStateGen() {
  try {
    yield put(fetchStateInvoked());
    const payload = yield call(getState);
    yield put(fetchStateSuccess(payload));
  } catch (ex) {
    yield put(fetchStateFailed(ex));
  }
}

function* userSaga() {
  yield takeLatest(STATE_FETCH_DATA, fetchStateGen);
}

export default userSaga;
