import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const Dashboard = React.lazy(() => import("./Dashboard"));

function DashboardPage(props) {
  usePageInit("Smart Portal");
  return (
    <Suspense
      fallback={
        <Loading addContainer active loadingText="Loading CTM Dashboard" />
      }
    >
      <Dashboard {...props} />
    </Suspense>
  );
}

export default DashboardPage;
