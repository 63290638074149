import { FLIGHTDETAILS } from "src/common/constants";
export const FLIGHTDETAILS_FETCH_DATA = `${FLIGHTDETAILS}_GET_DATA`;
export const FLIGHTDETAILS_FETCH_INVOKED = `${FLIGHTDETAILS}_GET_INVOKED`;
export const FLIGHTDETAILS_FETCH_SUCCESS = `${FLIGHTDETAILS}_GET_SUCCESS`;
export const FLIGHTDETAILS_FETCH_FAILED = `${FLIGHTDETAILS}_GET_FAILED`;

export const fetchFlightDetails = (timeFrames, id) => ({
  type: FLIGHTDETAILS_FETCH_DATA,
  timeFrames,
  id,
});
export const fetchFlightDetailsInvoked = (id) => ({
  type: FLIGHTDETAILS_FETCH_INVOKED,
  id,
});
export const fetchFlightDetailsSuccess = (payload, id) => ({
  type: FLIGHTDETAILS_FETCH_SUCCESS,
  payload,
  id,
});
export const fetchFlightDetailsFailed = (error, id) => ({
  type: FLIGHTDETAILS_FETCH_FAILED,
  error,
  id,
});
