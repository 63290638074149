import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import SideSubMenu from "./SideSubMenu";
import { Icon, Image, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import colors from "src/common/colors";
import { useSafeSetState } from "src/common/hooks";

/**
 * Dynamically generated menu containing My Apps, Tools and Widgets along with edit option for each category
 */

const initialState = {
  renderBrandLogo: true,
};

const AsideMenu = ({
  history,
  isCondensed,
  lightMode,
  path,
  links,
  loading,
  obts,
  search,
  ssoLinks,
  tools,
  userLogo,
  widgets,
}) => {
  const [state, safeSetState] = useSafeSetState(initialState);

  const handleImageError = () => {
    safeSetState({ renderBrandLogo: false });
  };

  const renderHomeIcon = path !== "/";
  const params = new URLSearchParams(search);
  const paramId = params.get("id");
  return (
    <aside>
      {renderHomeIcon && (
        <div className="ui borderless vertical aside-menu menu">
          <div className="item">
            <NavLink
              to="/"
              activeStyle={
                lightMode
                  ? { color: colors.lightModeTextColor }
                  : { color: "white" }
              }
              className="dashboard-link"
              data-aut="Aside|Home"
            >
              {isCondensed ? (
                <Popup
                  trigger={<Icon name="home" size="large" />}
                  content={"Dashboard"}
                  on="hover"
                  position="right center"
                  basic
                  style={{ zIndex: 99999, fontSize: "12px" }}
                />
              ) : (
                <Icon name="home" size="large" />
              )}
              <span>Dashboard</span>
            </NavLink>
          </div>
        </div>
      )}
      <div className="brand-logo">
        {state.renderBrandLogo && userLogo && (
          <Image src={userLogo} onError={handleImageError} alt="Brand Logo" />
        )}
      </div>
      {!loading && (ssoLinks.length || obts.length) ? (
        <SideSubMenu
          history={history}
          label="My Apps"
          isCondensed={isCondensed}
          type="ssoLinks"
          isSSO={true}
          items={[...obts, ...ssoLinks]}
        />
      ) : null}
      {tools && tools.length ? (
        <SideSubMenu
          label="Tools"
          isCondensed={isCondensed}
          items={tools}
          path={path}
          paramId={paramId}
        />
      ) : null}
      {links && links.length ? (
        <SideSubMenu label="Links" isCondensed={isCondensed} items={links} />
      ) : null}
      {widgets && widgets.length ? (
        <SideSubMenu
          label="Widgets"
          isCondensed={isCondensed}
          items={widgets}
          isWidget={true}
        />
      ) : null}
    </aside>
  );
};

AsideMenu.propTypes = {
  /** User's widgets */
  widgets: PropTypes.array.isRequired,
  /** User's links and docs*/
  links: PropTypes.array.isRequired,
  /** User's SSO Apps/Links */
  ssoLinks: PropTypes.array.isRequired,
  /** User's Full Page Experiences */
  tools: PropTypes.array.isRequired,
  /** User's Light/Dark Mode Preference */
  lightMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  links: state.links,
  obts: state.obts,
  ssoLinks: state.ssoLinks,
  tools: state.tools,
  widgets: state.widgets,
  lightMode: state.application.lightMode,
});

export default connect(mapStateToProps)(AsideMenu);
