import SVGIcon from "src/components/SVGIcon";

const LocationIcon = ({ title = "Location", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M16 0c-7.111 0-12.8 5.689-12.8 12.8 0 10.524 11.378 18.631 11.947 18.916 0.284 0.142 0.569 0.284 0.853 0.284s0.711-0.142 0.853-0.284c0.427-0.284 11.947-8.391 11.947-18.916 0-7.111-5.689-12.8-12.8-12.8zM16 28.302c-2.56-1.991-9.671-8.391-9.671-15.644 0-5.262 4.267-9.671 9.671-9.671 5.262 0 9.671 4.267 9.671 9.671 0 7.396-7.111 13.653-9.671 15.644zM16 7.964c-2.702 0-4.836 2.133-4.836 4.836s2.133 4.836 4.836 4.836c2.702 0 4.836-2.133 4.836-4.836s-2.133-4.836-4.836-4.836zM16 14.364c-0.853 0-1.564-0.711-1.564-1.564s0.711-1.564 1.564-1.564c0.853 0 1.564 0.711 1.564 1.564s-0.711 1.564-1.564 1.564z"
      ></path>
    </SVGIcon>
  );
};

export default LocationIcon;
