import {
  fetchBIDataFailed,
  fetchBIDataSuccess,
  fetchBIDataInvoked,
  BI_FETCH_DATA,
} from "src/redux/actions/bi";
import { put, call, take, fork, select } from "redux-saga/effects";
import { fetchBIData } from "src/services/bi";
import { shouldMockData } from "src/common";

const getState = (state) => state.bi;
const getFeatureId = (state) => {
  const feature =
    state.widgets &&
    state.widgets.find((w) => w.identifier === "Elysium_BI_Widget");
  return feature ? feature.id : "";
};

async function getMockData() {
  const { mockBIData } = await import("src/common/mocks/data/bi.js");
  return mockBIData();
}

function* fetchBIDataSaga() {
  try {
    yield put(fetchBIDataInvoked());
    const id = yield select(getFeatureId);
    const payload = shouldMockData()
      ? yield getMockData()
      : yield call(fetchBIData, id);
    yield put(fetchBIDataSuccess(payload));
  } catch (ex) {
    yield put(fetchBIDataFailed(ex));
  }
}

function* biSaga() {
  yield take(BI_FETCH_DATA);
  const bi = yield select(getState);
  if (bi && bi.data.length) yield put(fetchBIDataSuccess(bi.data));
  else yield fork(fetchBIDataSaga);
}

export default biSaga;
