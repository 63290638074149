export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK,
  loginUrl: process.env.REACT_APP_AUTH0_LOGIN_URL,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  responseType: "token id_token",
  scope: "openid email profile",
};

export const LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL;
export const PREFIX = "smartportal";
export const ACCESS_TOKEN = `${PREFIX}_access_token`;
export const ID_TOKEN = `${PREFIX}_id_token`;
export const DEBTOR_ID = `${PREFIX}_debtor_id`;
export const EXPIRES_AT = `${PREFIX}_expires_at`;
export const ROLES = `${PREFIX}_roles`;
export const SCOPES = `${PREFIX}_scopes`;
export const REDIRECT = `${PREFIX}_redirect`;
export const BYPASS = `${PREFIX}_bypass`;
