import { useState } from "react";
import { Icon, SemanticICONS } from "semantic-ui-react";

interface SmartImageI {
  alt: string;
  src: string;
  imageClassName: string;
  secondarySrc?: string;
  semanticIconName?: SemanticICONS;
}

function SmartImage({
  alt,
  src,
  imageClassName,
  secondarySrc,
  semanticIconName,
}: SmartImageI) {
  const [inErrorState, setErrorState] = useState(false);

  function handleError() {
    setErrorState(true);
  }

  if (!inErrorState && src)
    return (
      <img
        src={src}
        className={imageClassName}
        onError={handleError}
        alt={alt}
      />
    );
  if (secondarySrc)
    return <img src={secondarySrc} className={imageClassName} alt={alt} />;
  if (semanticIconName)
    return (
      <Icon
        name={semanticIconName}
        className={imageClassName}
        aria-label={alt}
      />
    );
  else return null;
}

export default SmartImage;
