import { COUNTRIES } from "src/common/constants";
export const COUNTRIES_FETCH_DATA = `${COUNTRIES}_GET_DATA`;
export const COUNTRIES_FETCH_INVOKED = `${COUNTRIES}_GET_INVOKED`;
export const COUNTRIES_FETCH_SUCCESS = `${COUNTRIES}_GET_SUCCESS`;
export const COUNTRIES_FETCH_FAILED = `${COUNTRIES}_GET_FAILED`;

export const fetchCountries = (isGlobalRiskMap) => ({
  type: COUNTRIES_FETCH_DATA,
  isGlobalRiskMap,
});
export const fetchCountriesInvoked = () => ({ type: COUNTRIES_FETCH_INVOKED });
export const fetchCountriesSuccess = (payload) => ({
  type: COUNTRIES_FETCH_SUCCESS,
  payload,
});
export const fetchCountriesFailed = (error) => ({
  type: COUNTRIES_FETCH_FAILED,
  error,
});
