import SVGIcon from "src/components/SVGIcon";

const ConcurIcon = ({ title = "Concur", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M15.323 20.366c-2.384 0-4.321-1.938-4.321-4.32 0-2.383 1.936-4.32 4.321-4.32 1.186 0 2.27 0.484 3.052 1.267l2.242-2.242c-1.357-1.359-3.23-2.199-5.295-2.199-4.133 0-7.495 3.363-7.495 7.494 0 4.134 3.362 7.493 7.495 7.493 2.065 0 3.938-0.84 5.295-2.196l-2.242-2.242c-0.782 0.782-1.866 1.265-3.052 1.265z"
      ></path>
      <path
        fill="currentColor"
        d="M21.621 14.153c-1.044 0-1.89 0.849-1.89 1.894s0.846 1.894 1.89 1.894c1.047 0 1.894-0.849 1.894-1.894s-0.846-1.894-1.894-1.894z"
      ></path>
      <path
        fill="currentColor"
        d="M1.859 30.141h28.283v-28.283h-28.283v28.283zM5.025 26.978v-21.953h21.956v21.953h-21.956z"
      ></path>
    </SVGIcon>
  );
};

export default ConcurIcon;
