import SVGIcon from "src/components/SVGIcon";

const CloudDownloadIcon = ({ title = "CloudDownload", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M27.844 11.252c-0.101-4.022-3.389-7.252-7.433-7.252-2.369 0-4.477 1.109-5.839 2.835-0.764-0.987-1.959-1.624-3.303-1.624-2.307 0-4.176 1.871-4.176 4.179 0 0.201 0.015 0.399 0.043 0.592-0.351-0.063-0.711-0.098-1.080-0.098-3.344-0-6.054 2.712-6.054 6.058s2.71 6.058 6.054 6.058h2.868l7.078 7.328 7.078-7.328 3.484-0c3.004-0.006 5.438-2.444 5.438-5.451 0-2.565-1.771-4.716-4.156-5.296zM16 26l-6-6h4v-6h4v6h4l-6 6z"
      />
    </SVGIcon>
  );
};

export default CloudDownloadIcon;
