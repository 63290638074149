import SVGIcon from "src/components/SVGIcon";

const ArrowIcon = ({ title = "Arrow", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M13.482 24.708l-12.38-12.38c-1.469-1.469-1.469-3.777 0-5.036 1.469-1.469 3.777-1.469 5.036 0v0l9.862 9.862 9.862-9.862c1.469-1.469 3.777-1.469 5.036 0 1.469 1.469 1.469 3.777 0 5.036l-12.38 12.38c-1.469 1.469-3.777 1.469-5.036 0v0z"
      />
    </SVGIcon>
  );
};

export default ArrowIcon;
