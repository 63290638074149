import {
  ITINERARYDETAILS_FETCH_SUCCESS,
  ITINERARYDETAILS_FETCH_INVOKED,
  ITINERARYDETAILS_FETCH_FAILED,
} from "src/redux/actions/itineraryDetails";
import { CarRentalI } from "src/types/CarRental";
import { FlightSegment } from "src/types/Flight";
import { HotelCardI } from "src/types/Hotel";
import { Passenger } from "src/types/Passenger";
import { RailSegmentI } from "src/types/RailSegment";

export type ItineraryI = {
  elapsedTime?: { outbound: number; return: number };
  id: string;
  flights?: { outbound: FlightSegment[]; return: FlightSegment[] };
  carRentals?: CarRentalI[];
  hotels?: HotelCardI[];
  passengers: Passenger[];
  rail: RailSegmentI[];
  prices: {
    total: {
      amount: number;
      currency: string;
    };
  };
};

type ItinState = {
  loading: boolean;
  error?: Error;
  data?: ItineraryI[];
};

export const initialState: {
  ids: {
    [key: string]: ItinState;
  };
} = { ids: {} };

export type ItineraryDetailsI = typeof initialState;

const itineraryDetails = (state = initialState, action): ItineraryDetailsI => {
  const result = { ...state };
  switch (action.type) {
    case ITINERARYDETAILS_FETCH_INVOKED:
      result.ids[action.id] = {
        loading: true,
      };
      return result;

    case ITINERARYDETAILS_FETCH_SUCCESS:
      result.ids[action.id] = {
        loading: false,
        data: action.payload,
      };
      return result;

    case ITINERARYDETAILS_FETCH_FAILED:
      result.ids[action.id] = {
        loading: false,
        error: action.error,
      };
      return result;

    default:
      return state;
  }
};

export default itineraryDetails;
