import React from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import ArrowIcon from "src/icons/ArrowIcon";
import { DropdownOption } from "src/types";

/**
 * An extension of semantic's dropdown component to make our custom UI tweaks more easily reusable.
 */

interface DropDownI {
  allowWrap?: boolean;
  "aria-label"?: string;
  centered?: boolean;
  dataAut?: string;
  disabled?: boolean;
  error?: boolean;
  leftIcon?: React.FC<any>;
  minCharacters?: number;
  id?: string;
  onChange: (
    val: string | number | boolean | (string | number | boolean)[]
  ) => void;
  onSearchChange?: () => void;
  options: DropdownOption[];
  value?: string | string[] | number | number[];
  loading?: boolean;
  multiple?: boolean;
  placeholder?: string;
  search?:
    | boolean
    | ((options: DropdownItemProps[], value: string) => DropdownItemProps[]);
}

const DropDown = React.memo(function MyComponent(props: DropDownI) {
  const {
    allowWrap,
    centered,
    dataAut,
    disabled,
    error,
    minCharacters,
    id,
    onChange,
    onSearchChange,
    options,
    value,
    loading,
    multiple,
    placeholder,
    search,
  } = props;
  // Give our component custom classes to style based on props
  let constructedClassName = "flight-picker-dropdown";
  const LeftIcon = props.leftIcon;
  if (!LeftIcon) constructedClassName += " no-left-icon-dropdown";
  if (centered) constructedClassName += " centered-dropdown";
  if (allowWrap) constructedClassName += " allow-wrap-dropdown";
  if (disabled) constructedClassName += " disabled";
  return (
    <div className={constructedClassName}>
      <Dropdown
        aria-label={props["aria-label"] || ""}
        minCharacters={minCharacters}
        data-aut={dataAut}
        disabled={disabled}
        error={error}
        compact
        fluid
        loading={loading}
        multiple={multiple}
        id={id}
        search={search}
        selection
        value={value}
        onChange={(ev, { value }) => onChange(value)}
        onSearchChange={onSearchChange}
        options={options}
        placeholder={placeholder || ""}
      />
      {LeftIcon && <LeftIcon className="dropdown-left-icon" />}
      {!loading && <ArrowIcon className="arrow" />}
    </div>
  );
});

export default DropDown;
