import {
  AIRLINES_FETCH_SUCCESS,
  AIRLINES_FETCH_FAILED,
} from "src/redux/actions/airlines";

type AirlineImage = {
  Image: string;
  Name: string;
  SecondaryImage: string;
};

type AirlineCodes = {
  [key: string]: AirlineImage;
};

export interface AirlineState {
  loading?: boolean;
  error?: Error;
  images?: AirlineCodes;
}

export const initialState: AirlineState = {
  loading: false,
  error: null,
};

const airlines = (
  state = initialState,
  action: {
    type: string;
    payload?: {
      airlineCodes: AirlineCodes;
    };
    error?: Error;
  }
) => {
  switch (action.type) {
    case AIRLINES_FETCH_SUCCESS:
      return {
        loading: false,
        error: null,
        images: action.payload.airlineCodes,
      };

    case AIRLINES_FETCH_FAILED:
      return {
        loading: false,
        error: action.error,
        images: null,
      };

    default:
      return state;
  }
};

export default airlines;
