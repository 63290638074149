import React from "react";
import { Loading } from "src/components";

function LoadingPage() {
  const asideMenu = document.getElementsByClassName("aside-menu")[0];
  let menuWidth = 77;
  if (asideMenu)
    menuWidth =
      document.getElementsByClassName("aside-menu")[0].offsetWidth || 77;
  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: `calc(100vw - ${menuWidth}px)`,
      }}
    >
      <Loading active loadingText="Loading User Data" />
    </div>
  );
}

export default LoadingPage;
