import { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { Menu as SUIMenu, Checkbox, Icon, Popup } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  toggleWidgetEdit,
  updateWidgetPropsSaga,
  updateWidgetOrderSaga,
} from "src/redux/actions";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { setGoogleAnalyticsEvent, toSpinalCase } from "src/common";

import { handleSSORedirect } from "src/services/ssoRedirect";
import SettingsIcon from "src/icons/SettingsIcon";

/**
 * Dynamically generated menu containing My Apps, Tools and Widgets along with edit option for each category
 */

const SideSubMenu = ({
  history,
  impersonation,
  isCondensed,
  isSSO,
  isWidget,
  isWidgetVisible,
  items,
  label,
  paramId,
  path,
  toggleWidgetEdit,
  updateWidgetOrderSaga,
  updateWidgetPropsSaga,
  widgetEditMode,
  widgets,
}) => {
  const headerRef = useRef();

  const iconStatus = () => {
    if (isWidget && widgetEditMode) return "green";
    else return "";
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateWidgetOrderSaga(oldIndex, newIndex);
  };

  const toggleEditMode = () => {
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_widget_edit_toggle",
      category: (!widgetEditMode).toString(),
    });
    toggleWidgetEdit();
  };

  const handleToggle = (id) => {
    const index = widgets.findIndex((widget) => widget.id === id);
    const props = { ...widgets[index].properties };
    props.isVisible = !props.isVisible;
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_widget_toggle_visibility",
      category: widgets[index].identifier,
      label: props.isVisible,
    });
    updateWidgetPropsSaga(index, props);
  };

  const renderToggle = (item) => {
    if (isWidget && widgetEditMode) {
      return (
        <Checkbox
          toggle
          checked={item.properties.isVisible}
          onClick={() => {
            handleToggle(item.id);
          }}
        />
      );
    }
  };

  const handleSSOLinkClick = (item, history, isImpersonating) => {
    handleSSORedirect(item, history, isImpersonating);
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_tile_click",
      category: item.identifier,
      label: "sidebar_link",
    });
  };

  const renderLink = (item) => {
    const href = isWidget ? `#widget${item.id}` : item.href || "#";
    // We want to render absolute paths and specific relative file links as <a> tags
    const isAbsoluteOrFile =
      href.indexOf("http") === 0 || href.indexOf(".pdf") >= 0;

    if (isSSO) {
      let url;
      if (item.isDirectLink) url = item.href;
      return (
        <a
          href={url}
          target={item.openInNewWindow ? "_blank" : undefined}
          rel="noreferrer"
          data-aut={`Aside|SSOLink|${item.label}`}
          onClick={
            !url
              ? () =>
                  handleSSOLinkClick(
                    item,
                    history,
                    impersonation?.isImpersonating
                  )
              : undefined
          }
          tabIndex={0}
        >
          {item.icon || item.component ? renderIcon(item) : <div></div>}
          {item.subLabel ? (
            <div className="label-wrapper">
              <div>{item.label}</div>
              <div className="sub-label">{item.subLabel}</div>
            </div>
          ) : (
            <span>{item.label}</span>
          )}
        </a>
      );
    }

    if (isAbsoluteOrFile) {
      return (
        <a
          href={item.href || "#"}
          target={item.target ? item.target : "_blank"}
          rel="noreferrer"
          data-aut={`Aside|AbsoluteLink|${item.label}`}
          onClick={() =>
            setGoogleAnalyticsEvent({
              action:
                item.type === "Link"
                  ? "portal_dashboard_link_click"
                  : "portal_dashboard_document_click",
            })
          }
        >
          {renderIcon(item, true)}
          <span>{item.label}</span>
        </a>
      );
    } else {
      if (isWidget) {
        // If this is a widget in edit mode, we don't want to render a link
        if (widgetEditMode)
          return (
            <Fragment>
              {renderIcon(item)}
              <span>{item.name}</span>
            </Fragment>
          );
        return (
          <a
            href={item.properties.isVisible ? href : null}
            className="widget-menu-link"
            data-aut={`Aside|WidgetLink|${item.name}`}
            onClick={() => {
              setGoogleAnalyticsEvent({
                action: "portal_dashboard_widget_click",
                category: item.identifier,
              });
            }}
          >
            {renderIcon(item)}
            <span>{item.name}</span>
          </a>
        );
      }
      const isActive = path === href && paramId === item.id;
      return (
        <NavLink
          to={`${href}?id=${item.id}`}
          target={item.target}
          data-aut={`Aside|PageLink|${item.label}`}
          isActive={() => isActive}
          onClick={() => {
            setGoogleAnalyticsEvent({
              action: "portal_dashboard_tool_click",
              category: item.identifier,
            });
          }}
        >
          {renderIcon(item)}
          <span>{item.label}</span>
        </NavLink>
      );
    }
  };

  const mapIcon = (item) => {
    if (item.component) {
      const Comp = item.component;
      return <Comp aria-hidden="true" />;
    }
    if (item.icon)
      return <i className={`icon ${item.icon}`} aria-hidden="true" />;
    if (item.isDownloadable)
      return <Icon name="file outline" aria-hidden="true" />;
    return <Icon name="linkify" aria-hidden="true" />;
  };

  const renderIcon = (item, isAbsolute) => {
    // If we have no custom icon and this is not an absolute link, we want no icon
    if (!item.icon && !isAbsolute && !item.component) return;
    if (isCondensed) {
      return (
        <Popup
          trigger={mapIcon(item)}
          content={item.label}
          on="hover"
          position="right center"
          basic
          style={{ zIndex: 99999, fontSize: "12px" }}
        />
      );
    } else {
      return mapIcon(item);
    }
  };

  const renderItem = (item, index) => {
    const key = `${item.id || ""}${index}`;
    const MenuItem = ({ item, draggableListeners }) => {
      return (
        <SUIMenu.Item
          key={key}
          className={draggableListeners && "draggable-widget-item"}
        >
          {draggableListeners && (
            <Icon name="sort" className="drag-icon" {...draggableListeners} />
          )}
          {renderLink(item)}
          {renderToggle(item)}
        </SUIMenu.Item>
      );
    };

    // If we are in edit mode and this is a widget menu
    // menu items need to be sortable items so that they can be reordered
    if (widgetEditMode && isWidget) {
      const SortableItem = ({ item }) => {
        const { attributes, listeners, setNodeRef, transform, transition } =
          useSortable({ id: item.id, transition: null });
        const style = {
          transform: CSS.Transform.toString(transform),
          transition,
        };

        return (
          <div ref={setNodeRef} style={style} {...attributes}>
            <MenuItem item={item} draggableListeners={listeners} />
          </div>
        );
      };

      return <SortableItem item={item} key={key} />;
    }
    return (
      <MenuItem
        item={item}
        key={item.id || item.identifier}
        draggableListeners={null}
      />
    );
  };

  const renderItems = () => {
    const mappedItems = items.map((item, index) => renderItem(item, index));
    // If this menu is not for widgets or if we are not in widgetedit mode, render items as unsortable
    if (!isWidget || !widgetEditMode) {
      return mappedItems;
    }
    // Otherwise render items as sortable
    const SortableContainer = ({ children }) => {
      const sortableItems = items.map((i) => i.id);

      const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            delay: 100,
            tolerance: 100,
          },
        }),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      );

      const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
          const oldIndex = sortableItems.indexOf(active.id);
          const newIndex = sortableItems.indexOf(over?.id || "");
          setGoogleAnalyticsEvent({
            action: "portal_dashboard_widget_reorder",
            category: items[oldIndex].identifier,
            label: newIndex,
          });
          arrayMove(items, oldIndex, newIndex);
          onSortEnd({ oldIndex, newIndex });
        }
      };

      return (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={sortableItems}>{children}</SortableContext>
        </DndContext>
      );
    };

    return (
      <SortableContainer>
        <div className="sortable-widgets">{mappedItems}</div>
      </SortableContainer>
    );
  };

  if (isWidget && !isWidgetVisible) return null;
  return (
    <SUIMenu
      vertical
      borderless
      className={`aside-menu${isWidget ? " widget" : ""}`}
      style={{ backgroundColor: "transparent" }}
      data-automation={toSpinalCase(label)}
    >
      <SUIMenu.Item header>
        <div className={`title`} ref={headerRef}>
          <span className="full">{label}</span>
          {["Widgets"].includes(label) ? (
            <SettingsIcon
              className={`icon ${iconStatus()}`}
              onClick={toggleEditMode}
            />
          ) : (
            ""
          )}
        </div>
      </SUIMenu.Item>
      {renderItems()}
    </SUIMenu>
  );
};

SideSubMenu.propTypes = {
  /** Toggle for if this is the widget menu for specific rendering */
  isWidget: PropTypes.bool,
  /** In some cases we want to hide the widget menu, this toggles that functionality */
  isWidgetVisible: PropTypes.bool,
  /** Menu items */
  items: PropTypes.array.isRequired,
  /** Text for top of the menu */
  label: PropTypes.string.isRequired,
  /** Function for allowing the widget menu to go into edit mode */
  toggleWidgetEdit: PropTypes.func.isRequired,
  /** Function for updating the widget order */
  updateWidgetOrderSaga: PropTypes.func.isRequired,
  /** All of our widget data */
  widgets: PropTypes.array.isRequired,
  /** Toggle for whether widget menu is in edit mode */
  widgetEditMode: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isWidgetVisible: state.application.portal.isWidgetVisible,
    widgets: state.widgets,
    widgetEditMode: state.application.widgetEditMode,
    impersonation: state.impersonation,
  };
};
const mapDispatchToProp = (dispatch) => ({
  toggleWidgetEdit: () => dispatch(toggleWidgetEdit()),
  updateWidgetPropsSaga: (index, props) =>
    dispatch(updateWidgetPropsSaga(index, props)),
  updateWidgetOrderSaga: (oldIndex, newIndex) =>
    dispatch(updateWidgetOrderSaga(oldIndex, newIndex)),
});

export default connect(mapStateToProps, mapDispatchToProp, null, {
  forwardRef: true,
})(SideSubMenu);
