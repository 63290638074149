import { get, put, post, deleteRequest } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import {
  APPROVE,
  APPROVE_CSV,
  APPROVE_DELEGATE,
  IMPERSONATION_ID,
} from "src/common/constants";
import { formatDate, handleErrors, saveFileFromBlobData } from "src/common";
import { CTM_APPROVE_ROUTE } from "src/pages/Routes";
import { shouldMockData } from "src/common";
import { mockApprovals } from "src/common/mocks/data/approve";

const {
  changeMockApprovalStatus,
  fetchMockApproval,
  fetchMockApprovals,
  fetchMockApprovalsCSV,
  transferMockApproval,
} = mockApprovals();

export const approvalStatusMap = {
  0: "Initiated",
  1: "Pending",
  2: "Approved",
  3: "Denied",
  4: "Expired",
  5: "Ignored",
};

export const bookingStatusMap = {
  0: "Ticketed",
  1: "Confirmed",
  2: "Cancelled",
};

const fieldTextToEnum = {
  traveler: 1,
  pnr: 2,
  bookedFormatted: 3,
  status: 4,
  travelDateFormatted: 5,
  expiresFormatted: 6,
  priceFormatted: 7,
};

export const historyStatuses = [0, 1, 5, 6, 7, 8];
export const historyApprovalStatuses = [2, 3, 4, 5];
export const historyBookingStatuses = [0, 1, 2];
const queueApprovalStatuses = [1];
const noActionStatuses = [2, 3, 4];

export const redirectToFullPage = ({ history, approvalId, featureId }) => {
  const url = `/${CTM_APPROVE_ROUTE}?id=${featureId}&approvalId=${approvalId}`;
  history.push(url);
};

const constructApproveParams = ({
  endDate,
  page,
  query,
  selectedTab,
  sortColumn,
  startDate,
  sortDirection,
  approvalStatuses,
  bookingStatuses,
}) => {
  const finalParams = {
    limit: 9,
    skip: (page - 1) * 9,
  };
  if (sortColumn) {
    finalParams.sortColumn = fieldTextToEnum[sortColumn];
    finalParams.sort = sortDirection === "ascending" ? 1 : 2;
  }
  if (query) finalParams.searchKeyword = query;
  if (selectedTab === "Queue") {
    finalParams.approvalStatuses = queueApprovalStatuses;
    finalParams.bookingStatuses = historyBookingStatuses;
  } else {
    if (endDate) finalParams.endDate = endDate.format();
    if (startDate) finalParams.startDate = startDate.format();
    finalParams.approvalStatuses = approvalStatuses;
    finalParams.bookingStatuses = bookingStatuses;
  }
  return finalParams;
};

export const fetchApproveData = (params) => {
  const url = getConfig(APPROVE).url;
  if (url) {
    const finalUrl = `${url}/filter`;
    const finalParams = constructApproveParams(params);
    return shouldMockData()
      ? fetchMockApprovals(params)
      : post({ url: finalUrl, params: finalParams })
          .then(handleErrors)
          .then((res) => res.json())
          .then((data) => ({
            ...data,
            bookingRequests: data.bookingRequests
              ? data.bookingRequests.map(mapApproveData)
              : [],
          }));
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

export const fetchApproveDataById = (id) => {
  const url = getConfig(APPROVE).url;
  if (url) {
    const finalUrl = `${url}/GetBookingRequestDetailsById/${id}`;
    return shouldMockData()
      ? fetchMockApproval(id)
      : get({ url: finalUrl })
          .then(handleErrors)
          .then((res) => res.json())
          .then(mapApproveData);
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

export const changeApprovalStatus = (params, isImpersonating, pnr) => {
  const url = `${getConfig(APPROVE).url}/${params.id}/status`;
  if (url) {
    return shouldMockData()
      ? changeMockApprovalStatus(params)
      : put({
          url,
          params,
          impersonationHeaders: isImpersonating
            ? {
                impersonationheaderid: sessionStorage.getItem(IMPERSONATION_ID),
                impersonationaction: `Approval: ${
                  params.status === "approved" ? "Approved" : "Denied"
                }`,
                impersonationdetail: pnr,
              }
            : undefined,
        })
          .then(handleErrors)
          .then((res) => res);
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

export const changeApprovalStatusWithToken = ({
  id,
  status,
  token,
  comments,
}) => {
  const url = getConfig(APPROVE).url;
  if (url && id && status && token) {
    const finalUrl = `${url}/${id}/guest-approval?Id=${id}&Status=${status}&Token=${token}&Comment=${comments}`;
    return get({ url: finalUrl, noTokenRequired: true }).then((res) =>
      res.text()
    );
  } else {
    return Promise.reject(
      new Error(`Missing ${APPROVE} url, or id, status, or token`)
    );
  }
};

export const fetchTransferOptions = (id) => {
  const url = `${getConfig(APPROVE).url}/${id}/transfer`;
  if (url) {
    return get({ url })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

export const transferApproval = (id, params) => {
  const url = `${getConfig(APPROVE).url}/${id}/transfer`;
  if (url) {
    return shouldMockData()
      ? transferMockApproval({ ...params, id })
      : put({ url, params })
          .then(handleErrors)
          .then((res) => res);
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

const mapApproveData = (approval) => {
  const hasAirBooking = !!(
    approval.itinerary && approval.itinerary.flights.outbound.length
  );
  const hasHotelBooking = !!(
    approval.itinerary && approval.itinerary.hotels.length
  );
  const hasCarBooking = !!(
    approval.itinerary && approval.itinerary.carRentals.length
  );
  const hasRailBooking = !!(
    approval.itinerary && approval.itinerary.rails.length
  );
  const hasLimoBooking = !!(
    approval.itinerary && approval.itinerary.limos.length
  );
  return {
    ...approval,
    bookedFormatted: formatDate(approval.bookingDate),
    expiresFormatted: formatDate(approval.expireAt),
    pnrLower: approval.pnr ? approval.pnr.toLowerCase() : "",
    approvalStatus: approvalStatusMap[approval.approvalStatus] || "N/A",
    bookingStatus: bookingStatusMap[approval.bookingStatus] || "N/A",
    travelDateFormatted: formatDate(approval.travelDate),
    travelerLower: approval.traveler ? approval.traveler.toLowerCase() : "",
    priceFormatted:
      (approval.itineraryTotalCost && approval.itineraryTotalCost) || "N/A",
    airTotalCost: hasAirBooking ? approval.airTotalCost : null,
    hotelTotalCost: hasHotelBooking ? approval.hotelTotalCost : null,
    carTotalCost: hasCarBooking ? approval.carTotalCost : null,
    railTotalCost: hasRailBooking ? approval.railTotalCost : null,
    limoTotalCost: hasLimoBooking ? approval.limoTotalCost : null,
    totalCost: approval.totalCost ? approval.totalCost : null,
    totalEstimate: approval.totalEstimate ? approval.totalEstimate : null,
    lostSavings: approval.lostSavings ? approval.lostSavings : null,
    lowestLogicalFare: approval.lowestLogicalFare
      ? approval.lowestLogicalFare
      : null,
    noActionRequired: noActionStatuses.includes(approval.approvalStatus)
      ? approvalStatusMap[approval.approvalStatus]
      : null,
  };
};

export const fetchApproveCSV = (params) => {
  if (shouldMockData()) {
    try {
      return fetchMockApprovalsCSV(params);
    } catch (e) {
      console.error("error with csv mock data export");
    }
  } else {
    const url = getConfig(APPROVE_CSV).url;
    if (url) {
      const finalParams = constructApproveParams(params);
      return post({ url, params: finalParams })
        .then(handleErrors)
        .then((res) => res.text())
        .then((csvdata) => {
          saveFileFromBlobData(csvdata, "approval-history.csv");
        })
        .catch(() => console.error("error fetching csv"));
    } else {
      return Promise.reject(new Error(`Missing ${APPROVE} url`));
    }
  }
};

export const fetchApproveDelegations = () => {
  const url = getConfig(APPROVE_DELEGATE).url;
  if (url) {
    return get({ url })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

const mapDelegationForRequest = (delegation) => {
  const params = {
    DelegatedApproverEmail: delegation.delegatedApproverEmail,
    StartDate: delegation.startDate.startOf("day").utc().format(),
    EndDate: delegation.endDate.endOf("day").utc().format(),
  };
  if (delegation?.approver?.approverId) {
    params.OriginalApproverId = delegation.approver.approverId;
  }
  if (delegation.description.length)
    params.Description = delegation.description;
  return params;
};

export const createApproveDelegation = (delegation) => {
  const url = getConfig(APPROVE_DELEGATE).url;
  if (url) {
    const params = mapDelegationForRequest(delegation);
    return post({ url, params })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

export const deleteApproveDelegation = (id) => {
  const url = getConfig(APPROVE_DELEGATE).url;
  if (url) {
    const finalUrl = `${url}/${id}`;
    return deleteRequest({ url: finalUrl }).then(handleErrors);
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};

export const editApproveDelegation = (delegation, id) => {
  const url = getConfig(APPROVE_DELEGATE).url;
  if (url) {
    const finalUrl = `${url}/${id}`;
    const params = mapDelegationForRequest(delegation);
    return put({ url: finalUrl, params })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${APPROVE} url`));
  }
};
