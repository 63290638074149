import {
  fetchCurrenciesFailed,
  fetchCurrenciesSuccess,
  fetchCurrenciesInvoked,
  fetchCurrenciesTimeSeriesFailed,
  fetchCurrenciesTimeSeriesSuccess,
  fetchCurrenciesTimeSeriesInvoked,
  CURRENCIES_FETCH_DATA,
  CURRENCIES_TIME_SERIES_FETCH_DATA,
} from "src/redux/actions/currencies";
import { fork, put, call, select, takeLatest } from "redux-saga/effects";
import {
  fetchCurrencies,
  fetchCurrenciesTimeSeries,
} from "src/services/currencies";

const getState = (state) => state.currencies;

export function* fetchCurrenciesSaga() {
  try {
    yield put(fetchCurrenciesInvoked());
    const payload = yield call(fetchCurrencies);
    yield put(fetchCurrenciesSuccess(payload));
    const { selections } = yield select(getState);
    yield fork(fetchCurrenciesTimeSeriesSaga, { selections });
  } catch (ex) {
    yield put(fetchCurrenciesFailed(ex));
  }
}

export function* fetchCurrenciesTimeSeriesSaga({ selections }) {
  try {
    yield put(fetchCurrenciesTimeSeriesInvoked());
    const payload = yield call(fetchCurrenciesTimeSeries, selections);
    yield put(fetchCurrenciesTimeSeriesSuccess(payload));
  } catch (ex) {
    yield put(fetchCurrenciesTimeSeriesFailed(ex));
  }
}

function* currenciesSaga() {
  yield takeLatest(CURRENCIES_FETCH_DATA, fetchCurrenciesSaga);
  yield takeLatest(
    CURRENCIES_TIME_SERIES_FETCH_DATA,
    fetchCurrenciesTimeSeriesSaga
  );
}

export default currenciesSaga;
