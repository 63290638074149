import {
  fetchApproveFailed,
  fetchApproveInvoked,
  fetchApproveSuccess,
  fetchApproveByIdFailed,
  fetchApproveByIdSuccess,
  setApproveTab,
  setApproveQuery,
  setApproveDates,
  setApprovePage,
  sortApproveData,
  setApprovalStatuses,
  setBookingStatuses,
  APPROVE_FETCH,
  APPROVE_FETCH_ONE,
  APPROVE_SET_TAB_SAGA,
  APPROVE_SET_DATES_SAGA,
  APPROVE_SET_PAGE_SAGA,
  APPROVE_DATA_SORT_SAGA,
  APPROVE_SET_QUERY_SAGA,
  APPROVE_SET_APP_STATUSES_SAGA,
  APPROVE_SET_BOOKING_STATUSES_SAGA,
} from "src/redux/actions/approve";
import { fork, put, takeLatest, select } from "redux-saga/effects";
import {
  fetchApproveData,
  fetchApproveDataById,
  // fetchTransferOptions,
} from "src/services/approve";

export const getApproveState = (state) => state.approve;

export function* setDates({ endDate, startDate }) {
  yield put(setApproveDates({ endDate, startDate }));
  yield fork(fetchApproveDataSaga);
}

export function* selectTab({ selectedTab }) {
  yield put(setApproveTab(selectedTab));
  yield fork(fetchApproveDataSaga);
}

export function* sortData({ sortColumn, sortDirection }) {
  yield put(sortApproveData({ sortColumn, sortDirection }));
  yield fork(fetchApproveDataSaga);
}

export function* setPage(page) {
  yield put(setApprovePage(page));
  try {
    const approveData = yield select(getApproveState);
    const payload = yield fetchApproveData(approveData);
    yield put(fetchApproveSuccess(payload));
  } catch (ex) {
    yield put(fetchApproveFailed(ex));
  }
}

export function* setApproveStatuses({ approvalStatuses }) {
  yield put(setApprovalStatuses({ approvalStatuses }));
  yield fork(fetchApproveDataSaga);
}
export function* setBookingStatus({ bookingStatuses }) {
  yield put(setBookingStatuses({ bookingStatuses }));
  yield fork(fetchApproveDataSaga);
}

export function* setQuery(query) {
  yield put(setApproveQuery(query));
  yield fork(fetchApproveDataSaga);
}

export function* fetchApproveDataSaga() {
  try {
    yield put(fetchApproveInvoked());
    const approveData = yield select(getApproveState);
    const payload = yield fetchApproveData(approveData);
    yield put(fetchApproveSuccess(payload));
  } catch (ex) {
    yield put(fetchApproveFailed(ex));
  }
}

export function* fetchApproveByIdSaga({ id }) {
  try {
    const selectedApproval = yield fetchApproveDataById(id);
    // const transferOptions = yield fetchTransferOptions(id);
    yield put(
      fetchApproveByIdSuccess({
        selectedApproval,
        // transferOptions
      })
    );
  } catch (ex) {
    yield put(fetchApproveByIdFailed(ex));
  }
}
function* approveSaga() {
  yield takeLatest(APPROVE_FETCH, fetchApproveDataSaga);
  yield takeLatest(APPROVE_FETCH_ONE, fetchApproveByIdSaga);
  yield takeLatest(APPROVE_SET_TAB_SAGA, selectTab);
  yield takeLatest(APPROVE_SET_DATES_SAGA, setDates);
  yield takeLatest(APPROVE_SET_PAGE_SAGA, setPage);
  yield takeLatest(APPROVE_SET_QUERY_SAGA, setQuery);
  yield takeLatest(APPROVE_DATA_SORT_SAGA, sortData);
  yield takeLatest(APPROVE_SET_APP_STATUSES_SAGA, setApproveStatuses);
  yield takeLatest(APPROVE_SET_BOOKING_STATUSES_SAGA, setBookingStatus);
}

export default approveSaga;
