import {
  fetchUnusedTicketsFailed,
  fetchUnusedTicketsSuccess,
  fetchUnusedTicketsInvoked,
  fetchUnusedTicketsWithAirlinesSuccess,
  UNUSED_TICKETS_FETCH_DATA,
} from "src/redux/actions/unusedTickets";
import { put, call, takeLatest } from "redux-saga/effects";
import { fetchData } from "src/services/unusedTickets";
import { shouldMockData } from "src/common";

export const getUnusedTicketsState = (state) => state.unusedTickets;

async function getMockUsedTickets(params) {
  const { mockUnusedTicketsData } = await import(
    "src/common/mocks/data/unusedTickets.js"
  );
  return mockUnusedTicketsData(params);
}

export function* fetchUnusedTickets({ params }) {
  try {
    yield put(fetchUnusedTicketsInvoked());
    const payload = shouldMockData()
      ? yield getMockUsedTickets(params)
      : yield call(fetchData, params);
    if (params.keepAirlines)
      yield put(fetchUnusedTicketsWithAirlinesSuccess(payload));
    else yield put(fetchUnusedTicketsSuccess(payload));
  } catch (ex) {
    yield put(fetchUnusedTicketsFailed(ex));
  }
}

function* unusedTicketsSaga() {
  yield takeLatest(UNUSED_TICKETS_FETCH_DATA, fetchUnusedTickets);
}

export default unusedTicketsSaga;
