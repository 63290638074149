import {
  fetchFlightDetailsFailed,
  fetchFlightDetailsSuccess,
  fetchFlightDetailsInvoked,
  FLIGHTDETAILS_FETCH_DATA,
} from "src/redux/actions/flightDetails";
import { put, call, takeEvery } from "redux-saga/effects";
import { fetchData } from "src/services/flightDetails";

function* fetchFlightDetails({ id, timeFrames }) {
  try {
    yield put(fetchFlightDetailsInvoked(id));
    const properties = { timeFrames, CompanyFeatureID: id };
    const payload = yield call(fetchData, properties);
    yield put(fetchFlightDetailsSuccess(payload, id));
  } catch (ex) {
    yield put(fetchFlightDetailsFailed(ex, id));
  }
}
function* flightDetailsSaga() {
  yield takeEvery(FLIGHTDETAILS_FETCH_DATA, fetchFlightDetails);
}

export default flightDetailsSaga;
