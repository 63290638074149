//@flow

import { PREFIX } from "../Auth/auth0-variables";
import { handleErrors } from "src/common/shared";
import { supportsLocalStorage, toQueryString } from "src/common/helpers";
import { IMPERSONATION_TOKEN } from "../constants";

export const getToken = () => {
  if (!supportsLocalStorage()) {
    // We still support the globalriskmap without localstorage.
    // Chrome/Iframe/Incognito don't get access to localstorage.
    const params = new URLSearchParams(window.location.search);
    return { token: params.get("token") };
  } else {
    const impToken = sessionStorage.getItem(IMPERSONATION_TOKEN);
    if (impToken) {
      return {
        token: impToken,
      };
    }
    return { token: localStorage.getItem(`${PREFIX}_access_token`) };
  }
};

type ImpersonationHeaders = {
  impersonationheaderid: string;
  impersonationaction: string;
  impersonationdetail: string;
};

interface RequestI {
  url: string;
  params?: {
    [key: string]: any;
  };
  options?: {
    [key: string]: any;
  };
  impersonationHeaders?: ImpersonationHeaders;
  contentType?: string;
  keepAlive?: boolean;
  noTokenRequired?: boolean;
}

interface GenericRequestI extends RequestI {
  method: "get" | "put" | "post" | "delete";
}

export const request = async ({
  method,
  url,
  params,
  options,
  contentType,
  impersonationHeaders,
  keepAlive = false,
  noTokenRequired = false,
}: GenericRequestI) => {
  const { token } = getToken();

  if (!token && !noTokenRequired) {
    try {
      handleErrors({ status: 401 });
    } catch (er) {
      // In some cases, our handleErrors can throw an error here instead of redirecting (styleguidist)
      // So we need to handle the error gracefully
      const error: any = new Error("No Token");
      error.code = 401;
      return Promise.reject(error);
    }
  }

  const merged: any = {
    method: method || "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...options,
  };
  if (keepAlive) {
    merged["keepalive"] = true;
  }
  if (params != null) {
    merged.body =
      contentType === "multipart/form-data" ? params : JSON.stringify(params);
    merged.headers["Accept"] =
      contentType === "multipart/form-data" ? "*/*" : "application/json";
    if (contentType !== "multipart/form-data")
      merged.headers["Content-Type"] = "application/json";
  }
  if (impersonationHeaders) {
    merged.headers = {
      ...merged.headers,
      ...impersonationHeaders,
    };
  }

  return fetch(url, merged);
};

export const get = async ({
  url,
  params,
  options,
  contentType,
  keepAlive,
  impersonationHeaders,
  noTokenRequired,
}: RequestI) => {
  if (params) {
    const qs = toQueryString(params);
    if (url.indexOf("?") > 0) url += qs;
    else url += `?${qs}`;
  }

  return request({
    method: "get",
    url,
    options,
    contentType,
    keepAlive,
    impersonationHeaders,
    noTokenRequired,
  });
};

export const put = async ({
  url,
  params,
  options,
  contentType,
  keepAlive,
  impersonationHeaders,
  noTokenRequired,
}: RequestI) => {
  return request({
    method: "put",
    url,
    params,
    options,
    contentType,
    keepAlive,
    impersonationHeaders,
    noTokenRequired,
  });
};

export const post = async ({
  url,
  params,
  options,
  contentType,
  keepAlive,
  impersonationHeaders,
  noTokenRequired,
}: RequestI) => {
  return request({
    method: "post",
    url,
    params,
    options,
    contentType,
    keepAlive,
    impersonationHeaders,
    noTokenRequired,
  });
};

export const deleteRequest = async ({
  url,
  params,
  options,
  contentType,
  impersonationHeaders,
}: RequestI) => {
  return request({
    method: "delete",
    url,
    params,
    options,
    contentType,
    impersonationHeaders,
  });
};
