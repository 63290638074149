import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import TravelerTracker from "../TravelerTracker";
import { Loading } from "src/components";
import { setIsGlobalRiskMap, setWidgets } from "src/redux/actions";
import { supportsLocalStorage } from "src/common";
import { usePageInit } from "src/common/hooks";

export const PREFIX = "smartportal";
export const ACCESS_TOKEN = `${PREFIX}_access_token`;

const presetWidgets = [
  {
    order: 1,
    properties: {
      timeFrames: {
        previous: 5,
        upcoming: 5,
      },
      displayGeorisk: true,
      displayRiskMessages: true,
      riskView: "riskSummary",
      mapView: "riskHotspots",
      geoRiskOn: true,
      travelRequirementsEnabled: true,
      riskLevels: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        all: true,
      },
      geoRiskLevels: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
      },
      isVisible: true,
      colspan: 3,
      id: "global-risk-map",
    },
    id: "global-risk-map",
    identifier: "Elysium_Traveler_Tracker_Widget",
    name: "Traveler Tracker",
    type: "Widget",
  },
];

function GlobalRiskMap(props) {
  const {
    location,
    widgets,
    setIsGlobalRiskMap,
    setWidgets,
    redirectToLogout,
  } = props;
  const [shouldRedirect, setRedirect] = useState(false);
  usePageInit("Smart Portal - Risk Map");
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token && token.length) {
      setIsGlobalRiskMap();

      if (!widgets || !widgets.length) {
        setWidgets(presetWidgets);
      }
      if (supportsLocalStorage()) {
        localStorage.setItem(ACCESS_TOKEN, token);
      }
    } else {
      setRedirect(true);
    }
  }, [location.search, setIsGlobalRiskMap, setWidgets, widgets]);
  if (shouldRedirect || redirectToLogout) {
    return <Redirect to="/invalid-key" />;
  }
  return (
    <div className="layout page travelertracker global-risk-map">
      {!widgets || !widgets.length ? (
        <Loading active loadingText="Loading Risk Map" />
      ) : (
        <TravelerTracker {...props} isGlobalRiskMap />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    widgets: state.widgets,
    redirectToLogout: state.application.redirectToLogout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setIsGlobalRiskMap: () => dispatch(setIsGlobalRiskMap()),
  setWidgets: (widgets) => dispatch(setWidgets(widgets)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRiskMap);
