import { put } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { WIDGETPROPERTIES } from "src/common/constants";
import { handleErrors } from "src/common";

// Updates User's Widget Order on the backend
export const putWidgetOrder = (identifier, newIndex) => {
  const url = getConfig(WIDGETPROPERTIES).url;
  if (url) {
    const constructedUrl = `${url}/${identifier}/${newIndex}`;
    return put({ url: constructedUrl })
      .then(handleErrors)
      .catch((e) => console.error("error", e));
  } else {
    return Promise.reject(new Error(`Missing ${WIDGETPROPERTIES} url`));
  }
};

// Updates the specified Widget's properties on the backend
export const putWidgetProperties = (identifier, id, properties) => {
  const url = getConfig(WIDGETPROPERTIES).url;
  if (url) {
    const params = {
      widgetName: identifier,
      properties,
    };
    const constructedUrl = `${url}/${id}`;
    return put({ url: constructedUrl, params })
      .then(handleErrors)
      .catch((e) => console.error("error", e));
  } else {
    return Promise.reject(new Error(`Missing ${WIDGETPROPERTIES} url`));
  }
};
