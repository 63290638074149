import SVGIcon from "src/components/SVGIcon";

const DeemIcon = ({ title = "Deem", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M12.907 31.985h-9.281v-17.655l2.97-2.97 2.97 2.96v11.715h3.393c5.568-0.023 10.074-4.537 10.086-10.105v-0.001c0-0 0-0 0-0.001 0-5.525-4.479-10.003-10.003-10.003-0.040 0-0.080 0-0.119 0.001l0.006-0h-9.281v-5.909h9.281c8.595 0.304 15.447 7.344 15.447 15.985 0 8.623-6.823 15.652-15.365 15.983l-0.030 0.001z"
      />
    </SVGIcon>
  );
};

export default DeemIcon;
