import { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import "./index.less";
import App from "src/App";
import { IESplashPage } from "src/pages";
import { getIEVersion } from "src/common";

window.onload = function () {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render((getIEVersion() ? <IESplashPage /> : <App />) as ReactNode);
};

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
