import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { COUNTRIES, MICROSITE_COUNTRIES } from "src/common/constants";
import { handleErrors } from "src/common";

export const fetchData = (isGlobalRiskMap) => {
  const urlName = isGlobalRiskMap ? MICROSITE_COUNTRIES : COUNTRIES;
  const url = getConfig(urlName).url;
  if (url) {
    return get({ url })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${urlName} url`));
  }
};
