import { WIDGET } from "src/common/constants";

export const SET_WIDGETS = "SET_WIDGETS";

export const UPDATE_WIDGET_ORDER_SAGA = `${WIDGET}_UPDATE_ORDER_SAGA`;
export const UPDATE_WIDGET_ORDER = `${WIDGET}_UPDATE_ORDER`;

export const UPDATE_WIDGET_PROPS_SAGA = `${WIDGET}_UPDATE_PROPS_SAGA`;
export const UPDATE_WIDGET_PROPS = `${WIDGET}_UPDATE_PROPS`;

export const setWidgets = (payload) => ({
  type: SET_WIDGETS,
  widgets: payload,
});

export const updateWidgetOrderSaga = (oldIndex, newIndex) => ({
  type: UPDATE_WIDGET_ORDER_SAGA,
  oldIndex,
  newIndex,
});
export const updateWidgetOrder = (oldIndex, newIndex) => ({
  type: UPDATE_WIDGET_ORDER,
  oldIndex,
  newIndex,
});

export const updateWidgetPropsSaga = (index, props) => ({
  type: UPDATE_WIDGET_PROPS_SAGA,
  index,
  props,
});
export const updateWidgetProps = (index, props) => ({
  type: UPDATE_WIDGET_PROPS,
  index,
  props,
});
