import { SSO_SET_DATA } from "src/redux/actions/ssoLinks";

import { setSsoDefaults } from "src/common/shared/featureSettings";
import { SSOLinkI } from "src/types/SSOLink";

export interface SSOLinksAction {
  type: string;
  payload: SSOLinkI[];
}

const ssoLinks = (
  state: SSOLinkI[] = [],
  action: SSOLinksAction
): SSOLinkI[] => {
  let result;
  switch (action.type) {
    case SSO_SET_DATA:
      result = setSsoDefaults(action.payload);
      return result;
    default:
      return state;
  }
};

export default ssoLinks;
