import moment from "moment";
import {
  APPROVE_FETCH_INVOKED,
  APPROVE_FETCH_SUCCESS,
  APPROVE_FETCH_FAILED,
  APPROVE_FETCH_ONE,
  APPROVE_FETCH_ONE_SUCCESS,
  APPROVE_FETCH_ONE_FAILED,
  APPROVE_DATA_SORT,
  APPROVE_SET_QUERY,
  APPROVE_SET_DATES,
  APPROVE_SET_PAGE,
  APPROVE_SET_TAB,
  APPROVE_SET_APP_STATUSES,
  APPROVE_SET_BOOKING_STATUSES,
} from "src/redux/actions/approve";
import {
  historyApprovalStatuses,
  historyBookingStatuses,
} from "src/services/approve";
import { Approval } from "src/types/Approve";

export interface ApprovalState {
  data?: Approval[];
  endDate?: moment.Moment;
  error?: Error;
  selectedError?: Error;
  selectedApproval?: Approval;
  loading?: boolean;
  loadingSelected?: boolean;
  page?: number;
  totalCount?: number;
  query?: string;
  selectedTab?: string;
  sortDirection?: string;
  sortColumn?: string;
  startDate?: moment.Moment;
  transferOptions?: null;
  approvalStatuses?: number[];
  bookingStatuses?: number[];
}

export const initialState: ApprovalState = {
  data: [],
  endDate: null,
  error: null,
  selectedError: null,
  selectedApproval: null,
  loading: true,
  loadingSelected: false,
  page: 1,
  totalCount: 0,
  query: "",
  selectedTab: "Queue",
  sortDirection: "descending",
  sortColumn: "travelDateFormatted",
  startDate: null,
  transferOptions: null,
  approvalStatuses: historyApprovalStatuses,
  bookingStatuses: historyBookingStatuses,
};

const approve = (state = initialState, action): ApprovalState => {
  switch (action.type) {
    case APPROVE_FETCH_INVOKED:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
        totalCount: 0,
      };
    case APPROVE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.bookingRequests,
        totalCount: action.payload.pagination.totalCount,
      };
    case APPROVE_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case APPROVE_FETCH_ONE:
      return {
        ...state,
        selectedApproval: null,
        selectedError: null,
        loadingSelected: true,
      };

    case APPROVE_FETCH_ONE_SUCCESS:
      return {
        ...state,
        selectedApproval: action.selectedApproval,
        transferOptions: action.transferOptions,
        loadingSelected: false,
      };

    case APPROVE_FETCH_ONE_FAILED:
      return {
        ...state,
        selectedError: action.error,
        loadingSelected: false,
      };

    case APPROVE_DATA_SORT:
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection,
      };
    case APPROVE_SET_QUERY:
      return {
        ...state,
        page: 1,
        query: action.query,
      };
    case APPROVE_SET_TAB:
      return {
        ...state,
        selectedApproval: null,
        selectedError: null,
        page: 1,
        selectedTab: action.selectedTab,
      };

    case APPROVE_SET_DATES: {
      const { startDate, endDate } = action;

      return {
        ...state,
        page: 1,
        startDate,
        endDate,
      };
    }
    case APPROVE_SET_PAGE:
      return {
        ...state,
        loading: true,
        data: [],
        page: action.page,
      };
    case APPROVE_SET_APP_STATUSES:
      return {
        ...state,
        approvalStatuses: action.approvalStatuses,
        page: 1,
      };
    case APPROVE_SET_BOOKING_STATUSES:
      return {
        ...state,
        bookingStatuses: action.bookingStatuses,
        page: 1,
      };

    default:
      return state;
  }
};

export default approve;
