import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import {
  resetImpersonatorState,
  setImpersonatorTimingOut,
} from "src/redux/actions";

function ImpersonationModal({
  clearImpersonation,
  isTimedOut,
  setImpersonatorTimingOut,
  resetImpersonatorState,
}) {
  if (isTimedOut) {
    return (
      <Modal open={true} style={{ width: "auto" }}>
        <Modal.Content>
          <p>You have been logged out of an impersonated session.</p>
          <div className="flex grid center">
            <Button onClick={resetImpersonatorState} color="green">
              Continue
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <Modal open={true} style={{ width: "auto" }}>
      <Modal.Content>
        <p>You are about to be logged out of your impersonated session.</p>
        <div className="flex grid center">
          <Button
            onClick={setImpersonatorTimingOut}
            color="green"
            style={{ marginRight: "16px" }}
          >
            Continue Session
          </Button>
          <Button
            onClick={() => {
              clearImpersonation();
              resetImpersonatorState();
            }}
          >
            Log Out
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  resetImpersonatorState: () => dispatch(resetImpersonatorState()),

  setImpersonatorTimingOut: () => dispatch(setImpersonatorTimingOut(false)),
});

export default connect(null, mapDispatchToProps)(ImpersonationModal);
