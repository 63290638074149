import SVGIcon from "src/components/SVGIcon";

const NoData = ({ title = "No Data", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 75.2 72.7" title={title} {...props}>
      <g>
        <g>
          <path
            className="ctm-primary-color"
            d="M2,17.5v11.8c0,1.8,8.8,6,26,6s26-4.2,26-6V17.5c-5.5,3.9-15.8,5.8-26,5.8S7.5,21.4,2,17.5z M28,19.2
			c14.4,0,26-3.8,26-8.5S42.4,2.2,28,2.2S2,6,2,10.7S13.6,19.2,28,19.2z M40.1,54.6c-3.9,0.5-8,0.8-12.1,0.8c-10,0-20.5-1.5-26-4.7
			v7.5c0,7.7,25.3,10,40.6,7C41,62.4,40,59.1,40,55.7C40,55.3,40,55,40.1,54.6z M2,34.6v10.7c0,1.8,8.8,6,26,6c5,0,9.2-0.4,12.8-0.9
			c1.9-6.2,6.9-11.1,13.2-12.6v-3.2c-5.5,3.2-16,4.7-26,4.7S7.5,37.8,2,34.6z"
          />
        </g>
      </g>
      <path
        className="ctm-default-text-color"
        d="M73,56.2c0,7.7-6.3,14-14,14s-14-6.3-14-14s6.3-14,14-14S73,48.5,73,56.2z M65.6,61.3l-5.1-5.1l5.1-5.1
	l-1.5-1.5L59,54.7l-5.1-5.1l-1.5,1.5l5.1,5.1l-5.1,5.1l1.5,1.5l5.1-5.1l5.1,5.1L65.6,61.3z"
      />
    </SVGIcon>
  );
};

export default NoData;
