import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const Approve = React.lazy(() => import("./ApprovePage"));

function ApprovePage(props) {
  usePageInit("Smart Portal - Approve");
  return (
    <Suspense
      fallback={
        <Loading addContainer active loadingText="Loading CTM Approve" />
      }
    >
      <Approve {...props} />
    </Suspense>
  );
}

export default ApprovePage;
