import SVGIcon from "src/components/SVGIcon";

const TramadaIcon = ({ title = "Tramada", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M6.048 0.002h14.29c0.082-0.003 0.178-0.005 0.274-0.005 0.568 0 1.122 0.057 1.657 0.167l-0.053-0.009c2.021 0.391 3.65 1.77 4.385 3.604l0.014 0.040c0.296 0.719 0.469 1.554 0.47 2.429v0q0 12.31 0 24.62c0 0.153 0 0.306 0 0.531-0.17-0.141-0.323-0.284-0.467-0.436l-0.002-0.002c-0.459-0.5-0.898-1.021-1.368-1.521-0.323-0.325-0.522-0.772-0.522-1.267 0-0.028 0.001-0.057 0.002-0.085l-0 0.004q0-10.656 0-21.303c0-0.276 0-0.551 0-0.827-0.049-1.753-1.353-3.187-3.043-3.438l-0.020-0.002c-0.5 0-1.021-0.112-1.5-0.112q-5.543 0-11.106 0c-0.048 0.006-0.104 0.009-0.16 0.009-0.488 0-0.92-0.239-1.185-0.607l-0.003-0.004c-0.542-0.644-1.086-1.227-1.658-1.78l-0.006-0.006z"
      />
      <path
        fill="currentColor"
        d="M25.268 32.002h-0.786c-4.532 0-9.064 0-13.596 0-0.004 0-0.008 0-0.012 0-2.976 0-5.436-2.207-5.833-5.073l-0.003-0.031c-0.073-0.49-0.117-1.059-0.122-1.637l-0-0.006q0-11.483 0-22.977v-0.806c0.071 0 0.143 0 0.163 0 0.653 0.735 1.296 1.47 1.95 2.195 0.16 0.153 0.259 0.367 0.259 0.605 0 0.028-0.001 0.055-0.004 0.082l0-0.003c0 0.174 0 0.347 0 0.531v20.864c-0.003 0.058-0.005 0.127-0.005 0.195 0 0.54 0.107 1.055 0.3 1.526l-0.010-0.027c0.574 1.363 1.899 2.303 3.443 2.303 0.078 0 0.155-0.002 0.232-0.007l-0.011 0c3.675 0 7.349 0 11.034 0 0.045-0.004 0.098-0.006 0.152-0.006 0.65 0 1.225 0.318 1.58 0.807l0.004 0.006c0.347 0.47 0.776 0.898 1.266 1.46z"
      />
    </SVGIcon>
  );
};

export default TramadaIcon;
