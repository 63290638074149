import { LINKS_SET_DATA } from "src/redux/actions/links";

import { setLinksDefaults } from "src/common/shared/featureSettings";
import { DocumentI } from "src/types/Document";
import { LinkI } from "src/types/Link";

const links = (
  state: (DocumentI | LinkI)[] = [],
  action
): (DocumentI | LinkI)[] => {
  switch (action.type) {
    case LINKS_SET_DATA:
      return setLinksDefaults(action.payload);
    default:
      return state;
  }
};

export default links;
