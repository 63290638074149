import { BI } from "src/common/constants";
export const BI_FETCH_DATA = `${BI}_GET_DATA`;
export const BI_FETCH_INVOKED = `${BI}_GET_INVOKED`;
export const BI_FETCH_SUCCESS = `${BI}_GET_SUCCESS`;
export const BI_FETCH_FAILED = `${BI}_GET_FAILED`;

export const fetchBIData = () => ({ type: BI_FETCH_DATA });
export const fetchBIDataInvoked = () => ({ type: BI_FETCH_INVOKED });
export const fetchBIDataSuccess = (payload) => ({
  type: BI_FETCH_SUCCESS,
  payload,
});
export const fetchBIDataFailed = (error) => ({ type: BI_FETCH_FAILED, error });
