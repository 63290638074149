import { all } from "redux-saga/effects";
import airlines from "./airlines";
import application from "./application";
import approve from "./approve";
import bi from "./bi";
import countries from "./countries";
import currencies from "./currencies";
import flightDetails from "./flightDetails";
import itineraryDetails from "./itineraryDetails";
import news from "./news";
import travelerTracker from "./travelerTracker";
import unusedTickets from "./unusedTickets";
import user from "./user";
import widgets from "./widgets";

function* allSagas() {
  yield all([
    airlines(),
    application(),
    approve(),
    bi(),
    countries(),
    currencies(),
    flightDetails(),
    itineraryDetails(),
    news(),
    travelerTracker(),
    unusedTickets(),
    user(),
    widgets(),
  ]);
}

export default allSagas;
