interface SVGIconI {
  children: React.ReactNode;
  className?: string;
  title: string;
  viewBox: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

function SVGIcon(props: SVGIconI) {
  return (
    <svg
      viewBox={props.viewBox}
      width={props.width || props.height ? undefined : "1em"}
      height={props.height || undefined}
      role="img"
      aria-label={props.title}
      {...props}
      className={`ctm-icon${props.className ? ` ${props.className}` : ""}`}
    >
      {props.children}
    </svg>
  );
}

export default SVGIcon;
