import {
  BI_FETCH_SUCCESS,
  BI_FETCH_INVOKED,
  BI_FETCH_FAILED,
} from "src/redux/actions/bi";

export type BIData = {
  dk: string;
  dataTypeCode: string;
  timePeriod: string;
  groupByValue: string;
  monthYear: string;
  spendType: string;
  amount: number;
  transactionCount: number;
  avgCpm: number;
};

export const initialState = {
  data: [] as BIData[],
  loading: true,
  error: null as Error,
};

export type BIState = typeof initialState;

const bi = (state = initialState, action): BIState => {
  switch (action.type) {
    case BI_FETCH_INVOKED:
      return {
        ...state,
        loading: true,
      };
    case BI_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case BI_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default bi;
