import SVGIcon from "src/components/SVGIcon";

const MinimizeArrowsIcon = ({ title = "MinimizeArrows", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentcolor"
        d="M31.393 28.253l-12.557-12.355 12.557-12.355c0.405-0.405 0.607-1.013 0.607-1.418 0-0.607-0.203-1.013-0.607-1.418-0.81-0.81-2.228-0.81-3.038 0l-13.975 13.773c-0.405 0.405-0.607 1.013-0.607 1.418 0 0.607 0.203 1.013 0.607 1.418l13.975 13.975c0.405 0.405 1.013 0.607 1.418 0.607 0.607 0 1.013-0.203 1.418-0.607s0.607-1.013 0.607-1.418c0.203-0.607 0-1.215-0.405-1.62zM5.063 15.899l12.557-12.355c0.405-0.405 0.607-1.013 0.607-1.418 0-0.607-0.203-1.013-0.607-1.418-0.81-0.81-2.228-0.81-3.038 0l-13.975 13.773c-0.405 0.405-0.607 1.013-0.607 1.418 0 0.607 0.203 1.013 0.607 1.418l13.975 13.975c0.405 0.405 1.013 0.607 1.418 0.607 0.607 0 1.013-0.203 1.418-0.607s0.607-1.013 0.607-1.418c0-0.607-0.203-1.013-0.607-1.418l-12.355-12.557z"
      ></path>
    </SVGIcon>
  );
};

export default MinimizeArrowsIcon;
