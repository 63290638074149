import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const InvalidKey = React.lazy(() => import("./InvalidKey"));

function InvalidKeyPage(props) {
  usePageInit("Smart Portal - Invalid Key");
  return (
    <Suspense fallback={<Loading addContainer active loadingText="Loading" />}>
      <InvalidKey {...props} />
    </Suspense>
  );
}

export default InvalidKeyPage;
