import jwt_decode from "jwt-decode";
import { getToken, setGoogleAnalyticsEvent } from "src/common";
import { jws } from "jsrsasign";

function generateToken(data) {
  const header = { alg: "HS256", typ: "JWT" };
  const exp = jws.IntDate.get("now + 1day");
  const payload = { ...data, exp };
  try {
    const result = jws.JWS.sign(
      null,
      JSON.stringify(header),
      JSON.stringify(payload),
      process.env.REACT_APP_SCOUT_SECRET
    );
    return result;
  } catch (e) {
    console.error("Generate token error: ", e);
  }
}

export const setScout = (scoutUrl) => {
  const user = jwt_decode(getToken().token);
  const email = user["email"] || user["https://www.ctmsmart.com/email"];
  const name = user["https://www.ctmsmart.com/fullName"];
  const nameArr = name.split(" ");
  const firstName = nameArr[0];
  const lastName = nameArr[1];
  const debtorId = user["https://www.ctmsmart.com/debtor"];
  const roleName = user["https://www.ctmsmart.com/roles"]?.find(
    (role) => role.code === "TA"
  )
    ? "travel arranger"
    : "traveller";
  // Scout has required fields. If any of them are null, we don't inititalize Scout
  if (!email || !firstName || !lastName || !debtorId || !scoutUrl) return;
  const userData = {
    debtorId,
    email,
    firstName,
    lastName,
    name,
    roleName,
    country: "US",
  };
  const token = generateToken(userData);
  const scoutConfig = {
    rasaServerUrl: scoutUrl,
    metadata: {
      ...userData,
      token,
    },
  };

  const script = document.createElement("script");
  script.src = process.env.REACT_APP_SCOUT_URL;
  script.defer = true;
  script.onload = () => {
    // @ts-ignore
    window.ChatbotWidget.default(scoutConfig);
    setTimeout(() => {
      const scoutDiv = document.getElementById("scoutChatbot")?.children[1];
      if (scoutDiv) {
        scoutDiv?.addEventListener("click", () => {
          setGoogleAnalyticsEvent({ action: "portal_scout_icon_click" });
        });
      } else {
        console.warn("GA Event selector div for Scout could not be found");
      }
    }, 100);
  };
  document.head.appendChild(script);
};

export const removeScoutFromPage = () => {
  const scoutDiv = document.getElementById("scoutChatbot");
  if (scoutDiv) scoutDiv.remove();
};
