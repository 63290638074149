const colors = {
  headerBorderColor: "rgba(150, 155, 165, 0.45)",
  lightHeaderColor: "#003043",
  darkHeaderColor: "#003043",
  sideMenuBorderColor: "#211f26",
  footerLinkColor: "#00010a",
  layoutMainMenuTopPadding: "82px",
  layoutMainMenuTopPaddingMobile: "62px",
  atRiskColor: "#c92123",
  landedColor: "#7b8de3",
  enRouteColor: "#a48ad3",
  delayedColor: "#ec4847",
  notDepartedColor: "#fe9527",
  onTimeColor: "#56cb70",
  allColor: "#02c2eb",
  riskOpacity: 0.4,
  atRiskOpaqueColor: "rgba(201, 33, 35, @riskOpacity)",
  landedOpaqueColor: "rgba(123, 141, 227, @riskOpacity)",
  enRouteOpaqueColor: "rgba(164, 138, 211, .4)",
  delayedOpaqueColor: "rgba(236, 72, 71, .4)",
  notDepartedOpaqueColor: "rgba(254, 149, 39, .4)",
  onTimeOpaqueColor: "rgba(86, 203, 112, .4)",
  allOpaqueColor: "rgba(2, 194, 235, .4)",
  lightBlueColor: "#02c2eb",
  // Official CTM Palette
  blueColor: "#007AFF",
  greenColor: "#51CE6C",
  neutralColor: "#2C3E50",
  silverColor: "#F3F6FC",
  whiteColor: "#FFFFFF",
  pinkColor: "#FF2D55",
  redColor: "#FF0035",
  purpleColor: "#6554C0",
  orangeColor: "#FF9500",
  grayColor: "#b2bac5",
  // Light Theme
  lightModeTextColor: "#2C3E50",
};

export default colors;
