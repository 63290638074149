import React from 'react';
import {Message, Segment} from 'semantic-ui-react';


const NotFound = () => (<Segment size="big" style={{height: '100%'}}>
    <Message
      header='404 - Page Not Found!'
      content='We are sorry but the page you are looking for does not exist.'
    />
  </Segment>
);

export default NotFound;
