import moment from "moment";
import { APPROVE } from "src/common/constants";
export const APPROVE_FETCH = `${APPROVE}_GET`;
export const APPROVE_FETCH_INVOKED = `${APPROVE}_GET_INVOKED`;
export const APPROVE_FETCH_SUCCESS = `${APPROVE}_GET_SUCCESS`;
export const APPROVE_FETCH_FAILED = `${APPROVE}_GET_FAILED`;
export const APPROVE_FETCH_ONE = `${APPROVE}_GET_ONE`;
export const APPROVE_FETCH_ONES = `${APPROVE}_GET_ONES`;
export const APPROVE_FETCH_ONE_SUCCESS = `${APPROVE}_GET_ONE_SUCCESS`;
export const APPROVE_FETCH_ONE_FAILED = `${APPROVE}_GET_ONE_FAILED`;
export const APPROVE_DATA_SORT_SAGA = `${APPROVE}_DATA_SORT_SAGA`;
export const APPROVE_DATA_SORT = `${APPROVE}_DATA_SORT`;
export const APPROVE_SET_QUERY_SAGA = `${APPROVE}_SET_QUERY_SAGA`;
export const APPROVE_SET_QUERY = `${APPROVE}_SET_QUERY`;
export const APPROVE_SET_DATES_SAGA = `${APPROVE}_SET_DATES_SAGA`;
export const APPROVE_SET_DATES = `${APPROVE}_SET_DATES`;
export const APPROVE_SET_PAGE_SAGA = `${APPROVE}_SET_PAGE_SAGA`;
export const APPROVE_SET_PAGE = `${APPROVE}_SET_PAGE`;
export const APPROVE_SET_TAB_SAGA = `${APPROVE}_SET_TAB_SAGA`;
export const APPROVE_SET_TAB = `${APPROVE}_SET_TAB`;
export const APPROVE_SET_APP_STATUSES = `${APPROVE}_SET_APP_STATUSES`;
export const APPROVE_SET_APP_STATUSES_SAGA = `${APPROVE}_SET_APP_STATUSES_SAGA`;
export const APPROVE_SET_BOOKING_STATUSES = `${APPROVE}_SET_BOOKING_STATUSES`;
export const APPROVE_SET_BOOKING_STATUSES_SAGA = `${APPROVE}_SET_BOOKING_STATUSES_SAGA`;

export const fetchApproveData = () => {
  return {
    type: APPROVE_FETCH,
  };
};

export const fetchApproveInvoked = () => {
  return {
    type: APPROVE_FETCH_INVOKED,
  };
};

export const fetchApproveSuccess = (payload) => {
  return {
    type: APPROVE_FETCH_SUCCESS,
    payload,
  };
};

export const fetchApproveFailed = (error) => ({
  type: APPROVE_FETCH_FAILED,
  error,
});

export const fetchApproveById = (id: string) => {
  return {
    type: APPROVE_FETCH_ONE,
    id,
  };
};

export const fetchApproveByIdSuccess = (payload) => {
  return {
    type: APPROVE_FETCH_ONE_SUCCESS,
    ...payload,
  };
};

export const fetchApproveByIdFailed = (error) => ({
  type: APPROVE_FETCH_ONE_FAILED,
  error,
});

export const setApproveQuerySaga = (query: string) => {
  return {
    type: APPROVE_SET_QUERY_SAGA,
    query,
  };
};

export interface SetApproveQueryI {
  query: string;
}

export const setApproveQuery = ({ query }) => {
  return {
    type: APPROVE_SET_QUERY,
    query,
  };
};

export interface SetApproveDatesI {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const setApproveDatesSaga = ({
  startDate,
  endDate,
}: SetApproveDatesI) => {
  return {
    type: APPROVE_SET_DATES_SAGA,
    startDate,
    endDate,
  };
};

export const setApproveDates = ({ startDate, endDate }: SetApproveDatesI) => {
  return {
    type: APPROVE_SET_DATES,
    startDate,
    endDate,
  };
};

export const setApprovePageSaga = (page: number) => {
  return {
    type: APPROVE_SET_PAGE_SAGA,
    page,
  };
};

export interface SetApprovePageI {
  page: number;
}

export const setApprovePage = ({ page }: SetApprovePageI) => {
  return {
    type: APPROVE_SET_PAGE,
    page,
  };
};

export const setApproveTabSaga = (selectedTab: number) => {
  return {
    type: APPROVE_SET_TAB_SAGA,
    selectedTab,
  };
};

export const setApproveTab = (selectedTab: number) => {
  return {
    type: APPROVE_SET_TAB,
    selectedTab,
  };
};

export interface SetApprovalStatusesI {
  approvalStatuses: number[];
}

export const setApprovalStatuses = ({
  approvalStatuses,
}: SetApprovalStatusesI) => {
  return {
    type: APPROVE_SET_APP_STATUSES,
    approvalStatuses,
  };
};

export const setApproveStatusesSaga = ({
  approvalStatuses,
}: SetApprovalStatusesI) => {
  return {
    type: APPROVE_SET_APP_STATUSES_SAGA,
    approvalStatuses,
  };
};

export interface SetBookingStatusesI {
  bookingStatuses: number[];
}

export const setBookingStatuses = ({
  bookingStatuses,
}: SetBookingStatusesI) => {
  return {
    type: APPROVE_SET_BOOKING_STATUSES,
    bookingStatuses,
  };
};

export const setBookingStatusesSaga = ({
  bookingStatuses,
}: SetBookingStatusesI) => {
  return {
    type: APPROVE_SET_BOOKING_STATUSES_SAGA,
    bookingStatuses,
  };
};

export interface SortApproveDataI {
  sortColumn: string;
  sortDirection: "ascending" | "descending";
}

export const sortApproveDataSaga = (data: SortApproveDataI) => {
  return {
    type: APPROVE_DATA_SORT_SAGA,
    ...data,
  };
};

export const sortApproveData = (data: SortApproveDataI) => {
  return {
    type: APPROVE_DATA_SORT,
    ...data,
  };
};
