import SVGIcon from "src/components/SVGIcon";

const AllStateIcon = ({ title = "AllState", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M2.947 19.741c-0.383 0-0.713 0.001-1.042 0-1.093-0.003-1.906-0.762-1.906-1.781 0-4.134 0-8.269 0-12.403 0-0.804 0.826-1.568 1.693-1.568 7.013 0 14.027 0.010 21.040-0.016 0.726-0.003 1.232 0.198 1.735 0.672 0.567 0.535 0.847 1.085 0.803 1.858-0.068 1.21-0.017 2.427-0.018 3.641 0 0.569-0.501 1.095-1.079 1.13-0.062 0.004-0.145-0.018-0.186-0.058-0.427-0.406-0.915-0.722-0.864-1.448 0.088-1.241 0.025-2.492 0.025-3.775h-21.444v11.699c0.625 0.495 1.344 0.494 2.076 0.477 0.822-0.018 1.265 0.41 1.269 1.185 0.004 0.733 0.001 1.466 0.001 2.23 0.958-0.501 1.562-1.279 2.214-2.018 0.387-0.439 0.82-0.844 1.261-1.236 0.12-0.107 0.341-0.153 0.517-0.157 0.736-0.017 1.473 0.010 2.209-0.014 0.383-0.013 0.48 0.207 0.529 0.47 0.096 0.513-0.462 1.16-1.016 1.098-0.783-0.087-1.306 0.241-1.806 0.734-1.16 1.142-2.339 2.268-3.52 3.392-0.316 0.3-0.639 0.609-1.015 0.828-0.199 0.116-0.553 0.13-0.777 0.048-0.354-0.13-0.706-0.324-0.701-0.792 0.014-1.377 0.005-2.754 0.005-4.195z"
      />
      <path
        fill="currentColor"
        d="M29.461 24.453v3.505c-0.779 0.199-1.403-0.049-1.929-0.529-0.946-0.862-1.884-1.733-2.797-2.625-0.269-0.262-0.539-0.346-0.913-0.344-2.91 0.012-5.821 0.002-8.731 0.014-0.354 0.001-0.628-0.052-0.866-0.323-0.248-0.282-0.518-0.567-0.839-0.767-0.343-0.214-0.339-0.49-0.339-0.797 0-2.69-0.007-5.381 0.004-8.072 0.004-0.945 0.903-1.861 1.951-1.862 4.944-0.004 9.889-0.030 14.832 0.018 1.258 0.012 2.124 0.637 2.143 2.025 0.035 2.575 0.023 5.151 0.002 7.727-0.006 0.735-0.314 1.322-1.054 1.73-0.461 0.254-0.899 0.358-1.464 0.3zM29.87 14.252h-14.694v8.246h0.54c2.91 0 5.819-0.005 8.729 0.009 0.256 0.001 0.583 0.060 0.754 0.213 0.747 0.671 1.445 1.389 2.171 2.082 0.086 0.082 0.229 0.111 0.407 0.193 0-0.529-0.002-0.998 0-1.468 0.003-0.696 0.356-1.023 1.103-1.028 0.326-0.002 0.653 0 0.989 0v-8.246z"
      />
      <path
        fill="currentColor"
        d="M12.532 10.289c-2.313 0-4.626 0-6.938 0-0.365 0-0.971-0.467-0.964-0.74 0.007-0.296 0.607-0.833 0.953-0.833 4.521-0.003 9.041-0.005 13.562 0.001 0.703 0.001 1.167 0.662 0.964 1.261-0.117 0.346-0.374 0.309-0.638 0.31-2.313 0.002-4.626 0.001-6.938 0.001z"
      />
      <path
        fill="currentColor"
        d="M8.175 15.015c-0.822 0-1.645 0-2.467 0-0.399 0-0.979-0.413-1.075-0.761-0.067-0.243 0.484-0.803 0.841-0.805 1.819-0.012 3.638-0.018 5.458-0.003 0.526 0.005 0.88 0.343 0.851 0.72-0.025 0.328-0.579 0.844-0.932 0.847-0.892 0.007-1.785 0.002-2.676 0.003z"
      />
      <path
        fill="currentColor"
        d="M19.090 17.476c0.58 0 1.011 0.395 1.007 0.921-0.004 0.426-0.538 0.941-0.984 0.949-0.466 0.009-1.012-0.518-1.015-0.98-0.003-0.49 0.443-0.891 0.993-0.891z"
      />
      <path
        fill="currentColor"
        d="M22.479 17.476c0.58 0.005 0.997 0.403 0.985 0.941-0.010 0.423-0.561 0.933-1.005 0.93-0.469-0.003-1.001-0.538-0.994-1 0.007-0.496 0.45-0.877 1.014-0.871z"
      />
      <path
        fill="currentColor"
        d="M25.839 17.476c0.579 0.004 0.996 0.398 0.992 0.938-0.003 0.418-0.557 0.934-1.002 0.932-0.468-0.001-1.002-0.534-0.998-0.995 0.005-0.494 0.449-0.879 1.008-0.876z"
      />
    </SVGIcon>
  );
};

export default AllStateIcon;
