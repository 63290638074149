import SVGIcon from "src/components/SVGIcon";

const NotificationIcon = ({ title = "Notification", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 25 29" title={title} {...props}>
      <g
        id="Icons-**"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(-868.000000, -5004.000000)">
          <g id="Group" transform="translate(864.283856, 5002.125000)">
            <rect
              id="Rectangle-Copy-15"
              transform="translate(16.000000, 16.000000) rotate(-180.000000) translate(-16.000000, -16.000000) "
              x="0"
              y="0"
              width="32"
              height="32"
            ></rect>
            <path
              d="M19.0163927,26.9836073 C19.0163927,28.6426233 17.659016,30 16,30 C14.3409841,30 12.9836073,28.6426233 12.9836073,26.9836073 L12.9836073,26.9836073 Z M16,2 C17.2487866,2 18.2622946,3.01350795 18.2622946,4.26229453 L18.2622946,4.26229453 L18.2622946,5.31096231 C22.163721,6.31660124 25.0491782,9.84986053 25.0491782,14.0655708 L25.0491782,14.0655708 L25.0491782,20.9508219 L27.3674403,22.6946739 C27.8023823,22.9716243 28.0655708,23.451583 28.0655708,23.9672146 C28.0655708,24.8001684 27.3903284,25.4754109 26.5573745,25.4754109 L26.5573745,25.4754109 L5.44262557,25.4754109 C4.60967173,25.4754109 3.93442918,24.8001684 3.93442918,23.9672146 C3.93442918,23.4515831 4.19761744,22.9716244 4.63550495,22.6946739 L4.63550495,22.6946739 L6.95082193,20.9508219 L6.95082193,14.0655708 C6.95082193,9.84986053 9.83627845,6.31660124 13.7377055,5.31096231 L13.7377055,5.31096231 L13.7377055,4.26229453 C13.7377055,3.01350795 14.7512135,2 16,2 Z"
              id="Combined-Shape"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </SVGIcon>
  );
};

export default NotificationIcon;
