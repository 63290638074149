import {
  NEWS_FETCH_SUCCESS,
  NEWS_FETCH_INVOKED,
  NEWS_FETCH_FAILED,
} from "src/redux/actions/news";

type NewsItem = {
  id: number;
  category: string;
  source?: string;
  title: string;
  description: string;
  publishedDate: string;
  riskRating?: number;
  mustRead: boolean;
};

export const initialState = {
  payload: [] as NewsItem[],
  loading: false,
  error: null as Error,
};

export type NewsState = typeof initialState;

const news = (state = initialState, action): NewsState => {
  switch (action.type) {
    case NEWS_FETCH_INVOKED:
      return {
        ...state,
        loading: true,
      };
    case NEWS_FETCH_SUCCESS:
      // Before we load our news data into redux, we sort the items, and map company specific stories to their own category to avoid needing to do so on render
      return {
        ...state,
        loading: false,
        payload: action.payload
          .sort((a, b) => {
            if (a.mustRead === "true" && b.mustRead !== "true") return -1;
            if (b.mustRead === "true" && a.mustRead !== "true") return 1;
            return new Date(a.publishedDate) < new Date(b.publishedDate);
          })
          .map((item) => {
            if (item.debtorTitle) item.category = item.debtorTitle;
            else item.category = item.category.replace(/news/i, "").trim();
            return item;
          }),
      };
    case NEWS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default news;
