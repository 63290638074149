import { useEffect } from "react";
import { connect } from "react-redux";
import { Image } from "semantic-ui-react";
import { fetchAirlines } from "src/redux/actions/airlines";
import { AirlineState } from "src/redux/reducers/airlines";

/**
 * Airline card for flight itineraries.
 */

interface AirlineI {
  airlines: AirlineState;
  code: string;
  fetchAirlines: () => void;
}

export const Airline = ({ airlines, code, fetchAirlines }: AirlineI) => {
  useEffect(() => {
    if (!airlines.images) fetchAirlines();
  }, [airlines.images, fetchAirlines]);

  const airline = airlines.images && airlines.images[code];
  if (airline)
    return (
      <div className="airline snippet" aria-label="Airline Logo">
        <Image
          src={airline.SecondaryImage}
          title={airline.Name}
          verticalAlign="bottom"
          inline
          alt={`${airline.Name} Logo`}
        />
      </div>
    );
  return null;
};

export const mapStateToProps = (state) => ({
  airlines: state.airlines,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchAirlines: () => dispatch(fetchAirlines()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Airline);
