import { AGENT_CHAT_SET_DATA } from "src/redux/actions/agentChat";
import { LinkI } from "src/types/Link";

const agentChat = (
  state: LinkI = null,
  action: {
    type: string;
    payload: LinkI;
  }
): LinkI => {
  switch (action.type) {
    case AGENT_CHAT_SET_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default agentChat;
