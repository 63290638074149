import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { BI } from "src/common/constants";
import { handleErrors } from "src/common";

export const fetchBIData = (id) => {
  const url = getConfig(BI).url;
  if (url && id) {
    return get({ url: `${url}/${id}` })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${BI} url or ID`));
  }
};

/**
 * Takes raw BI data and compile to top airlines list
 */
export const compileTopAirlines = (data, spendType = "", duration = "M") =>
  compileBasicBI(data, "TAL", spendType, duration);

export const compileAdvancePurchase = (
  data,
  spendType = "",
  duration = "M"
) => {
  // Gather initial data and then strip out any first char 0s in the timeFrames
  // ala turn 04-5 days into 4-5 days
  const compiledData = compileBasicBI(data, "ADP", spendType, duration);
  compiledData.forEach((d) => {
    if (d.groupByValue && d.groupByValue[0] === "0") {
      d.groupByValue = d.groupByValue.slice(1);
    }
  });
  return compiledData;
};

export const compileAirSpend = (data, spendType = "", duration = "M") =>
  compileBasicBI(data, "ASP", spendType, duration, "monthYear");

export const compileAccomSpend = (data, spendType = "", duration = "M") =>
  compileBasicBI(data, "ACC", spendType, duration, "monthYear");

export const compileServiceType = (data, spendType = "", duration = "M") =>
  compileBasicBI(data, "SVT", spendType, duration);

export const compileBasicBI = (
  data,
  typeCode,
  spendType = "",
  duration = "M",
  groupBy = "groupByValue"
) => {
  const grouped = [];
  data
    .filter(
      (f) =>
        f.dataTypeCode === typeCode &&
        (f.spendType === spendType ||
          spendType === "" ||
          spendType === "all") &&
        f.timePeriod === duration
    )
    .forEach((d) => {
      let existing = grouped.filter((f) => f[groupBy] === d[groupBy])[0];
      if (!existing) {
        grouped.push({ ...d });
      } else {
        existing.amount += d.amount;
        existing.transactionCount += d.transactionCount;
      }
    });

  return grouped.sort((lft, rgt) => {
    if (lft.amount > rgt.amount) return -1;
    else if (lft.amount < rgt.amount) return 1;
    else return 0;
  });
};
