import SVGIcon from "src/components/SVGIcon";

const PlaneIcon = ({ title = "Plane", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M32 15.868c0-1.104-1.262-2.050-2.681-2.050h-8.202l-6.625-10.567c-0.789-1.262-1.577-1.893-3.785-1.577-1.735 0.158-0.946 2.050-0.158 4.101l3.154 8.202c-3.154 0-5.836 0.315-8.044 0.631-0.473 0-0.789-0.158-0.946-0.473l-2.208-3.47c-0.473-0.631-0.789-0.946-1.893-0.789-0.946 0.158-0.473 1.104-0.158 2.050l1.419 3.47c-0.631 0.158-0.946 0.473-1.104 0.631 0 0 0 0 0 0s0 0 0 0c0.158 0.158 0.473 0.473 1.104 0.631l-1.419 3.47c-0.473 1.104-0.789 2.050 0.158 2.050 1.104 0.158 1.577-0.158 1.893-0.789l2.208-3.47c0.158-0.315 0.473-0.473 0.946-0.473 2.208 0.315 5.047 0.473 8.044 0.631l-3.154 8.202c-0.789 2.050-1.577 3.785 0.158 4.101 2.208 0.158 2.839-0.315 3.785-1.577l6.625-10.567h8.202c1.419-0.315 2.681-1.262 2.681-2.366v0z"
      ></path>
    </SVGIcon>
  );
};

export default PlaneIcon;
