import SVGIcon from "src/components/SVGIcon";

const ApproveIcon = ({ title = "Approve", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        className="ctm-default-text-color"
        fillRule="evenodd"
        d="m27.17,8.38h-4.99c-.36,0-.59-.24-.59-.59v-1.19c.02-1.62-1.29-2.95-2.91-2.97-.02,0-.04,0-.06,0h-5.34c-1.66,0-3.09,1.31-3.09,2.97v1.19c0,.36-.24.59-.48.59h-5.11c-1.62-.02-2.95,1.29-2.97,2.91,0,.02,0,.04,0,.06v14.06c-.02,1.62,1.29,2.95,2.91,2.97.02,0,.04,0,.06,0h22.56c1.62.02,2.95-1.29,2.97-2.91,0-.02,0-.04,0-.06v-14.06c.02-1.62-1.29-2.95-2.91-2.97-.02,0-.04,0-.06,0h0Zm-7.96-.59c0,.36-.24.59-.59.59h-5.34c-.36,0-.71-.24-.71-.59v-1.19c.12-.36.36-.59.71-.59h5.34c.36,0,.59.24.59.59v1.19Zm-3.32,4.19c-3.54,0-6.4,2.87-6.4,6.4s2.87,6.4,6.4,6.4,6.4-2.87,6.4-6.4-2.87-6.4-6.4-6.4Zm4.16,4.77l-5.1,5.1c-.28.28-.74.29-1.03,0,0,0,0,0,0,0l-2.19-2.19c-.28-.28-.29-.74,0-1.03,0,0,0,0,0,0,.28-.28.74-.29,1.03,0,0,0,0,0,0,0l1.67,1.67,4.59-4.59c.28-.28.74-.29,1.03,0,0,0,0,0,0,0,.28.28.29.74,0,1.03,0,0,0,0,0,0Z"
      />
    </SVGIcon>
  );
};

export default ApproveIcon;
