import SVGIcon from "src/components/SVGIcon";

const EmailIcon = ({ title = "Email", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M32 23.802v-15.605c0-1.975-1.778-3.753-3.951-3.753h-24.099c-2.173 0-3.951 1.778-3.951 3.753v15.605c0 1.975 1.778 3.753 3.951 3.753h24.099c2.173 0 3.951-1.778 3.951-3.753zM27.259 24.198c-0.198 0-0.395 0-0.593-0.198l-7.704-6.913-2.173 1.778c-0.198 0.198-0.395 0.198-0.593 0.198s-0.395 0-0.593-0.198l-2.173-1.778-7.704 6.913c-0.197 0.198-0.395 0.198-0.593 0.198s-0.593 0-0.79-0.198c-0.395-0.395-0.395-0.988 0-1.383l7.704-6.716-7.901-6.123c-0.395-0.395-0.395-0.988-0.197-1.383 0.395-0.395 0.988-0.395 1.383-0.197l10.667 8.494 10.667-8.494c0.395-0.395 0.988-0.197 1.383 0.197s0.395 0.988-0.198 1.383l-7.704 6.123 7.704 6.716c0.395 0.395 0.395 0.988 0 1.383 0 0.198-0.198 0.198-0.593 0.198z"
      ></path>
    </SVGIcon>
  );
};

export default EmailIcon;
