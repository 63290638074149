import {
  chooseRandom,
  convertDataToCSVDownload,
  formatDate,
  formatDateTime,
  formatPrice,
  getRandomInt,
  getRandomNumber,
  sortItinerariesByTime,
} from "src/common";
import moment from "moment";
import { approvalStatusMap, bookingStatusMap } from "src/services/approve";
import { getAirport, getPNR, multiplyAndRound } from "./helpers";

const airlineCodes = ["AA", "DL", "UA"];
const airlineNames = {
  AA: "American Airlines",
  DL: "Delta",
  UA: "United Airlines",
};

const carRentals = [
  "Enterprise",
  "National",
  "Alamo",
  "Hertz",
  "Avis",
  "Dollar",
];

const cities = [
  "Los Angeles",
  "Las Vegas",
  "Seattle",
  "Chicago",
  "New York",
  "Miami",
  "Austin",
  "Denver",
  "Portland",
  "San Francisco",
  "Nashville",
  "Charlotte",
  "Pasadena",
];

const flightClasses = [
  "Basic",
  "Economy",
  "Premium Economy",
  "Business",
  "First",
];

const hotels = [
  "Westin",
  "Wyndham",
  "Hyatt",
  "Sheraton",
  "DoubleTree",
  "Hyatt Regency",
  "Hilton",
  "Hampton",
  "Courtyard",
  "Crowne Plaza",
  "Marriot",
  "Hilton Garden Inn",
  "Fairfield Inn & Suites",
  "Embassay Suites",
  "JW Marriot",
];

const getCity = (city) => {
  let result = chooseRandom(cities);
  while (result === city) {
    result = chooseRandom(cities);
  }
  return result;
};

const getAirlineCode = (airlineCode) => {
  let result = chooseRandom(airlineCodes);
  while (result === airlineCode) {
    result = chooseRandom(airlineCodes);
  }
  return result;
};

const getApprovers = async (approval) => {
  const approvers = [];
  const total = getRandomInt(1, 5);
  const { generateEmail } = await import("./names.js");
  for (let i = 0; i < total; i++) {
    const isLast = i === total - 1;
    approvers.push({
      email: generateEmail(),
      status: getApproverStatus({
        approvalStatus: approval.approvalStatus,
        approvers,
        isLast,
      }),
    });
  }
  return approvers;
};

const getApproverStatus = ({ approvalStatus, approvers, isLast }) => {
  /* 
    // From B/E Enum
    public enum ApprovalStatusEnum
      {
          Unknown = 0,
          Approved = 1,
          Denied = 2, 
          Deferred = 3,
          Pending = 4,
      }
  
  */

  const isApproved = approvalStatus === "Approved";
  const isDenied = approvalStatus === "Denied";

  // If it's not approved or denied, we don't need to worry about the other statuses for now
  if (!isApproved && !isDenied) return 0;

  // If it's denied, we only want one denial per approvers array
  if (isDenied && approvers.find((item) => item.status === 2)) return 0;
  if (!isLast) {
    if (isApproved) {
      return Math.random() >= 0.5 ? 1 : 0;
    } else {
      return Math.random() >= 0.6 ? 2 : Math.random() > 0.75 ? 1 : 0;
    }
  }

  // For the last item in the array, we need to make sure there is at least one approve/deny in there
  if (isApproved) {
    if (!approvers.find((item) => item.status === 1)) return 1;
    else return Math.random() >= 0.6 ? 1 : 0;
  } else {
    // We already checked earlier if there is a denial in the approver array. In this case, we need to force a denial.
    return 2;
  }
};

const getCarRental = () => {
  return chooseRandom(carRentals);
};

const getHotel = () => {
  return chooseRandom(hotels);
};

const getFlightNumber = () => {
  return getRandomInt(100, 999).toString();
};

const getReferenceNumber = () => {
  return getRandomInt(1000000, 9999999).toString();
};

const airReasonCodes = [
  "1A - Carrier Preference",
  "1B - Alternative Airport Declined",
];

const genericReasonCodes = [
  "1C - Flexibility Required",
  "1D - Approval to Travel Out of Policy",
  "1E - Traveling with Client or Colleague",
  "1F - Medical Exemption",
];
const getReasonCodes = (approval) => {
  // Have half of approvals have reason codes
  const flip = Math.random();
  if (flip >= 0.5) return null;
  if (approval.hasAir) {
    return flip >= 0.2
      ? [chooseRandom(airReasonCodes)]
      : [chooseRandom(genericReasonCodes)];
  }
  return [chooseRandom(genericReasonCodes)];
};

const approvalStatuses = ["Approved", "Denied", "Expired", "Ignored"];
const bookingStatuses = ["Ticketed", "Confirmed", "Cancelled"];

let numberOfPending = 0;

const getApprovalStatus = () => {
  // We don't want too many pending if we have a big data set. This usually nets us around 10 - 25 which is good.
  if (numberOfPending > 40 || Math.random() > 0.015) {
    return chooseRandom(approvalStatuses);
  } else {
    numberOfPending++;
    return "Pending";
  }
};

// Factory function for spitting out random itineraries
const generateItinerary = (args = {}) => {
  const { hasAir, hasCar, hasHotel, hasLimo } = args;
  // Most itineraries should have air
  const includeAir = hasAir || Math.random() >= 0.1;
  // Itineraries without air are more like to have a car
  const includeCar = hasCar
    ? true
    : includeAir
    ? Math.random() >= 0.75
    : Math.random() >= 0.35;
  // If there is no air or car, then of course we need a hotel
  const includeHotel = hasHotel
    ? true
    : includeAir || includeCar
    ? Math.random() >= 0.65
    : true;

  const includeLimo = hasLimo || Math.random() >= 0.75;
  // Add a multiplier for total cost based on the amount of itin items we have
  const multiplier = includeAir + includeCar + includeHotel + includeLimo;
  const totalCost = getRandomNumber(
    100 + 100 * multiplier,
    500 + 500 * multiplier
  );
  const { airCost, carCost, hotelCost, limoCost, lowestLogical } =
    generateTotals({
      totalCost,
      hasAir: includeAir,
      hasCar: includeCar,
      hasHotel: includeHotel,
      hasLimo: includeLimo,
    });
  return {
    hasAir: includeAir,
    hasCar: includeCar,
    hasHotel: includeHotel,
    hasLimo: includeLimo,
    airCost,
    carCost,
    hotelCost,
    limoCost,
    totalCost,
    lowestLogical,
    ...generateDates(),
  };
};

const generateDates = (advancedPurchase) => {
  const approvalStatus = getApprovalStatus();
  const bookingStatus = chooseRandom(bookingStatuses);
  // Pending should be booked recently, but we don't want the expiration to be over
  const bookedDate =
    approvalStatus === "Pending"
      ? moment().subtract(getRandomInt(5, 60 * 24), "minutes")
      : moment().subtract(getRandomInt(3, 999), "days");
  const travelDate = moment(bookedDate)
    .add(advancedPurchase || getRandomInt(3, 180), "days")
    .add(getRandomInt(1, 60 * 24), "minutes");
  if (travelDate.hours() < 6) travelDate.add(6, "hours");
  const expirationDate = moment(bookedDate).add(
    getRandomInt(60 * 24, 60 * 24 * 3),
    "minutes"
  );
  return {
    approvalStatus,
    bookingStatus,
    bookedDate,
    expirationDate,
    travelDate,
  };
};

// Sometime lowest logical should be the same as the airFare,
// sometimes a bit lower
const generateLowestLogical = (total) => {
  const result =
    Math.random() >= 0.5
      ? total
      : getRandomNumber(Math.floor(total * 0.7), total);
  return result;
};

const generateTotals = ({ totalCost, hasAir, hasCar, hasHotel, hasLimo }) => {
  let airCost = 0;
  let carCost = 0;
  let hotelCost = 0;
  let limoCost = 0;
  let lowestLogical = 0;
  // Prioritize air as the most expensive itin item
  if (hasAir) {
    if (!hasCar && !hasHotel) {
      airCost = totalCost;
    } else if (hasCar && hasHotel && hasLimo) {
      airCost = getRandomNumber(
        multiplyAndRound(totalCost, 0.3),
        multiplyAndRound(totalCost, 0.55)
      );
    } else {
      airCost = getRandomNumber(
        multiplyAndRound(totalCost, 0.55),
        multiplyAndRound(totalCost, 0.75)
      );
    }
    lowestLogical = generateLowestLogical(airCost);
  }
  let remainder = totalCost - airCost;

  if (hasCar) {
    if (!hasHotel && !hasLimo) {
      carCost = remainder;
    } else {
      carCost = getRandomNumber(
        multiplyAndRound(remainder, 0.45),
        multiplyAndRound(remainder, 0.6)
      );
    }
  }

  remainder = remainder - carCost;

  if (hasHotel) {
    if (!hasLimo) {
      hotelCost = remainder;
    } else {
      hotelCost = getRandomNumber(
        multiplyAndRound(remainder, 0.55),
        multiplyAndRound(remainder, 0.75)
      );
    }
  }

  if (hasLimo) {
    limoCost = remainder - hotelCost;
  }

  return { airCost, carCost, hotelCost, limoCost, lowestLogical };
};

const policyConditions = {
  // Avoid commas
  approvalAlwaysRequired: {
    title: "Approval Always Required",
    description: "Users in this Travel Class always require approval.",
  },
  flightsGreaterThan200: {
    title: "Flights Greater than $200 over Lowest Logical Air Fare",
    description:
      "If flights are greater than the lowest logical airfaire + $200, approval is required.",
  },
  itineraryTotalCostOver1000: {
    title: "Itinerary Total Max Price Greater than $1000",
    description:
      "If the itinerary total max price is greater than $1000 approval is required.",
  },
  airfareTotalOver500: {
    title: "Air Fare Total Max Price Greater than $500",
    description:
      "If the air fare total max price is greater than $500 approval is required.",
  },
  hotelTotalOver300: {
    title: "Hotel Rate Max Price Greater than $300",
    description:
      "If the hotel rate max price is greater than $300 approval is required.",
  },
  countryCheck: {
    title: "Country Check",
    description:
      "If the booking is included or excluded from a list of countries provided approval is required.",
  },
  advancedPurchase: {
    title: "Advanced Purchase Less than 14 days prior to departure",
    description:
      "If the booking is made less than 14 days prior to departure approval is required.",
  },
  internationalFlightWarning: {
    title: "International Flight Warning",
    description:
      "If the flights are or aren't international approval is required.",
  },
  airClassServiceNotAvailable: {
    title: "Air Class of Service not available",
    description:
      "If the flights are included or excluded from a list of classes of service available approval is required.",
  },
};

// We want to add additional failed/passed policies
// These numbers help tune the amount that show up, based on UX preference
const failRatio = 0.65;
const passRatio = 0.875;

const getPolicyConditions = (policyCondition, approval) => {
  const additionalPolicyConditions = [];

  // Check to make sure we haven't already added a policyCondition before checking it
  if (
    policyCondition !== policyConditions.flightsGreaterThan200 &&
    approval.hasAir
  ) {
    if (approval.airCost - 200 > approval.lowestLogical) {
      Math.random() > failRatio &&
        additionalPolicyConditions.push(policyConditions.flightsGreaterThan200);
    } else {
      Math.random() > passRatio &&
        additionalPolicyConditions.push({
          valid: true,
          ...policyConditions.flightsGreaterThan200,
        });
    }
  }

  if (policyCondition !== policyConditions.itineraryTotalCostOver1000) {
    if (approval.totalCost > 1000) {
      Math.random() > failRatio &&
        additionalPolicyConditions.push(
          policyConditions.itineraryTotalCostOver1000
        );
    } else {
      Math.random() > passRatio &&
        additionalPolicyConditions.push({
          ...policyConditions.itineraryTotalCostOver1000,
          valid: true,
        });
    }
  }

  if (
    policyCondition !== policyConditions.airfareTotalOver500 &&
    approval.hasAir
  ) {
    if (approval.airCost > 500) {
      Math.random() > failRatio &&
        additionalPolicyConditions.push(policyConditions.airfareTotalOver500);
    } else {
      Math.random() > passRatio &&
        additionalPolicyConditions.push({
          ...policyConditions.airfareTotalOver500,
          valid: true,
        });
    }
  }

  if (
    policyCondition !== policyConditions.hotelTotalOver300 &&
    approval.hasHotel
  ) {
    if (approval.hotelCost > 300) {
      Math.random() > failRatio &&
        additionalPolicyConditions.push(policyConditions.hotelTotalOver300);
    } else {
      Math.random() > passRatio &&
        additionalPolicyConditions.push({
          ...policyConditions.hotelTotalOver300,
          valid: true,
        });
    }
  }

  if (policyCondition !== policyConditions.advancedPurchase) {
    if (
      moment.duration(approval.bookedDate.diff(approval.travelDate)).asDays() >=
      14
    ) {
      Math.random() > failRatio &&
        additionalPolicyConditions.push(policyConditions.advancedPurchase);
    } else {
      Math.random() > passRatio &&
        additionalPolicyConditions.push({
          ...policyConditions.advancedPurchase,
          valid: true,
        });
    }
  }

  return [policyCondition, ...additionalPolicyConditions];
};

const generateApproval = ({
  type,
  itinerary = null,
  itinParams = {},
  dateParams = null,
}) => {
  const policyCondition = policyConditions[type];
  const itin = itinerary || generateItinerary(itinParams);
  const approval = {
    ...itin,
    ...generateDates(dateParams),
  };
  const conditions = getPolicyConditions(policyCondition, approval);

  return {
    policyConditions: conditions,
    ...itin,
    ...generateDates(dateParams),
  };
};

const approvalAlwaysRequired = () => {
  return generateApproval({
    type: "approvalAlwaysRequired",
  });
};

const flightsGreaterThan200 = () => {
  const threshhold = getRandomInt(400, 3500);
  const hasCar = Math.random() >= 0.65;
  const hasHotel = Math.random() >= 0.65;
  const hasLimo = Math.random() >= 0.65;
  const airCost = getRandomNumber(threshhold + 201, threshhold + 400);
  const carCost = hasCar ? getRandomNumber(70, 470) : 0;
  const hotelCost = hasHotel ? getRandomNumber(120, 700) : 0;
  const limoCost = hasLimo ? getRandomNumber(50, 250) : 0;
  const totalCost = airCost + carCost + hotelCost + limoCost;
  const lowestLogical = getRandomNumber(
    Math.max(airCost - 500, 100),
    airCost - 200
  );

  return generateApproval({
    type: "flightsGreaterThan200",
    itinerary: {
      hasAir: true,
      hasCar,
      hasHotel,
      hasLimo,
      airCost,
      carCost,
      hotelCost,
      limoCost,
      totalCost,
      lowestLogical,
    },
  });
};

const itineraryTotalCostOver1000 = () => {
  const hasAir = Math.random() >= 0.25;
  const hasCar = Math.random() >= 0.65;
  const hasHotel = (!hasAir && !hasCar) || Math.random() >= 0.7;
  const hasLimo = (!hasAir && !hasCar && !hasHotel) || Math.random() >= 0.7;
  const totalCost = getRandomNumber(1001, 4500);
  const { airCost, carCost, hotelCost, limoCost, lowestLogical } =
    generateTotals({
      totalCost,
      hasAir,
      hasCar,
      hasHotel,
      hasLimo,
    });
  return generateApproval({
    type: "itineraryTotalCostOver1000",
    itinerary: {
      hasAir: true,
      hasCar,
      hasHotel,
      hasLimo,
      airCost,
      carCost,
      hotelCost,
      limoCost,
      totalCost,
      lowestLogical,
    },
  });
};

const airfareTotalOver500 = () => {
  const totalCost = getRandomNumber(501, 2222);
  const lowestLogical = generateLowestLogical(totalCost);

  return generateApproval({
    type: "airfareTotalOver500",
    itinerary: {
      hasAir: true,
      hasCar: false,
      hasHotel: false,
      airCost: totalCost,
      carCost: 0,
      hotelCost: 0,
      totalCost,
      lowestLogical,
    },
  });
};

const hotelTotalOver300 = () => {
  const hasAir = Math.random() >= 0.2;
  const hasCar = Math.random() >= 0.7;
  const hasHotel = true;
  const hotelCost = getRandomNumber(301, 1100);
  const carCost = hasCar ? getRandomNumber(75, 580) : 0;
  const airCost = hasAir ? getRandomNumber(200, 1500) : 0;
  const totalCost = airCost + carCost + hotelCost;
  return generateApproval({
    type: "hotelTotalOver300",
    itinerary: {
      hasAir,
      hasCar,
      hasHotel,
      airCost,
      carCost,
      hotelCost,
      totalCost,
      lowestLogical: hasAir ? generateLowestLogical(airCost) : 0,
    },
  });
};

const countryCheck = () => {
  return generateApproval({
    type: "countryCheck",
  });
};

const advancedPurchase = () => {
  return generateApproval({
    type: "advancedPurchase",
    dateParams: getRandomInt(1, 13),
  });
};

const internationalFlightWarning = () => {
  return generateApproval({
    type: "internationalFlightWarning",
    itinParams: { hasAir: true },
  });
};

const airClassServiceNotAvailable = () => {
  return generateApproval({
    type: "airClassServiceNotAvailable",
    itinParams: { hasAir: true },
  });
};

const approvalTemplates = [
  approvalAlwaysRequired,
  flightsGreaterThan200,
  itineraryTotalCostOver1000,
  airfareTotalOver500,
  hotelTotalOver300,
  countryCheck,
  advancedPurchase,
  internationalFlightWarning,
  airClassServiceNotAvailable,
];

const getItinerary = (approval) => {
  const tripLength = getRandomInt(60 * 24, 60 * 24 * 5);
  const itin = {
    carRentals: [],
    flights: { outbound: [] },
    hotels: [],
    limos: [],
  };
  let declinedItineraries;
  let secondaryTime = moment(approval.travelDate).add(tripLength, "minutes");
  if (secondaryTime.hours() < 6) secondaryTime.add(6, "hours");
  if (approval.hasAir) {
    const originAirport = getAirport();
    const destinationAirport = getAirport(originAirport);
    const airlineCode = getAirlineCode();

    const declinedAirlineCode = getAirlineCode(airlineCode);
    const declinedTime = moment(approval.travelDate).add(24, "minutes");
    const declinedSecondaryTime = moment(secondaryTime).add(26, "minutes");

    const origin = getCity();
    const destination = getCity(origin);

    itin.flights.outbound.push({
      carrier: airlineCode,
      carrierFullName: airlineNames[airlineCode],
      cabinClass: chooseRandom(flightClasses),
      operatingFlightNumber: getFlightNumber(),
      locations: {
        origin: {
          airportCode: originAirport,
          cityName: origin,
          localTime: formatDateTime(approval.travelDate),
        },
        destination: {
          airportCode: destinationAirport,
          cityName: destination,
          localTime: formatDateTime(approval.travelDate),
        },
      },
    });

    itin.flights.outbound.push({
      carrier: airlineCode,
      carrierFullName: airlineNames[airlineCode],
      cabinClass: chooseRandom(flightClasses),
      operatingFlightNumber: getFlightNumber(),
      locations: {
        origin: {
          airportCode: destinationAirport,
          cityName: destination,
          localTime: formatDateTime(secondaryTime),
        },
        destination: {
          airportCode: originAirport,
          cityName: origin,
          localTime: formatDateTime(secondaryTime),
        },
      },
    });

    if (Math.random() >= 0.8) {
      const declinedPNR = getPNR();
      const origin = getCity();
      const destination = getCity(origin);

      declinedItineraries = [
        {
          carrier: declinedAirlineCode,
          carrierName: airlineNames[declinedAirlineCode],
          departureDateTime: declinedTime,
          location: destination,
          locationCode: destinationAirport,
          flightNumber: getFlightNumber(),
          originLocation: origin,
          originLocationCode: originAirport,
          pnr: declinedPNR,
          flightClass: chooseRandom(flightClasses),
        },
        {
          carrier: declinedAirlineCode,
          carrierName: airlineNames[declinedAirlineCode],
          departureDateTime: declinedSecondaryTime,
          location: origin,
          locationCode: originAirport,
          flightNumber: getFlightNumber(),
          originLocation: destination,
          originLocationCode: destinationAirport,
          pnr: declinedPNR,
          flightClass: chooseRandom(flightClasses),
        },
      ];
    }
  }

  if (approval.hasCar) {
    const pickUp = chooseRandom(cities);
    const dropOff = chooseRandom(cities);
    const dateStart = moment(approval.travelDate).add(2, "hours").minute(0);
    const dateEnd = moment(secondaryTime).hour(15).minute(0);
    const days = Math.ceil(dateEnd.diff(dateStart, "days", true));
    itin.carRentals.push({
      dateStart,
      dateStartLocal: formatDateTime(dateStart),
      dateEnd,
      dateEndLocal: formatDateTime(dateEnd),
      days,
      pickUp,
      dropOff,
      carType: "SUV",
      pickUpCity: pickUp,
      dropOffCity: dropOff,
      numCars: 1,
      rate: (approval.carCost / days).toFixed(2),
      location: {
        pickUp: {
          title: getCarRental(),
        },
      },
      referenceNumber: getReferenceNumber(),
    });
  }

  if (approval.hasHotel) {
    const hotelStart = moment(approval.travelDate).hour(15).minute(0);
    const hotelEnd = moment(secondaryTime).hour(11).minute(0);
    const nights = Math.ceil(hotelEnd.diff(hotelStart, "days", true)) || 1;

    itin.hotels.push({
      company: {
        title: getHotel(),
        address: getCity(),
      },
      location: getCity(),
      statuses: {
        stay: {
          dateStart: hotelStart,
          dateStartLocal: formatDateTime(hotelStart),
          dateEnd: hotelEnd,
          dateEndLocal: formatDateTime(hotelEnd),
        },
      },
      hotelRates: [
        {
          rateNum: 1,
          rate: (approval.hotelCost / nights).toFixed(2),
          originalRate: approval.hotelCost,
          start: formatDateTime(hotelStart),
          end: formatDateTime(hotelEnd),
          nights: nights,
        },
      ],
    });
  }

  if (approval.hasLimo) {
    const limoStart = moment(approval.travelDate).add(5, "hours").minute(0);
    const limoEnd = moment(secondaryTime).subtract(10, "hours").minute(0);
    itin.limos.push({
      dateStart: limoStart,
      dateEnd: limoEnd,
      referenceNumber: getRandomInt(10000000, 999999999),
      pickUp: getCity(),
      dropOff: getCity(),
      dateStartLocal: formatDateTime(limoStart),
      dateEndLocal: formatDateTime(limoEnd),
    });
  }

  return { itinerary: itin, declinedItineraries };
};

// Take the raw data made from the factory and map it to mock the BE response
const mapApproval = async (approval, index) => {
  const { generateName } = await import("./names");
  const traveler = generateName();
  const { itinerary, declinedItineraries } = getItinerary(approval);
  const approvers = await getApprovers(approval);
  return {
    airTotalCost: approval.airCost ? formatPrice(approval.airCost, 2) : null,
    approvers,
    bookedFormatted: formatDate(approval.bookedDate),
    bookingDate: approval.bookedDate,
    carTotalCost: approval.carCost ? formatPrice(approval.carCost, 2) : null,
    limoTotalCost: approval.limoCost ? formatPrice(approval.limoCost, 2) : null,
    customText: "",
    declinedItineraries: declinedItineraries,
    disclaimers: null,
    expireAt: approval.expirationDate,
    expirationDate: approval.expirationDate,
    expiresFormatted: formatDate(approval.expirationDate),
    hotelTotalCost: approval.hotelCost
      ? formatPrice(approval.hotelCost, 2)
      : null,
    id: index,
    itinerary,
    lostSavings: declinedItineraries
      ? formatPrice(getRandomNumber(12, 200), 2)
      : null,
    lowestLogicalFare: approval.lowestLogical
      ? formatPrice(approval.lowestLogical, 2)
      : null,
    pnr: getPNR(),
    policyConditions: approval.policyConditions,
    priceFormatted: `$${approval.totalCost.toFixed(2)}`,
    reasonCodes: getReasonCodes(approval),
    remarks: null,
    approvalStatus: approval.approvalStatus,
    bookingStatus: approval.bookingStatus,
    totalCost: approval.totalCost ? formatPrice(approval.totalCost, 2) : null,
    totalEstimate: approval.totalEstimate
      ? formatPrice(approval.totalEstimate, 2)
      : null,
    travelDate: approval.travelDate,
    travelDateFormatted: formatDate(approval.travelDate),
    traveler,
    travelerUpper: traveler.toUpperCase(),
  };
};

export const getMockApprovals = async () => {
  const approvals = [];
  const count = 12345;
  for (let i = 0; i < count; i++) {
    approvals.push(chooseRandom(approvalTemplates)());
  }
  const mappedApprovals = await Promise.all(approvals.map(await mapApproval));
  return mappedApprovals;
};

const sortApprovals = (params, approvals) => {
  const { sortColumn, sortDirection } = params;

  if (sortColumn === "traveler")
    return approvals.sort((a, b) => {
      if (sortDirection === "ascending") {
        return a.traveler < b.traveler ? -1 : 1;
      }
      return a.traveler < b.traveler ? 1 : -1;
    });

  if (sortColumn === "pnr")
    return approvals.sort((a, b) => {
      if (sortDirection === "ascending") {
        return a.pnr < b.pnr ? -1 : 1;
      }
      return a.pnr < b.pnr ? 1 : -1;
    });

  if (sortColumn === "approvalStatus")
    return approvals.sort((a, b) => {
      if (sortDirection === "ascending") {
        return a.approvalStatus < b.approvalStatus ? -1 : 1;
      }
      return a.approvalStatus < b.approvalStatus ? 1 : -1;
    });

  if (sortColumn === "bookingStatus")
    return approvals.sort((a, b) => {
      if (sortDirection === "ascending") {
        return a.bookingStatus < b.bookingStatus ? -1 : 1;
      }
      return a.bookingStatus < b.bookingStatus ? 1 : -1;
    });

  if (sortColumn === "priceFormatted")
    return approvals.sort((a, b) => {
      return sortDirection === "ascending"
        ? a.totalCost.amount - b.totalCost.amount
        : b.totalCost.amount - a.totalCost.amount;
    });

  if (sortColumn === "bookedFormatted")
    return approvals.sort((a, b) => {
      const isBefore = a.bookingDate.isBefore(b.bookingDate);
      if (sortDirection === "ascending") return isBefore ? -1 : 1;
      else return isBefore ? 1 : -1;
    });

  if (sortColumn === "travelDateFormatted")
    return approvals.sort((a, b) => {
      const isBefore = a.travelDate.isBefore(b.travelDate);
      if (sortDirection === "ascending") return isBefore ? -1 : 1;
      else return isBefore ? 1 : -1;
    });

  if (sortColumn === "expiresFormatted")
    return approvals.sort((a, b) => {
      const isBefore = a.expirationDate.isBefore(b.expirationDate);
      if (sortDirection === "ascending") return isBefore ? -1 : 1;
      else return isBefore ? 1 : -1;
    });
};

export const mockApprovals = () => {
  let approvals = [];

  const changeApprovalStatus = (params) => {
    const approval = approvals.find((approval) => approval.id === params.id);
    if (params.approvalStatus === "approved")
      approval.approvalStatus = "Approved";
    else approval.approvalStatus = "Denied";
  };

  const transferApproval = (params) => {
    approvals = approvals.filter((approval) => approval.id !== params.id);
  };

  const fetchApproval = (id) => {
    // eslint-disable-next-line
    return approvals.find((approval) => approval.id == id);
  };

  const filterApprovals = (params, approvals) => {
    return approvals.filter((approval) => {
      if (params.query && params.query.length) {
        const upperQuery = params.query.toUpperCase();
        if (
          !approval.pnr.includes(upperQuery) &&
          !approval.travelerUpper.includes(upperQuery)
        )
          return false;
      }

      if (
        params.selectedTab === "History" &&
        params.startDate &&
        params.startDate.isAfter(approval.travelDate)
      )
        return false;

      if (
        params.selectedTab === "History" &&
        params.endDate &&
        params.endDate.isBefore(approval.travelDate)
      )
        return false;

      if (params.selectedTab === "History") {
        const filteredApprovalStatuses = params.approvalStatuses.map(
          (s) => approvalStatusMap[s]
        );
        const filteredBookingStatuses = params.bookingStatuses.map(
          (s) => bookingStatusMap[s]
        );
        return (
          filteredApprovalStatuses.includes(approval.approvalStatus) &&
          filteredBookingStatuses.includes(approval.bookingStatus)
        );
      } else return approval.approvalStatus === "Pending";
    });
  };

  const fetchApprovals = async (params) => {
    // Instead of pre-populating approvals, wait until fetch is called so that the function only runs for people using mock data
    if (!approvals.length) approvals = await getMockApprovals();

    const filteredApprovals = filterApprovals(params, approvals);

    const sortedApprovals = params.sortColumn
      ? sortApprovals(params, filteredApprovals)
      : filteredApprovals;

    const pagedApprovals = sortedApprovals.slice(
      (params.page - 1) * 9,
      params.page * 9
    );

    return {
      bookingRequests: pagedApprovals,
      pagination: {
        totalCount: sortedApprovals.length,
      },
    };
  };

  const renderItinTime = (time) => time.format("MMMM Do YYYY h:mm:ss a");

  const mapItineraryForCSV = (itin) => {
    const sortedItin = sortItinerariesByTime(itin);
    return sortedItin
      .flatMap((itin) => {
        if (itin.type === "flight") {
          return `Air ${itin.operatingFlightNumber} ${
            itin.locations.origin.airportCode
          } ${itin.locations.destination.airportCode} ${renderItinTime(
            itin.time
          )}`;
        } else if (itin.type === "hotel") {
          return `Hotel ${itin.company.title} ${itin.location} ${renderItinTime(
            itin.time
          )}`;
        } else if (itin.type === "carRental") {
          return `Car ${itin.location.pickUp.title} ${
            itin.pickUp
          } ${renderItinTime(itin.time)}`;
        } else return [];
      })
      .join(" | ");
  };

  const totalToNumber = (total) =>
    total ? Number(total.replace(/[^0-9.-]+/g, "")) : 0;

  const mapApprovalForCSVRow = (approval) => {
    return [
      approval.id,
      approval.traveler,
      approval.pnr,
      formatDateTime(approval.bookingDate),
      approval.approvalStatus,
      approval.bookingStatus,
      formatDateTime(approval.travelDate),
      formatDateTime(approval.expirationDate),
      approval.lowestLogicalFare
        ? totalToNumber(approval.lowestLogicalFare)
        : "",
      approval.approvers.map((a) => a.email).join(" | "),
      mapItineraryForCSV(approval.itinerary),
      approval.policyConditions.map((pc) => pc.title).join(" | "),
      totalToNumber(approval.totalCost),
      totalToNumber(approval.airTotalCost),
      totalToNumber(approval.hotelTotalCost),
      totalToNumber(approval.carTotalCost),
    ];
  };

  const legend = [
    "Id",
    "Traveler",
    "Pnr",
    "BookingDate",
    "ApprovalStatus",
    "BookingStatus",
    "TravelDate",
    "ExpirationDate ",
    "LowestLogicalFare",
    "Approvers",
    "Itinerary",
    "PolicyConditions",
    "TotalCost",
    "AirTotalCost",
    "HotelTotalCost",
    "CarTotalCost",
  ];

  const fetchMockApprovalsCSV = async (params) => {
    // Instead of pre-populating approvals, wait until fetch is called so that the function only runs for people using mock data
    if (!approvals.length) approvals = await getMockApprovals();

    const filteredApprovals = filterApprovals(params, approvals);

    const sortedApprovals = params.sortColumn
      ? sortApprovals(params, filteredApprovals)
      : filteredApprovals;

    const mappedApprovals = sortedApprovals.map(mapApprovalForCSVRow);
    const rows = [legend, ...mappedApprovals];
    convertDataToCSVDownload(rows, "approval-history.csv");
  };

  const changeMockApprovalStatus = (params) => {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(changeApprovalStatus(params));
      }, 150);
    });
    return promise;
  };

  const fetchMockApproval = (id) => {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchApproval(id));
      }, 150);
    });
    return promise;
  };

  const fetchMockApprovals = (params) => {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchApprovals(params));
      }, 100);
    });
    return promise;
  };

  const transferMockApproval = (params) => {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(transferApproval(params));
      }, 150);
    });
    return promise;
  };

  return {
    changeMockApprovalStatus,
    fetchMockApproval,
    fetchMockApprovals,
    fetchMockApprovalsCSV,
    transferMockApproval,
  };
};
