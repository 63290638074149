import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const SelfRegistration = React.lazy(() => import("./SelfRegistration"));

function SelfRegistrationPage(props) {
  usePageInit("Smart Portal - Self Registration");
  return (
    <Suspense
      fallback={
        <Loading
          addContainer
          active
          loadingText="Loading CTM SelfRegistration"
        />
      }
    >
      <SelfRegistration {...props} />
    </Suspense>
  );
}

export default SelfRegistrationPage;
