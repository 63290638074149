import { setSsoDefaults } from "src/common/shared/featureSettings";
import { SSOLinkI } from "src/types/SSOLink";
import { SSOLinksAction } from "../ssoLinks";
import { OBTS_SET_DATA } from "src/redux/actions";

const obts = (state: SSOLinkI[] = [], action: SSOLinksAction): SSOLinkI[] => {
  let result;
  switch (action.type) {
    case OBTS_SET_DATA:
      result = setSsoDefaults(action.payload);
      return result;
    default:
      return state;
  }
};

export default obts;
