import {
  getStatusesSuccess,
  getStatusesInvoked,
  GET_STATUSES,
} from "src/redux/actions/travelerTracker";
import { put, takeLatest, select } from "redux-saga/effects";
import { computeStatuses } from "src/services/itineraryDetails";

function* getStatuses({ id }) {
  yield put(getStatusesInvoked(id));
  const state = yield select();
  const itineraries = state.itineraryDetails.ids[id].data;
  const application = state.application;
  const statuses = computeStatuses(itineraries, application.flightStatusEnum);
  yield put(getStatusesSuccess(statuses, id));
}

function* travelerTrackerSaga() {
  yield takeLatest(GET_STATUSES, getStatuses);
}

export default travelerTrackerSaga;
