import SVGIcon from "src/components/SVGIcon";

const AirplaneGlobeIcon = ({ title = "Airplane Globe", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M25.454 20.005c-0.311-0.156-0.661-0.039-0.778 0.272-1.711 3.655-5.366 5.988-9.332 5.988-2.761 0-5.288-1.128-7.155-2.916 1.828-0.117 3.888-0.467 6.066-1.050l-0.739 1.594c-0.156 0.311-0.039 0.661 0.272 0.778 0.078 0.039 0.156 0.039 0.233 0.039 0.233 0 0.428-0.117 0.544-0.35l1.322-2.8c0.156-0.311 0.039-0.661-0.272-0.778l-2.839-1.361c-0.311-0.156-0.622-0.039-0.778 0.272s-0.039 0.661 0.272 0.778l1.478 0.7c-2.489 0.661-4.705 1.011-6.533 1.089-1.361-1.75-2.178-3.966-2.178-6.338 0-5.677 4.627-10.305 10.266-10.305 2.372 0 4.627 0.817 6.416 2.294 0.039 0.078 0.117 0.156 0.233 0.194 1.089 0.972 1.983 2.139 2.605 3.422 0.156 0.272 0.506 0.428 0.778 0.272s0.428-0.505 0.272-0.778c-0.506-1.050-1.167-2.022-1.944-2.839 3.072-0.039 5.172 0.622 5.638 1.905 0.078 0.233 0.311 0.389 0.544 0.389 0.078 0 0.117 0 0.194-0.039 0.311-0.117 0.467-0.428 0.35-0.739-0.7-1.944-3.344-2.877-7.349-2.683h-0.622c-2.022-1.594-4.55-2.489-7.116-2.489-5.638 0-10.305 4.083-11.277 9.488-3.189 2.411-4.55 4.861-3.85 6.727 0.661 1.75 3.033 2.683 6.416 2.683 2.1 2.489 5.211 4.044 8.71 4.044 4.472 0 8.516-2.605 10.383-6.649 0.194-0.35 0.078-0.7-0.233-0.817zM1.307 20.316c-0.389-1.206 0.544-2.916 2.605-4.705 0 0.117 0 0.233 0 0.35 0 2.333 0.7 4.472 1.867 6.261-2.489-0.117-4.083-0.817-4.472-1.905zM31.871 13.123c-0.35-0.817-1.711-1.011-2.45-1.011-0.389 0-0.739 0.039-0.972 0.156l-3.966 1.828c-1.867-1.4-3.539-2.139-4.977-2.139-1.167 0-1.75 0.505-1.828 0.544-0.117 0.117-0.194 0.272-0.194 0.428s0.039 0.311 0.156 0.428l2.605 2.8-1.672 1.011-4.627 0.156c-0.272 0-0.467 0.194-0.544 0.467s0.078 0.544 0.311 0.661l4.666 2.139c0.078 0.039 0.156 0.039 0.233 0.039s0.156 0 0.233-0.039l11.044-5.055c0.661-0.311 1.206-0.583 1.555-0.856 0.739-0.583 0.583-1.206 0.428-1.555z"
      ></path>
    </SVGIcon>
  );
};

export default AirplaneGlobeIcon;
