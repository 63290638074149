import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const SessionTimeout = React.lazy(() => import("./SessionTimeout"));

function SessionTimeoutWrapper(props) {
  usePageInit("Smart Portal - Session Timeout");
  return (
    <Suspense
      fallback={<Loading addContainer active loadingText="Loading..." />}
    >
      <SessionTimeout {...props} />
    </Suspense>
  );
}

export default SessionTimeoutWrapper;
