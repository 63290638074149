import {
  UNUSED_TICKETS_FETCH_SUCCESS,
  UNUSED_TICKETS_WITH_AIRLINES_FETCH_SUCCESS,
  UNUSED_TICKETS_FETCH_INVOKED,
  UNUSED_TICKETS_FETCH_FAILED,
} from "src/redux/actions/unusedTickets";
import { DropdownOption } from "src/types";
import { UnusedTicket } from "src/types/UnusedTicket";

interface UnusedTicketsI {
  tickets: UnusedTicket[];
  count: number;
  airlines: DropdownOption[];
  loading: boolean;
  error?: Error;
  totalAmount: number;
}

export const initialState: UnusedTicketsI = {
  tickets: null,
  count: 0,
  airlines: [],
  loading: true,
  error: null,
  totalAmount: 0,
};

function mapAirlines(airlines) {
  if (!Array.isArray(airlines)) return [];
  return airlines.map((a) => {
    return {
      key: a.label,
      text: a.label,
      value: a.label,
    };
  });
}

const unusedTickets = (
  state: UnusedTicketsI = initialState,
  action
): UnusedTicketsI => {
  switch (action.type) {
    case UNUSED_TICKETS_FETCH_INVOKED:
      return {
        ...state,
        loading: true,
        error: null,
        tickets: null,
        count: 0,
        totalAmount: 0,
      };
    case UNUSED_TICKETS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        count: action.payload.totalCount,
        tickets: action.payload.data || [],
        totalAmount:
          action.payload.totalAmounts && action.payload.totalAmounts.length
            ? action.payload.totalAmounts[0].formatted
            : 0,
      };
    case UNUSED_TICKETS_WITH_AIRLINES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        count: action.payload.totalCount,
        airlines:
          action.payload.facets && action.payload.facets.airlineName
            ? mapAirlines(action.payload.facets.airlineName)
            : [],
        tickets: action.payload.data || [],
        totalAmount:
          action.payload.totalAmounts && action.payload.totalAmounts.length
            ? action.payload.totalAmounts[0].formatted
            : 0,
      };
    case UNUSED_TICKETS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default unusedTickets;
