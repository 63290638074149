import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { handleErrors, FLIGHTDETAILS, shouldMockData } from "src/common";

async function getMockItineraries(properties) {
  const { mockItineraries } = await import("src/common/mocks/data/flights.js");
  return mockItineraries(properties, true);
}

export const fetchData = (properties) => {
  const { CompanyFeatureID } = properties;
  const url = getConfig(FLIGHTDETAILS).url;
  if (url) {
    return get({
      url,
      params: {
        CompanyFeatureID,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (shouldMockData())
          return getMockItineraries(properties).then((mockResponse) => [
            ...res,
            ...mockResponse,
          ]);
        return res;
      })
      .catch(() => {
        if (shouldMockData()) {
          return getMockItineraries(properties);
        }
      });
  } else {
    return Promise.reject(new Error(`Missing ${FLIGHTDETAILS} url`));
  }
};
