import SVGIcon from "src/components/SVGIcon";

const DataHubIcon = ({ title = "Data Hub", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 28 28" title={title} {...props}>
      <g
        id="Icons-**"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-730.000000, -1078.000000)">
          <g id="Group" transform="translate(728.000000, 1076.000000)">
            <rect
              id="Rectangle-Copy-149"
              x="0"
              y="0"
              width="32"
              height="32"
            ></rect>
            <g transform="translate(2.000000, 2.000000)" id="Path">
              <path
                d="M13.5080267,21.0185665 C11.1991551,20.9808855 9.1654442,19.7978786 7.90454908,18 L2,21.4120258 C3.16851773,23.3387208 4.58684872,24.7562844 6.53916611,25.9725909 C8.6931366,27.3080743 11.0527031,27.9620873 13.3942448,27.9983077 C17.8970795,28.0701645 22.3140146,25.8513692 25,21.7733542 L19.1985306,18.1761366 C17.8852507,19.9354578 15.8183065,21.0533265 13.5080267,21.0185665 Z"
                fill="currentColor"
              ></path>
              <path
                d="M6.65775599,13.8583329 C6.71117139,10.2016888 9.43674775,7.22970276 12.8987333,6.85970218 L13,0 C10.833616,0.0870086155 8.9637989,0.638443546 6.99660992,1.75700349 C2.58622286,4.27711531 0.075142708,8.93107779 0.00141832947,13.7621955 C-0.0280714219,15.8649513 0.401755615,17.9950934 1.341811,20 L7.1718792,16.6682833 C6.82773936,15.8030468 6.64273291,14.852228 6.65775599,13.8583329 Z"
                className="secondary-svg-color"
              ></path>
              <path
                d="M24.05876,4.13126059 C21.7537908,1.79509104 18.6167171,0.261848357 15.1040843,0 L15,6.73824975 C18.5348755,7.20980107 21.2322284,10.2112449 21.1771921,13.8036698 C21.1620784,14.7812557 20.9442142,15.7064158 20.5655183,16.5463491 L26.4415787,20 C27.4781447,18.0672563 27.9600695,16.1653514 27.9982813,13.8992696 C28.0581654,10.1058327 26.5493702,6.65666744 24.05876,4.13126059 Z"
                fill="currentColor"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SVGIcon>
  );
};

export default DataHubIcon;
