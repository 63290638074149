import { STATE } from "src/common/constants";
export const STATE_FETCH_DATA = `${STATE}_GET_DATA`;
export const STATE_FETCH_INVOKED = `${STATE}_GET_INVOKED`;
export const STATE_FETCH_SUCCESS = `${STATE}_GET_SUCCESS`;
export const STATE_FETCH_FAILED = `${STATE}_GET_FAILED`;

export const fetchState = () => ({ type: STATE_FETCH_DATA });
export const fetchStateInvoked = () => ({ type: STATE_FETCH_INVOKED });
export const fetchStateSuccess = (payload) => ({
  type: STATE_FETCH_SUCCESS,
  payload,
});
export const fetchStateFailed = (error) => ({
  type: STATE_FETCH_FAILED,
  error,
});
