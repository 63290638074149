import { NEWS } from "src/common/constants";
export const NEWS_FETCH_DATA = `${NEWS}_GET_DATA`;
export const NEWS_FETCH_INVOKED = `${NEWS}_GET_INVOKED`;
export const NEWS_FETCH_SUCCESS = `${NEWS}_GET_SUCCESS`;
export const NEWS_FETCH_FAILED = `${NEWS}_GET_FAILED`;

export const fetchNews = () => ({ type: NEWS_FETCH_DATA });
export const fetchNewsInvoked = () => ({ type: NEWS_FETCH_INVOKED });
export const fetchNewsSuccess = (payload) => ({
  type: NEWS_FETCH_SUCCESS,
  payload,
});
export const fetchNewsFailed = (error) => ({ type: NEWS_FETCH_FAILED, error });
