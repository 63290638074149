import SVGIcon from "src/components/SVGIcon";

const SadCloud = ({ title = "Sad Cloud", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 132.1 131.1" title={title} {...props}>
      <g>
        <g>
          <path
            className="ctm-primary-color"
            d="M71.3,16l-3.1,5.8l-5.7,3.1l5.7,3.1l3.1,5.8l3.1-5.8l5.7-3.1l-5.7-3.1L71.3,16z M90.8,12.5L89.4,15l-2.5,1.4
			l2.5,1.4l1.4,2.6l1.4-2.6l2.5-1.4L92.2,15L90.8,12.5z M126.5,59l-2.2-4.1l-2.2,4.1l-4,2.2l4,2.2l2.2,4.1l2.2-4.1l4-2.2L126.5,59z
			 M6.3,66.9L4.1,71l-4,2.2l4,2.2l2.2,4.1l2.2-4.1l4-2.2l-4-2.2L6.3,66.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="ctm-default-text-color"
            d="M73.5,96.4c-6.3,0-14,1.8-18.5,6.4c-1.9,1.9,1.1,4.7,2.9,2.8c3.7-3.8,10.5-5.2,15.6-5.2
			c5.2,0,11.9,1.4,15.7,5.2c1.8,1.9,4.7-0.9,2.9-2.8C87.5,98.1,79.8,96.4,73.5,96.4z M85.9,90.8c2,0,3.8-1.8,3.8-3.8
			c0-2.1-1.8-3.9-3.8-3.9c-2.1,0-3.9,1.8-3.9,3.9C81.9,89,83.7,90.8,85.9,90.8z M61.2,90.8c2.1,0,3.9-1.8,3.9-3.8
			c0-2.1-1.8-3.9-3.9-3.9c-2.1,0-3.8,1.8-3.8,3.9C57.4,89,59,90.8,61.2,90.8z M108.4,85c-2.4-5.2-7.3-8.9-12.9-9.9
			c-5-20.1-34.4-22.6-42.6-3.4c-7.8,0.9-14.2,6.6-15.9,14.2c-14.9,8.5-9.4,32.7,9.1,32.7h54.7C120.2,118.5,125,92.4,108.4,85z
			 M100.9,113.4H46.1c-14,0-17.7-19.3-4.4-24.2c0.4-7.1,6.2-12.8,13.5-12.8h1.3c4.9-19.1,33.4-16.6,34.6,3.2h1
			c5.8,0,10.9,3.9,12.5,9.2C118.4,93.2,115.3,113.4,100.9,113.4z"
          />
        </g>
      </g>
    </SVGIcon>
  );
};

export default SadCloud;
