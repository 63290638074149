import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const ErrorPage = React.lazy(() => import("./ErrorPage"));

function ErrorPageWrapper(props) {
  usePageInit("Smart Portal - Error");
  return (
    <Suspense
      fallback={<Loading addContainer active loadingText="Loading..." />}
    >
      <ErrorPage {...props} />
    </Suspense>
  );
}

export default ErrorPageWrapper;
