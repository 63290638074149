import { getConfig } from "src/apiConfig";
import { get, put, post } from "src/common/apiClient";
import { IMPERSONATION, IMPERSONATION_ID, handleErrors } from "src/common";
const url = getConfig(IMPERSONATION).url;

export const impersonateUser = (email) => {
  if (url) {
    return post({ url: `${url}/User/${email}` })
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        return {
          token: res.auth0TokenResponse.access_token,
          id: res.impersonationHeaderId,
        };
      });
  } else {
    return Promise.reject(new Error(`Missing ${IMPERSONATION} url`));
  }
};

export const isImpersonated = () => {
  if (url) {
    return get({ url: `${url}/IsSessionActive` })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${IMPERSONATION} url`));
  }
};

export const logoutImpersonation = () => {
  const id = sessionStorage.getItem(IMPERSONATION_ID);
  if (url) {
    return put({ url: `${url}/CloseSession/${id}`, keepAlive: true });
  } else {
    return Promise.reject(new Error(`Missing ${IMPERSONATION} url`));
  }
};
