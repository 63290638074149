import {
  CURRENCIES_FETCH_SUCCESS,
  CURRENCIES_FETCH_INVOKED,
  CURRENCIES_FETCH_FAILED,
  CURRENCIES_TIME_SERIES_FETCH_SUCCESS,
  CURRENCIES_TIME_SERIES_FETCH_INVOKED,
  CURRENCIES_TIME_SERIES_FETCH_FAILED,
  CURRENCIES_SET_SELECTIONS,
} from "src/redux/actions/currencies";

export const initialState = {
  loading: false,
  loadingTimeSeries: false,
  currencies: [],
  timeSeries: [],
  selections: { firstSelection: "USD", secondSelection: "EUR" },
};

export type Currency = {
  key: string;
  text: string;
  value: string;
};

export type TimeSeries = {
  date: Date;
  value: number;
};

export interface CurrenciesI {
  loading: boolean;
  loadingTimeSeries: boolean;
  error?: Error;
  currencies: Currency[];
  timeSeries: TimeSeries[];
  selections: { firstSelection: string; secondSelection: string };
}

const currencies = (state: CurrenciesI = initialState, action): CurrenciesI => {
  switch (action.type) {
    case CURRENCIES_FETCH_INVOKED:
      return {
        ...state,
        currencies: [],
        loading: true,
      };
    case CURRENCIES_FETCH_SUCCESS: {
      const mappedCurrencies = Object.keys(action.payload).map((currency) => {
        return {
          key: currency,
          text: `${currency}: ${action.payload[currency]}`,
          value: currency,
        };
      });
      return {
        ...state,
        loading: false,
        currencies: mappedCurrencies,
      };
    }
    case CURRENCIES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CURRENCIES_TIME_SERIES_FETCH_INVOKED:
      return {
        ...state,
        timeSeries: [],
        loadingTimeSeries: true,
      };
    case CURRENCIES_TIME_SERIES_FETCH_SUCCESS: {
      const timeSeries = Object.keys(action.payload.rates).map((item) => {
        return {
          date: new Date(item),
          value: Object.values(action.payload.rates[item])[0] as number,
        };
      });
      return {
        ...state,
        loadingTimeSeries: false,
        timeSeries,
      };
    }
    case CURRENCIES_TIME_SERIES_FETCH_FAILED:
      return {
        ...state,
        loadingTimeSeries: false,
        error: action.error,
      };
    case CURRENCIES_SET_SELECTIONS:
      return {
        ...state,
        selections: action.selections,
      };
    default:
      return state;
  }
};

export default currencies;
