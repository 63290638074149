import { APPLICATION } from "src/common/constants";

export const FETCH_APPLICATION_DEPENDENCIES = `${APPLICATION}_FETCH_APPLICATION_DEPENDENCIES`;
export const REDIRECT_TO_LOGOUT = "REDIRECT_TO_LOGOUT";
export const SET_COLORS = `${APPLICATION}_SET_COLORS`;
export const SET_CAROUSELS = `${APPLICATION}_SET_CAROUSELS`;
export const SET_HEADER_STATE = `${APPLICATION}_SET_HEADER_STATE`;
export const SET_ASIDE_MENU = `${APPLICATION}_SET_ASIDE_MENU`;
export const SET_ASIDE_MENU_PREF = `${APPLICATION}_SET_ASIDE_MENU_PREF`;
export const SET_IS_GLOBAL_ERROR_MAP = `${APPLICATION}_SET_IS_GLOBAL_ERROR_MAP`;
export const SET_PAGE_ERROR = "SET_PAGE_ERROR";
export const SET_SMART_PORTAL_STATE = `${APPLICATION}_SET_SMART_PORTAL_STATE`;
export const TOGGLE_LIGHT_MODE = `${APPLICATION}_TOGGLE_LIGHT_MODE`;
export const TOGGLE_WIDGET_EDIT = `${APPLICATION}_TOGGLE_WIDGET_EDIT`;
export const LOCATION_CHANGE = `${APPLICATION}_LOCATION_CHANGE`;

export const fetchApplicationAndDependencies = () => ({
  type: FETCH_APPLICATION_DEPENDENCIES,
});
export const setPageError = () => ({ type: SET_PAGE_ERROR });
export const redirectToLogout = () => ({ type: REDIRECT_TO_LOGOUT });
export const setHeaderState = (state) => ({ type: SET_HEADER_STATE, state });
export const setAsideMenuCondensed = (payload) => ({
  type: SET_ASIDE_MENU,
  payload,
});
export const setAsideMenuCondensedPref = (payload) => ({
  type: SET_ASIDE_MENU_PREF,
  payload,
});

export const setColorSettings = (payload) => ({
  type: SET_COLORS,
  payload,
});

export const setCarousels = (payload) => ({
  type: SET_CAROUSELS,
  payload,
});

export const setIsGlobalRiskMap = () => ({ type: SET_IS_GLOBAL_ERROR_MAP });
export const setSmartPortalState = (state) => ({
  type: SET_SMART_PORTAL_STATE,
  state,
});
export const toggleLightMode = (payload) => ({
  type: TOGGLE_LIGHT_MODE,
  payload,
});
export const toggleWidgetEdit = () => ({ type: TOGGLE_WIDGET_EDIT });

export const routeChanged = (payload) => ({
  type: LOCATION_CHANGE,
  payload,
});
