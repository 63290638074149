import SVGIcon from "src/components/SVGIcon";

const LightningIcon = ({ title = "Lightning", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 43 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M19.458 0l-1.623 11.493 6.58-1.577-9.104 22.084 1.532-16.676-5.183 1.352 2.028-15.099 5.769-1.577zM25.858 9.961l-6.31 15.369 9.735-1.082-3.831-5.904 5.544-0.361-5.138-8.023z"
      ></path>
    </SVGIcon>
  );
};

export default LightningIcon;
