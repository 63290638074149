import { AIRLINES } from "src/common/constants";
export const AIRLINES_FETCH_DATA = `${AIRLINES}_GET_DATA`;
export const AIRLINES_FETCH_INVOKED = `${AIRLINES}_GET_INVOKED`;
export const AIRLINES_FETCH_SUCCESS = `${AIRLINES}_GET_SUCCESS`;
export const AIRLINES_FETCH_FAILED = `${AIRLINES}_GET_FAILED`;

export const fetchAirlines = () => ({
  type: AIRLINES_FETCH_DATA,
});
export const fetchAirlinesSuccess = (payload) => {
  return {
    type: AIRLINES_FETCH_SUCCESS,
    payload,
  };
};
export const fetchAirlinesFailed = ({ error }) => ({
  type: AIRLINES_FETCH_FAILED,
  error,
});
