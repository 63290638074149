import { CURRENCIES, CURRENCIES_TIME_SERIES } from "src/common/constants";

export const CURRENCIES_FETCH_DATA = `${CURRENCIES}_GET_DATA`;
export const CURRENCIES_FETCH_INVOKED = `${CURRENCIES}_GET_INVOKED`;
export const CURRENCIES_FETCH_SUCCESS = `${CURRENCIES}_GET_SUCCESS`;
export const CURRENCIES_FETCH_FAILED = `${CURRENCIES}_GET_FAILED`;

export const fetchCurrencies = () => ({ type: CURRENCIES_FETCH_DATA });
export const fetchCurrenciesInvoked = () => ({
  type: CURRENCIES_FETCH_INVOKED,
});
export const fetchCurrenciesSuccess = (payload) => ({
  type: CURRENCIES_FETCH_SUCCESS,
  payload,
});
export const fetchCurrenciesFailed = (error) => ({
  type: CURRENCIES_FETCH_FAILED,
  error,
});

export const CURRENCIES_TIME_SERIES_FETCH_DATA = `${CURRENCIES_TIME_SERIES}_GET_DATA`;
export const CURRENCIES_TIME_SERIES_FETCH_INVOKED = `${CURRENCIES_TIME_SERIES}_GET_INVOKED`;
export const CURRENCIES_TIME_SERIES_FETCH_SUCCESS = `${CURRENCIES_TIME_SERIES}_GET_SUCCESS`;
export const CURRENCIES_TIME_SERIES_FETCH_FAILED = `${CURRENCIES_TIME_SERIES}_GET_FAILED`;

export const fetchCurrenciesTimeSeries = (selections) => ({
  type: CURRENCIES_TIME_SERIES_FETCH_DATA,
  selections,
});
export const fetchCurrenciesTimeSeriesInvoked = () => ({
  type: CURRENCIES_TIME_SERIES_FETCH_INVOKED,
});
export const fetchCurrenciesTimeSeriesSuccess = (payload) => ({
  type: CURRENCIES_TIME_SERIES_FETCH_SUCCESS,
  payload,
});
export const fetchCurrenciesTimeSeriesFailed = (error) => ({
  type: CURRENCIES_TIME_SERIES_FETCH_FAILED,
  error,
});

export const CURRENCIES_SET_SELECTIONS = `${CURRENCIES}_SET_SELECTIONS`;

export const setSelections = (selections) => ({
  type: CURRENCIES_SET_SELECTIONS,
  selections,
});
