import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { handleErrors } from "src/common";
import { IMPERSONATION_ID, SSO } from "src/common/constants";

const url = getConfig(SSO).url;

// Gets an SSO link from the api and then redirects to it
export const ssoRedirect = (item, isImpersonating) => {
  if (url && item?.id) {
    const finalURL = `${url}/${item.id}`;
    return get({
      url: finalURL,
      impersonationHeaders: isImpersonating
        ? {
            impersonationheaderid: sessionStorage.getItem(IMPERSONATION_ID),
            impersonationaction: "SSO Out",
            impersonationdetail: `Name: ${item.name}, Url: ${item.href}`,
          }
        : undefined,
    })
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => console.error("Error in SSO Redirect", er));
  } else {
    return Promise.reject(new Error(`Missing id or configured url`));
  }
};

export const handleSSORedirect = (item, history, isImpersonating) => {
  // We need to keep a reference to the current window object we are going to want to use
  // which will be dependent on whether or not the new link is being opened in a new tab.
  let newWindow;
  if (item.openInNewWindow) {
    const redirectUrl = `/redirect?label=${item.label}`;
    // If we're opening in a new tab, we keep a reference to it and focus on it
    newWindow = window.open(redirectUrl, "_blank");
    newWindow.focus();
  } else {
    const redirectUrl = `/redirect?label=${item.label}&redirect=true`;
    // Otherwise we reference the current window and push our route to the redirect page
    history.push(redirectUrl);
    newWindow = window;
  }

  // We request the url we are redirecting to
  ssoRedirect(item, isImpersonating)
    .then((res) => {
      // If the server lacks a response, or still responds with a 302 redirect, we want to show an error
      if (!res) throw new Error("No response");
      // If not, we refer to our window object, which is either the current window or the new one,
      // and we set the location of the window to the url we got from the server.
      // This allows us to bypass any popup warnings.
      newWindow.location.href = res;
    })
    .catch(() => {
      // If we get an error, we want to show an error page to the user.
      // Which will either be in the new tab, or just a new route in our react app,
      // depending on whether or not we were opening in a new tab.
      const errorURL = `/sso-error?label=${item.label}`;
      if (item.openInNewWindow) {
        newWindow.location.href = errorURL;
      } else {
        history.push(errorURL);
      }
    });
};
