import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";
import { DebouncedFunction, debounce } from "src/common/helpers";

export const debouncedSearch: DebouncedFunction = debounce(
  (value: string, callback: (val: string) => void) => {
    callback(value);
  },
  250
);

interface ThrottledInputI {
  ariaLabel?: string;
  query: string;
  onChange: (val: string) => void;
  placeholder?: string;
  dataAut?: string;
  className?: string;
}

const ThrottledInput = ({
  ariaLabel = "query",
  query,
  onChange,
  placeholder,
  dataAut,
  ...props
}: ThrottledInputI) => {
  const [queryState, setQuery] = useState(query);
  useEffect(() => {
    setQuery(query);
  }, [query]);

  const handleChange = (e) => {
    const query = e.target.value;
    // Sanitize input so that the first character can't be a blank space
    if (query.charCodeAt(0) !== 32) {
      setQuery(query);
      debouncedSearch(query, onChange);
    }
  };
  return (
    <Input
      aria-label={ariaLabel}
      name="query"
      onChange={handleChange}
      value={queryState}
      icon="search"
      iconPosition="left"
      placeholder={placeholder || ""}
      fluid
      data-aut={dataAut || undefined}
      autoCorrect="off"
      spellCheck="false"
      autoComplete="off"
      {...props}
    />
  );
};

ThrottledInput.propTypes = {
  /* Callback for parent component to handle debounced input*/
  onChange: PropTypes.func.isRequired,
  /* Callback for parent component to handle debounced input*/
  placeholder: PropTypes.string,
};

export default ThrottledInput;
