import { chooseRandom } from "src/common";

export const multiplyAndRound = (number, multiplier) => {
  return (Math.round(Math.floor(number * multiplier)) * 100) / 100;
};

export const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const getPNR = () => {
  let result = "";
  while (result.length < 6) {
    result += chooseRandom(letters);
  }
  return result;
};

const airportCodes = [
  "LAX",
  "JFK",
  "LHR",
  "CDG",
  "YYZ",
  "SYD",
  "HKG",
  "MCW",
  "MDZ",
  "AUH",
  "ACA",
  "ABQ",
  "AUA",
  "ATH",
  "BKK",
  "BWI",
  "PEK",
  "BZE",
  "BGO",
  "BMG",
  "BOB",
  "BOS",
  "BNE",
  "BUD",
  "CNS",
  "YYC",
  "CCU",
  "CAI",
  "CTS",
  "CKG",
  "ORK",
  "CFU",
  "HAG",
  "DKI",
  "DUD",
  "EDI",
  "YEG",
  "ELY",
  "FKS",
  "GLA",
];

export const getAirport = (airport) => {
  let result = chooseRandom(airportCodes);
  while (result === airport) {
    result = chooseRandom(airportCodes);
  }
  return result;
};
