import { post } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { UNUSED_TICKETS } from "src/common/constants";
import { handleErrors } from "src/common";

export const fetchData = ({ airlines, id }) => {
  const url = getConfig(UNUSED_TICKETS).url;
  if (url) {
    const params = { includeCount: true, MaxResults: 1000 };
    if (airlines && airlines.length) params.AirlineName = airlines;
    return post({ url: `${url}/${id}`, params })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${UNUSED_TICKETS} url`));
  }
};

export const fetchCSV = (id, airlines) => {
  const url = getConfig(UNUSED_TICKETS).url;
  const params = {};
  if (airlines && airlines.length) params.AirlineName = airlines;
  if (url) {
    return post({ url: `${url}/${id}/csv`, params })
      .then(handleErrors)
      .then((res) => res.text());
  } else {
    return Promise.reject(new Error(`Missing ${UNUSED_TICKETS} url`));
  }
};
