import { connect } from "react-redux";
import {
  ApproveTwoFactor,
  GlobalRiskMap,
  SmartApp,
  AuthCallback,
  ErrorPage,
  Impersonating,
  InvalidKey,
  Login,
  Logout,
  Redirecting,
  SelfRegistration,
  SessionTimeout,
} from "src/pages";
import ProtectedRoute from "src/compositions/ProtectedRoute";
import { Router, Route, Switch } from "react-router";
import { useCheckImpersonation } from "./common/hooks/impersonation";

function MainRoutes({ history, user, isImpersonating }) {
  const impersonated = useCheckImpersonation(isImpersonating, user.loading);

  if (impersonated?.data) {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" component={Impersonating} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/logout" render={() => <Logout user={user.data} />} />
        <Route path="/invalid-key" render={() => <InvalidKey />} />
        <Route
          path="/callback"
          render={(props) => {
            return <AuthCallback {...props} />;
          }}
        />
        <Route
          path="/ctmapprovetwofactor"
          render={(props) => {
            return <ApproveTwoFactor {...props} />;
          }}
        />
        <Route
          path="/ctmglobalriskmap"
          render={(props) => {
            return <GlobalRiskMap {...props} />;
          }}
        />
        <Route
          path="/register"
          render={(props) => {
            return <SelfRegistration {...props} />;
          }}
        />
        <Route
          path="/redirect"
          render={(props) => {
            return <Redirecting {...props} />;
          }}
        />

        <Route
          path="/sessiontimeout"
          render={(props) => {
            return <SessionTimeout {...props} />;
          }}
        />
        <Route
          path="/sso-error"
          render={(props) => {
            return <ErrorPage {...props} />;
          }}
        />
        <ProtectedRoute path="/" component={SmartApp} userToken={user.data} />
      </Switch>
    </Router>
  );
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    isImpersonating: state.impersonation.isImpersonating,
  };
};

export default connect(mapStateToProps, null)(MainRoutes);
