import {
  COUNTRIES_FETCH_SUCCESS,
  COUNTRIES_FETCH_INVOKED,
  COUNTRIES_FETCH_FAILED,
} from "src/redux/actions/countries";

const mappedCountries = {
  "United States": "United States of America",
  "Czech Republic": "Czechia",
  Serbia: "Republic of Serbia",
  "Cote d'Ivoire": "Ivory Coast",
  Bahamas: "The Bahamas",
  "South Georgia and the South Sandwich Islands":
    "South Georgia and the Islands",
  "Cape Verde": "Cabo Verde",
  Micronesia: "Federated States of Micronesia",
};

const additionalData = {
  Somalia: ["Somaliland"],
  "Palestinian Territory": ["Palestine"],
  Kazakhstan: ["Baykonur Cosmodrome"],
  Cyprus: [
    "Akrotiri Sovereign Base Area",
    "Northern Cyprus",
    "Cyprus No Mans Area",
    "Dhekelia Sovereign Base Area",
  ],
  "French Southern Territories": ["French Southern and Antarctic Lands"],
  Australia: ["Heard Island and McDonald Islands"],
  India: ["Siachen Glacier"],
};

interface CountryI {
  id: string;
  text: string;
  value: string;
  centroid: {
    latitude: number;
    longitude: number;
  };
}

interface RiskGroupsI {
  0: string[];
  1: string[];
  2: string[];
  3: string[];
  4: string[];
  5: string[];
}

export interface CountriesI {
  error?: Error;
  loading: boolean;
  countries: CountryI[];
  riskGroups?: RiskGroupsI;
}

const initialState: CountriesI = {
  error: null,
  loading: false,
  countries: [],
  riskGroups: null,
};

const countries = (state: CountriesI = initialState, action): CountriesI => {
  switch (action.type) {
    case COUNTRIES_FETCH_INVOKED:
      return {
        ...state,
        loading: true,
      };
    case COUNTRIES_FETCH_SUCCESS: {
      const riskGroups = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      };

      const newCountries = [];

      action.payload.forEach((c) => {
        const country = mappedCountries[c.country] || c.country;
        const additionalCountries = additionalData[country];
        newCountries.push({
          id: c.countryCode,
          value: c.countryCode,
          text: c.country,
          centroid: c.centroid,
        });

        if (!c.riskLevel) {
          riskGroups[0].push(country);

          if (additionalCountries)
            riskGroups[0] = riskGroups[0].concat(additionalCountries);
        } else {
          riskGroups[c.riskLevel].push(country);

          if (additionalCountries)
            riskGroups[c.riskLevel] =
              riskGroups[c.riskLevel].concat(additionalCountries);
        }
      });

      return {
        ...state,
        loading: false,
        riskGroups,
        countries: newCountries,
      };
    }
    case COUNTRIES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default countries;
