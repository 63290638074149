import { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toggleLightMode } from "src/redux/actions";
import { setLightModePrefLocalStorage } from "src/services/application/";
import { Image, Divider, Popup, Icon, Radio } from "semantic-ui-react";
import { debounce, setGoogleAnalyticsEvent } from "src/common/helpers";
import { redirectToLogout } from "src/redux/actions/application";
import { useHistory } from "react-router";
import ImpersonationForm from "./ImpersonationForm";
import { handleSSORedirect } from "src/services/ssoRedirect";
import { useModalFocusTrapAutomated } from "src/common/hooks";

/** User Profile for header with dropdown menu */

const renderAvatar = (user) => {
  if (
    user.givenName &&
    user.givenName.length &&
    user.familyName &&
    user.familyName.length
  ) {
    return (
      <span className="avatar">
        <span>{`${user.givenName[0]}${user.familyName[0]}`}</span>
      </span>
    );
  }

  if (user.picture) {
    return (
      <Image
        src={user.picture}
        avatar
        className="avatar"
        style={{ height: "40px", width: "40px" }}
      />
    );
  }
};
const Profile = ({
  toggleLightMode,
  user,
  globalSsoLinks,
  lightMode,
  initializeImpersonation,
  clearImpersonation,
  isImpersonating,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openRef = useRef(false);
  const history = useHistory();
  const triggerRef = useRef(null);

  const handleToggle = (e, val) => {
    if (e) e.nativeEvent.stopImmediatePropagation();
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_theme_switch",
      category: val ? "breezy" : "galaxy",
    });
    toggleLightMode(val);
    setLightModePrefLocalStorage(val);
  };
  const handleOpen = (e) => {
    if (e) {
      e.nativeEvent.stopImmediatePropagation();
    }
    const newState = !isOpen;
    setIsOpen(newState);
    openRef.current = newState;
  };
  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
      openRef.current = false;
      triggerRef?.current?.focus();
    }
  };
  const debouncedClose = debounce(closeMenu, 50);

  const renderTrigger = () => {
    const currUser = user || {};
    if (!currUser.givenName) {
      return (
        <button
          className="unstyled-button"
          data-aut="UserProfile"
          ref={triggerRef}
        >
          <Icon name="caret down" onClick={handleOpen} />
        </button>
      );
    }
    const name = currUser.familyName
      ? `${currUser.givenName} ${currUser.familyName}`
      : currUser.givenName;

    return (
      <button
        className="unstyled-button"
        id="user-profile"
        onClick={handleOpen}
        data-aut="UserProfile"
        ref={triggerRef}
      >
        {renderAvatar(currUser)}
        <span className="greeting">{name}</span> <Icon name="caret down" />
      </button>
    );
  };

  const renderGlobalSsoLinks = () => {
    return globalSsoLinks.map((link) => {
      const url = link.isDirectLink ? link.href : undefined;
      return (
        <Fragment key={link.id}>
          <a
            href={url}
            target={link.openInNewWindow ? "_blank" : undefined}
            rel="noreferrer"
            data-aut={`Profile|SSOLink|${link.label}`}
            tabIndex={0}
            onClick={() => {
              if (!link.isDirectLink)
                handleSSORedirect(link, history, isImpersonating);
              setGoogleAnalyticsEvent({
                action: "portal_dashboard_global_sso",
                category: link.identifier,
              });
            }}
          >
            {link.label}
          </a>
          <Divider />
        </Fragment>
      );
    });
  };

  function PopupContent() {
    const { containerProps } = useModalFocusTrapAutomated();

    useEffect(() => {
      document.addEventListener("click", closeMenu);
      document.body.addEventListener("scroll", debouncedClose);
      window.addEventListener("keydown", checkEsc, true);
      return () => {
        document.removeEventListener("click", closeMenu);
        document.body.removeEventListener("scroll", debouncedClose);
        window.removeEventListener("keydown", checkEsc);
      };
    }, []);

    const checkEsc = ({ key }) => {
      if (openRef.current && key === "Escape") {
        closeMenu();
      }
    };
    return (
      <Popup.Content
        className="profile-menu"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Semantic components are a pain to pass refs to so using the automated approach here */}
        <div {...containerProps}>
          <h5 aria-level={2}>Select Screen Theme</h5>
          <Radio
            label="Breezy"
            checked={lightMode}
            className="lightmode-radio"
            onChange={(e) => handleToggle(e, true)}
            data-aut="Profile|LightMode|Breezy"
            name="Screen Setting"
          />

          <Radio
            label="Galaxy"
            checked={!lightMode}
            className="lightmode-radio"
            onChange={(e) => handleToggle(e, false)}
            data-aut="Profile|LightMode|Galaxy"
            name="Screen Setting"
          />

          <Divider />
          {Boolean(user.canImpersonate || isImpersonating) && (
            <ImpersonationForm
              initializeImpersonation={initializeImpersonation}
              clearImpersonation={clearImpersonation}
              isImpersonating={isImpersonating}
            />
          )}
          {globalSsoLinks.length ? renderGlobalSsoLinks() : null}
          <button
            data-aut="Profile|Logout"
            onClick={() => history.push("/logout")}
            className="unstyled-button"
          >
            Log Out
          </button>
        </div>
      </Popup.Content>
    );
  }

  return (
    <Popup trigger={renderTrigger()} open={isOpen} offset={[9, 0]}>
      {isOpen ? <PopupContent /> : <div />}
    </Popup>
  );
};

const mapStateToProps = (state) => {
  return {
    lightMode: state.application.lightMode,
    user: state.user.data,
    loading: state.user.loading,
    globalSsoLinks: state.globalSsoLinks,
    isImpersonating: state.impersonation.isImpersonating,
  };
};

const mapDispatchToProp = (dispatch) => ({
  redirectToLogout: () => dispatch(redirectToLogout()),
  toggleLightMode: (bool) => dispatch(toggleLightMode(bool)),
});

export default connect(mapStateToProps, mapDispatchToProp)(Profile);
