import {
  SET_WIDGETS,
  UPDATE_WIDGET_ORDER,
  UPDATE_WIDGET_PROPS,
} from "src/redux/actions/widgets";
import update from "immutability-helper";
import { arrayMove } from "@dnd-kit/sortable";
import { setWidgetDefaults } from "src/common/shared/featureSettings";
import { WidgetI } from "src/types/Widget";

const widgets = (state: WidgetI[] = [], action): WidgetI[] => {
  switch (action.type) {
    case SET_WIDGETS: {
      const { widgets } = action;
      // Widgets don't come configured with default props from the server, so we need to make sure we map
      // default properties to any widgets that haven't had Widget properties configured by the user
      // Also sort the widgets based on sort order.
      return setWidgetDefaults(widgets).sort((a, b) => a.order - b.order);
    }
    case UPDATE_WIDGET_ORDER: {
      const { oldIndex, newIndex } = action;
      return arrayMove(state, oldIndex, newIndex);
    }
    case UPDATE_WIDGET_PROPS: {
      const { index, props } = action;

      return update(state, {
        [index]: {
          properties: {
            $set: props,
          },
        },
      }) as WidgetI[];
    }
    default:
      return state;
  }
};

export default widgets;
