import {
  IMPERSONATION_RESET,
  IMPERSONATION_SET_IMPERSONATOR,
  IMPERSONATION_SET_TIMED_OUT,
  IMPERSONATION_SET_TIMING_OUT,
} from "src/redux/actions";

export interface ImpersonationStateI {
  isImpersonating: boolean;
  isTimingOut: boolean;
  isTimedOut: boolean;
}

const initialState = {
  isImpersonating: false,
  isTimingOut: false,
  isTimedOut: false,
};

const impersonation = (
  state: ImpersonationStateI = initialState,
  action
): ImpersonationStateI => {
  switch (action.type) {
    case IMPERSONATION_SET_IMPERSONATOR:
      return {
        ...state,
        isImpersonating: action.payload,
      };
    case IMPERSONATION_SET_TIMING_OUT:
      return {
        ...state,
        isTimingOut: action.payload,
      };
    case IMPERSONATION_SET_TIMED_OUT:
      return {
        ...state,
        isTimedOut: action.payload,
      };
    case IMPERSONATION_RESET:
      return initialState;
    default:
      return state;
  }
};

export default impersonation;
