import { useCallback, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { minutesToMilliseconds, setGoogleAnalyticsEvent } from "../helpers";
import { logoutUser } from "src/services/logout";
import { SSO_REDIRECT_URL } from "../constants";

const SESSION_TIMEOUT = minutesToMilliseconds(
  Number(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES || 30)
);

export const useSessionTimeout = (user) => {
  const sessionTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { logout, user: authUser } = useAuth0();
  const handleLogout = () => {
    const redirectUrl = authUser["https://www.ctmsmart.com/logoutUrl"];
    if (redirectUrl) sessionStorage.setItem(SSO_REDIRECT_URL, redirectUrl);
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_session_timeout",
    });
    logoutUser({
      logout,
      authUser,
      user,
      redirect: process.env.REACT_APP_AUTH0_SESSION_TIMEOUT_REDIRECT,
    });
  };

  const resetTimer = useCallback(() => {
    clearTimeout(sessionTimeout.current);
    sessionTimeout.current = setTimeout(handleLogout, SESSION_TIMEOUT);
  }, []);

  const initializeSessionTimeout = () => {
    sessionTimeout.current = setTimeout(handleLogout, SESSION_TIMEOUT);
    window.addEventListener("click", resetTimer);
  };

  return { initializeSessionTimeout };
};
