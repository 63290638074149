import { FAREFORECASTER_UPDATE_FORM } from "src/redux/actions/fareForecaster";
import { FareForecasterFormDataI } from "src/types/FareForecaster";

const initialState: FareForecasterFormDataI = {
  preferredAirlines: [],
  numberOfStops: 0,
  isRoundTrip: false,
  outboundTimeSlotId: null,
};

const fareForecaster = (
  state: FareForecasterFormDataI = initialState,
  action
) => {
  switch (action.type) {
    case FAREFORECASTER_UPDATE_FORM: {
      const newState = { ...state };
      const newForm = { ...newState[action.id], ...action.payload };
      newState[action.id] = newForm;
      return newState;
    }
    default:
      return state;
  }
};

export default fareForecaster;
