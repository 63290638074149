import {
  FLIGHTDETAILS_FETCH_SUCCESS,
  FLIGHTDETAILS_FETCH_INVOKED,
  FLIGHTDETAILS_FETCH_FAILED,
} from "src/redux/actions/flightDetails";
import { FlightSegment } from "src/types/Flight";
import { Passenger } from "src/types/Passenger";

export interface UpcomingFlightI {
  id: string;
  passengers: Passenger[];
  flightInfo: FlightSegment;
}

export interface FlightDetailsI {
  [key: string]: {
    loading: boolean;
    error?: Error;
    data: UpcomingFlightI[];
  };
}

export const initialState: FlightDetailsI = {};

const flightDetails = (state = initialState, action) => {
  const result = { ...state };
  switch (action.type) {
    case FLIGHTDETAILS_FETCH_INVOKED:
      result[action.id] = {
        loading: true,
        data: [],
        error: null,
      };
      return result;
    case FLIGHTDETAILS_FETCH_SUCCESS:
      result[action.id] = {
        loading: false,
        data: action.payload,
      };
      return result;
    case FLIGHTDETAILS_FETCH_FAILED:
      result[action.id] = {
        data: [],
        loading: false,
        error: action.error,
      };
      return result;
    default:
      return state;
  }
};

export default flightDetails;
