import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { Loading } from "src/components";

const ApproveTwoFactor = React.lazy(() => import("./ApproveTwoFactor"));

function ApproveTwoFactorPage(props) {
  usePageInit("Smart Portal - Approve Two Factor");
  return (
    <Suspense
      fallback={
        <Loading addContainer active loadingText="Loading CTM Approve" />
      }
    >
      <ApproveTwoFactor {...props} />
    </Suspense>
  );
}

export default ApproveTwoFactorPage;
