import { get, post } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { CURRENCIES, CURRENCIES_TIME_SERIES } from "src/common/constants";
import { handleErrors } from "src/common";
import moment from "moment";

export const fetchCurrencies = () => {
  const url = getConfig(CURRENCIES).url;
  if (url) {
    return get({ url })
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => console.error("Error Fetching Currencies Symbols", er));
  } else {
    return Promise.reject(new Error(`Missing ${CURRENCIES} url`));
  }
};

export const fetchCurrenciesTimeSeries = ({
  firstSelection,
  secondSelection,
}) => {
  const url = getConfig(CURRENCIES_TIME_SERIES).url;
  if (url) {
    const params = {
      startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      baseCurrency: firstSelection,
      conversionCurrencies: secondSelection,
      prettyprint: 0,
    };
    return post({ url, params })
      .then(handleErrors)
      .then((res) => res.json())
      .catch((er) => console.error("Error Fetching Currencies TimeSeries", er));
  } else {
    return Promise.reject(new Error(`Missing ${CURRENCIES_TIME_SERIES} url`));
  }
};
