import airlines from "./airlines";
import agentChat from "./agentChat";
import application from "./application";
import approve from "./approve";
import bi from "./bi";
import contactInfo from "./contactInfo";
import countries from "./countries";
import currencies from "./currencies";
import globalSsoLinks from "./globalSsoLinks";
import fareForecaster from "./fareForecaster";
import flightDetails from "./flightDetails";
import impersonation from "./impersonation";
import itineraryDetails from "./itineraryDetails";
import news from "./news";
import links from "./links";
import obts from "./obts";
import ssoLinks from "./ssoLinks";
import tools from "./tools";
import travelerTracker from "./travelerTracker";
import unusedTickets from "./unusedTickets";
import user from "./user";
import widgets from "./widgets";

const reducers = {
  airlines,
  agentChat,
  application,
  approve,
  bi,
  contactInfo,
  countries,
  currencies,
  globalSsoLinks,
  fareForecaster,
  flightDetails,
  impersonation,
  itineraryDetails,
  news,
  links,
  obts,
  ssoLinks,
  tools,
  travelerTracker,
  unusedTickets,
  user,
  widgets,
};

export default reducers;
