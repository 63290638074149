import SVGIcon from "src/components/SVGIcon";

const SwiftDataIcon = ({ title = "Swift Data", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M32 17.457l-1.097-0.197 0.48 0.593-0.096 0.164-1.3-0.616v0.427c-1.893-0.107-3.527-0.842-5.11-1.733-0.185-0.103-0.433-0.103-0.653-0.144-0.28-0.051-0.562-0.093-0.876-0.144l-4.873 3.239-1.092-2.533c-0.193 1.429-0.41 2.914-0.588 4.404-0.097 0.812-0.126 1.631-0.192 2.446-0.020 0.253-0.020 0.516-0.116 0.738-0.084 0.198-0.351 0.511-0.429 0.482-0.302-0.11-0.805 0.119-0.863-0.491-0.084-0.896-0.252-1.783-0.352-2.678-0.172-1.569-0.316-3.142-0.489-4.893-0.578 0.883-0.505 1.893-1.175 2.398l-5.007-3.092c-1.554 0.676-3.229 1.434-4.936 2.113-0.434 0.172-0.974 0.076-1.501 0.105l0.211-0.505-1.275 0.713-0.101-0.101 0.704-0.843-1.239 0.567c-0.020-0.092-0.043-0.14-0.030-0.153 2.51-2.638 5.234-4.999 8.55-6.596 0.735-0.352 1.429-0.79 2.168-1.126 0.757-0.346 1.194-0.193 1.678 0.462 0.145 0.201 0.287 0.375 0.437 0.542l-0.005-0.006c0.323 0.352 0.741 0.409 1.051 0.077 0.333-0.356 0.75-0.809 0.774-1.239 0.059-1.031 0.543-1.767 1.226-2.421 0.675 0.687 1.161 1.474 1.243 2.476 0.018 0.217 0.153 0.435 0.27 0.631 0.562 0.931 1.108 0.968 1.773 0.126 0.817-1.029 1.010-1.136 2.165-0.593 3.077 1.446 6.074 3.029 8.542 5.444 0.666 0.653 1.414 1.223 2.124 1.83z"
      ></path>
    </SVGIcon>
  );
};

export default SwiftDataIcon;
