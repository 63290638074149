import SVGIcon from "src/components/SVGIcon";

const PaperAirplane = ({ title = "Paper Airplane", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 200.1 131.1" title={title} {...props}>
      <title>{title}</title>
      <g>
        <g>
          <path
            className="ctm-default-text-color"
            d="M86.5,77.5c-0.3-0.3-0.9-0.4-1.2,0l-26.7,25.1c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.5,0.3,0.9,0.8,0.9
			c0.3,0,0.5-0.1,0.7-0.3l26.7-25C86.8,78.4,86.8,77.9,86.5,77.5z M70.1,71.7c0.2,0,0.5-0.1,0.7-0.2l12.5-11.7
			c0.3-0.3,0.4-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0L69.6,70.2c-0.2,0.1-0.2,0.3-0.3,0.5C69.3,71.2,69.6,71.7,70.1,71.7z M106.5,77.8
			L88.9,94.3c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.5,0.3,0.9,0.8,0.9c0.3,0,0.5,0,0.7-0.2l17.5-16.4c0.3-0.3,0.4-0.9,0-1.2
			C107.4,77.5,106.8,77.5,106.5,77.8z M98,78c-0.3-0.3-0.9-0.4-1.2,0l-26.3,24.7c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.5,0.3,0.9,0.8,0.9
			c0.2,0,0.5-0.1,0.7-0.2l26.3-24.7C98.3,78.9,98.3,78.3,98,78z M85.3,68.2c0.3-0.3,0.4-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0L57.6,91.9
			c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.5,0.3,0.9,0.8,0.9c0.2,0,0.5-0.1,0.7-0.2L85.3,68.2z M137.5,27.3c0-0.1-0.1-0.2-0.1-0.3
			c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0h-0.1
			c0,0,0,0-0.1,0l-57.9,12c-0.4,0.1-0.7,0.4-0.8,0.8c-0.1,0.4,0.1,0.8,0.5,1l16.6,8.8l2.9,16.2v0c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1
			c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0
			c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0h0.1c0.3,0,0.5-0.1,0.7-0.3l11.1-7.4l11.6,7.5c0.2,0.1,0.3,0.2,0.5,0.2
			c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.5-0.3,0.6-0.6L137.3,28c0.1-0.1,0.1-0.3,0.1-0.5C137.5,27.4,137.5,27.4,137.5,27.3z M81.5,39.9
			l48-9.9L95.6,47.4L81.5,39.9z M98.8,61.1l-2.2-12.1l29.2-15L102,51.7c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0L98.8,61.1z M104.5,60.4l-4.1,2.7l2.7-9l4.2,2.8l1.2,0.8
			L104.5,60.4z M122,64l-15.4-10l-2.3-1.5l30.1-22.2L122,64z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="ctm-primary-color"
            d="M120.7,105.4h-0.2c-0.8-2.9-3.4-5-6.5-5c-1.4,0-2.6,0.4-3.7,1.1c-0.7-4.5-4.5-8-9.2-8c-4.2,0-7.7,2.8-8.9,6.7
			c-0.5-0.1-1.1-0.2-1.7-0.2c-3.2,0-5.8,2.4-6.3,5.5h-1c-2.6,0-4.8,2.2-4.8,4.9v0.4c0,2.7,2.1,4.9,4.8,4.9h37.5
			c2.6,0,4.8-2.2,4.8-4.9v-0.4C125.5,107.6,123.3,105.4,120.7,105.4z M45.3,25.8h-0.2c-0.7-2.5-3-4.3-5.7-4.3
			c-1.2,0-2.3,0.4-3.2,0.9c-0.6-3.9-4-6.9-8-6.9c-3.6,0-6.7,2.4-7.8,5.8c-0.5-0.1-0.9-0.2-1.4-0.2c-2.8,0-5.1,2.1-5.5,4.7h-0.9
			c-2.3,0-4.2,1.9-4.2,4.2v0.3c0,2.3,1.9,4.2,4.2,4.2h32.7c2.3,0,4.2-1.9,4.2-4.2V30C49.5,27.7,47.6,25.8,45.3,25.8z M181.2,34.3
			h-0.2c-0.7-2.6-3.1-4.5-5.8-4.5c-1.2,0-2.3,0.4-3.3,1c-0.6-4.1-4-7.3-8.2-7.3c-3.7,0-6.9,2.5-7.9,6.1c-0.5-0.1-1-0.2-1.5-0.2
			c-2.8,0-5.2,2.2-5.6,5h-0.9c-2.3,0-4.3,2-4.3,4.4v0.3c0,2.4,1.9,4.4,4.3,4.4h33.5c2.3,0,4.3-2,4.3-4.4v-0.3
			C185.5,36.3,183.6,34.3,181.2,34.3z"
          />
        </g>
      </g>
    </SVGIcon>
  );
};

export default PaperAirplane;
