import { GLOBAL_SSO_SET_DATA } from "src/redux/actions/globalSsoLinks";
import { SSOLinkI } from "src/types/SSOLink";

const globalSsoLinks = (state: SSOLinkI[] = [], action) => {
  switch (action.type) {
    case GLOBAL_SSO_SET_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default globalSsoLinks;
