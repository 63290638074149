import {
  fetchNewsFailed,
  fetchNewsSuccess,
  fetchNewsInvoked,
  NEWS_FETCH_DATA,
} from "src/redux/actions/news";
import { put, call, take, fork, select } from "redux-saga/effects";
import { fetchData } from "src/services/news";

export const getNewsState = (state) => state.news;

export function* fetchNews() {
  try {
    yield put(fetchNewsInvoked());
    const payload = yield call(fetchData);
    yield put(fetchNewsSuccess(payload));
  } catch (ex) {
    yield put(fetchNewsFailed(ex));
  }
}

function* newsSaga() {
  yield take(NEWS_FETCH_DATA);
  const news = yield select(getNewsState);
  if (news && news.payload && news.payload.length)
    yield put(fetchNewsSuccess(news.payload));
  else yield fork(fetchNews);
}

export default newsSaga;
