import SVGIcon from "src/components/SVGIcon";

const Planet = ({ title = "Planet", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 128.1 128.1" title={title} {...props}>
      <g>
        <g>
          <path
            className="ctm-default-text-color"
            d="M59.5,83.8c5.4,1,11.2,0.2,16.3-2.7c5.1-2.9,8.6-7.5,10.2-12.5c-3.9,2.9-8.2,5.7-12.8,8.3
			C68.6,79.5,64,81.8,59.5,83.8z M103.6,39.2c-1.5-2.4-4.5-3.7-8.9-3.7c-5,0-11.7,1.6-19.2,4.5c1.9,0.9,3.6,2,5.3,3.4
			c5.5-1.9,10.3-2.9,13.9-2.9c2.3,0,4,0.5,4.5,1.3c0.6,1,0.1,4.5-6,10.9C87.5,58.5,79,64.9,69.3,70.5c-13.8,8-27.9,13.1-35.9,13.1
			c-2.3,0-4-0.5-4.5-1.3c-0.6-1-0.1-4.5,6-10.9c1.3-1.3,2.7-2.7,4.2-4c-0.5-2-0.7-4-0.6-6.1c-2.8,2.3-5.3,4.5-7.4,6.7
			c-6.8,7.1-9,12.8-6.6,16.7c1.5,2.4,4.5,3.7,8.9,3.7c9,0,23.8-5.3,38.6-13.8C82.1,68.8,91,62.2,97,55.9
			C103.7,48.9,106,43.1,103.6,39.2z M47.8,77.9c5.7-2,12.5-5.1,20.1-9.5c7.7-4.5,13.7-8.8,18.2-12.6c-0.5-1.8-1.3-3.5-2.3-5.2
			c-6.6-10.5-20.7-13.9-31.6-7.6c-10.9,6.3-14.5,20-7.9,30.5C45.2,75.1,46.5,76.6,47.8,77.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="ctm-primary-color"
            d="M31.3,31l-3.1,5.8l-5.7,3.1l5.7,3.1l3.1,5.8l3.1-5.8l5.7-3.1l-5.7-3.1L31.3,31z M50.8,27.5L49.4,30l-2.5,1.4
			l2.5,1.4l1.4,2.6l1.4-2.6l2.5-1.4L52.2,30L50.8,27.5z M102.5,89l-2.2-4.1L98.1,89l-4,2.2l4,2.2l2.2,4.1l2.2-4.1l4-2.2L102.5,89z"
          />
        </g>
      </g>
    </SVGIcon>
  );
};

export default Planet;
