import {
  GET_STATUSES_INVOKED,
  GET_STATUSES_SUCCESS,
} from "src/redux/actions/travelerTracker";
import { TravelSegment } from "src/types/TravelSegments";

export type TravelerTrackerStatus = {
  code: string;
  count: number;
  id: string;
  isCalculated?: boolean;
  isSelected: boolean;
  segments: TravelSegment[];
  text: string;
};

export interface TravelerTrackerI {
  loading: boolean;
  statuses?: TravelerTrackerStatus[];
  error?: Error;
}

export interface TravelerTrackerState {
  ids: {
    [key: string]: TravelerTrackerI;
  };
}

export const initialState: TravelerTrackerState = { ids: {} };

const travelerTracker = (state = initialState, action) => {
  const result = { ...state };
  switch (action.type) {
    case GET_STATUSES_INVOKED:
      result.ids[action.id] = {
        loading: true,
      };
      return result;
    case GET_STATUSES_SUCCESS:
      result.ids[action.id] = {
        loading: false,
        statuses: action.statuses,
      };
      return result;

    default:
      return state;
  }
};

export default travelerTracker;
