import React from "react";
import { useSafeSetState } from "src/common/hooks";
import { Icon, Loader } from "semantic-ui-react";
import { getToken } from "src/common/apiClient";
import { BASE_URL } from "src/apiConfig";

const initialState = {
  isLoaded: false,
};

function AgentChat({ agentChatHref, handleClose }) {
  const [{ isLoaded }, safeSetState] = useSafeSetState(initialState);

  const token = encodeURIComponent(getToken().token);
  const url = `${BASE_URL}${agentChatHref}?access_token=${token}`;

  function handleOnLoad() {
    // We want to delay the iframe opening for a few seconds because the agent chat site is slow to load and has no styling/background etc.
    setTimeout(() => safeSetState({ isLoaded: true }), 3000);
  }

  return (
    <div className="chatbot-window">
      <span
        role="button"
        className="close-chatbot-window"
        onClick={handleClose}
      >
        <Icon name="close" size="large" />
      </span>
      {!isLoaded && (
        <div className="loading-container">
          <Loader active inline size="large" />
          <p className="loading-text">Loading...</p>
        </div>
      )}
      <iframe
        src={url}
        title="agent-chat"
        className="agent-chat-iframe"
        onLoad={handleOnLoad}
        style={!isLoaded ? { display: "none" } : {}}
      />
    </div>
  );
}

export default AgentChat;
