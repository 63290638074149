import {
  fetchCountriesFailed,
  fetchCountriesSuccess,
  fetchCountriesInvoked,
  COUNTRIES_FETCH_DATA,
} from "src/redux/actions/countries";

import { put, call, takeLatest, select } from "redux-saga/effects";
import { fetchData } from "src/services/countries";

export const getCountriesState = (state) => state.countries;

export function* fetchCountries({ isGlobalRiskMap }) {
  const countries = yield select(getCountriesState);
  if (!countries || !countries.payload || !countries.payload.length) {
    try {
      yield put(fetchCountriesInvoked());
      const payload = yield call(fetchData, isGlobalRiskMap);
      yield put(fetchCountriesSuccess(payload));
    } catch (ex) {
      yield put(fetchCountriesFailed(ex));
    }
  }
}

function* countriesSaga() {
  yield takeLatest(COUNTRIES_FETCH_DATA, fetchCountries);
}

export default countriesSaga;
