import {
  setSmartPortalState,
  FETCH_APPLICATION_DEPENDENCIES,
  setColorSettings,
  setCarousels,
} from "src/redux/actions/application";
import { setLightModePrefLocalStorage } from "src/services/application/";

import {
  fetchState,
  setAgentChat,
  setContactInfo,
  setGlobalSSOLinks,
  setWidgets,
  setSSOLinks,
  setTools,
  setLinks,
  setOBTS,
} from "src/redux/actions";
import { put, call, takeLatest, take, select } from "redux-saga/effects";
import { onRouteChange } from "src/services/application";
import { STATE_FETCH_SUCCESS } from "src/redux/actions/user";
import { LOCATION_CHANGE } from "src/redux/actions/application";
import { LIGHT_MODE_PREF, supportsLocalStorage } from "src/common";
import { defaultColors } from "src/redux/reducers/application";
import { setScout } from "src/services/scout";

// const OBTFeatureIdentifiers = {
//   Lightning: true,
//   GetThere: true,
//   "concur-saml-sso": true,
//   "deem-sso": true,
// };

const mapFeatures = (state) => {
  const tools = [
    "Elysium_Fare_Forecaster_Widget",
    "Elysium_Traveler_Tracker_Widget",
    "approve",
    "ctminvoices",
  ];

  const mappedFeatures = {
    agentChat: null,
    colorSettings: null,
    contactInfo: null,
    globalSsoLinks: [],
    ssoLinks: [],
    links: [],
    tools: [],
    widgets: [],
    carousels: [],
    obts: [],
  };

  const userData = state.user.data;
  // Validate that the user has data, state, and features before trying to do any mapping
  if (userData && userData.state && userData.state.features) {
    const features = userData.state.features;
    const provisionalUsersFeatureCombined = {};
    features.forEach((feature) => {
      if (feature.identifier === "contact-info") {
        mappedFeatures.contactInfo = feature.properties.contacts;
      } else if (feature.identifier === "Elysium_Docs_And_Link") {
        mappedFeatures.tools.push(feature);
      } else if (feature.identifier === "scout") {
        mappedFeatures.scout = feature;
      } else if (feature.identifier === "sitecolor") {
        mappedFeatures.colorSettings = feature;
      } else if (feature.identifier === "carousel") {
        mappedFeatures.carousels.push(feature);
      } else if (feature.identifier === "provisional_traveler") {
        if (feature.properties.isVisible) {
          // Unlike other widget features, we only want to show one instance of
          // provisional travelers. We combine active features here.
          if (!provisionalUsersFeatureCombined.identifier) {
            const provisionalUserFeatureData = {
              identifier: feature.identifier,
              order: feature.order,
              type: feature.type,
              name: "Provisional Travelers",
              id: "ProvisionalTravelers",
              properties: {
                id: "ProvisionalTravelers",
                isVisible: feature.properties.isVisible,
                colspan: feature.properties.colspan,
                featureData: [
                  {
                    provisionalFields: feature.properties,
                    id: feature.id,
                    name: feature.name,
                  },
                ],
              },
            };
            Object.assign(
              provisionalUsersFeatureCombined,
              provisionalUserFeatureData
            );
          } else {
            provisionalUsersFeatureCombined.properties.featureData.push({
              id: feature.id,
              name: feature.name,
              provisionalFields: feature.properties,
            });
          }
        }
      } else if (feature.type === "Widget") {
        mappedFeatures.widgets.push(feature);
      } else if (feature.type === "Link" || feature.type === "Document") {
        mappedFeatures.links.push(feature);
      } else if (feature.type === "Sso") {
        if (feature.identifier === "incontact-chat-sso") {
          mappedFeatures.agentChat = feature;
        } else {
          const isGlobal = feature.identifier.indexOf("ctm-portal") === 0;
          if (isGlobal) mappedFeatures.globalSsoLinks.push(feature);
          // else if (OBTFeatureIdentifiers[feature.identifier])
          //   mappedFeatures.obts.push(feature);
          else mappedFeatures.ssoLinks.push(feature);
        }
      }
      // Check to see if the feature is associated with a SMART tool
      if (tools.includes(feature.identifier))
        mappedFeatures.tools.push(feature);
    });
    if (provisionalUsersFeatureCombined.identifier)
      mappedFeatures.widgets.push(provisionalUsersFeatureCombined);
  }
  return mappedFeatures;
};

function getColorSettingsPayload(colorSettings) {
  return {
    colors: {
      dark: {
        primary: colorSettings.darkPrimaryColor || defaultColors.dark.primary,
        headerFooter:
          colorSettings.darkHeaderFooterColor ||
          defaultColors.dark.headerFooter,
      },
      light: {
        primary: colorSettings.lightPrimaryColor || defaultColors.light.primary,
        headerFooter:
          colorSettings.lightHeaderFooterColor ||
          defaultColors.light.headerFooter,
      },
    },
  };
}

function* fetchApplicationDependencies() {
  yield put(fetchState());
  yield take(STATE_FETCH_SUCCESS);
  const mappedFeatures = yield select(mapFeatures);
  yield put(setWidgets(mappedFeatures.widgets));
  yield put(setGlobalSSOLinks(mappedFeatures.globalSsoLinks));
  yield put(setSSOLinks(mappedFeatures.ssoLinks));
  yield put(setTools(mappedFeatures.tools));
  yield put(setLinks(mappedFeatures.links));
  yield put(setOBTS(mappedFeatures.obts));
  if (mappedFeatures.agentChat)
    yield put(setAgentChat(mappedFeatures.agentChat));

  if (mappedFeatures.scout) {
    setScout(mappedFeatures.scout.href);
  }

  const colorSettings =
    (mappedFeatures.colorSettings && mappedFeatures.colorSettings.properties) ||
    {};

  const colorPayload = getColorSettingsPayload(colorSettings);

  const currentLightMode = supportsLocalStorage()
    ? JSON.parse(localStorage.getItem(LIGHT_MODE_PREF))
    : null;
  const lightModeIsSet =
    currentLightMode === true || currentLightMode === false;

  if (!lightModeIsSet)
    colorPayload.lightMode = colorSettings.lightMode || false;

  yield put(setColorSettings(colorPayload));

  if (!lightModeIsSet) {
    setLightModePrefLocalStorage(colorSettings.lightMode || false);
  }

  if (mappedFeatures.carousels) {
    yield put(setCarousels(mappedFeatures.carousels));
  }

  if (mappedFeatures.contactInfo)
    yield put(setContactInfo(mappedFeatures.contactInfo));
}

function* routeLocationChanged({ payload }) {
  window.scrollTo(0, 0);
  let path = (payload && payload.location && payload.location.pathname) || "";
  if (path.indexOf("/") === 0) path = path.substring(1);
  const newState = yield call(onRouteChange, path);
  yield put(setSmartPortalState(newState));
}

function* applicationSaga() {
  yield takeLatest(LOCATION_CHANGE, routeLocationChanged);
  yield takeLatest(
    FETCH_APPLICATION_DEPENDENCIES,
    fetchApplicationDependencies
  );
}

export default applicationSaga;
