import SVGIcon from "src/components/SVGIcon";

const ThousandMilesIcon = ({ title = "Thousand Miles", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 921 921" title={title} {...props}>
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M535.000000,922.000000 
	C356.666656,922.000000 178.833328,922.000000 1.000000,922.000000 
	C1.000000,615.000000 1.000000,308.000000 1.000000,1.000000 
	C308.000000,1.000000 615.000000,1.000000 922.000000,1.000000 
	C922.000000,308.000000 922.000000,615.000000 922.000000,922.000000 
	C793.166687,922.000000 664.333313,922.000000 535.000000,922.000000 
M775.000000,325.477356 
	C775.000000,289.334198 775.035889,253.190979 774.955078,217.048019 
	C774.944824,212.466400 774.769409,207.800125 773.906067,203.320374 
	C770.394287,185.097107 762.625488,168.946228 747.682007,157.495270 
	C740.516479,152.004440 732.259766,147.390182 723.801575,144.256836 
	C709.653503,139.015701 694.739563,138.865906 679.778809,140.946854 
	C665.567810,142.923523 652.686157,148.141174 641.619995,157.058533 
	C622.918152,172.128799 614.407654,192.682632 614.195496,216.070801 
	C613.826050,256.788635 614.456726,297.520844 615.110046,338.239929 
	C615.349243,353.151001 620.795105,366.807159 630.061218,378.317566 
	C646.822998,399.138977 669.771973,407.714752 695.799438,407.730469 
	C705.170410,407.736115 714.821777,405.563049 723.857361,402.793060 
	C742.735168,397.005890 757.037598,385.085388 766.160522,367.364807 
	C772.761719,354.542419 775.072998,340.753021 775.000000,325.477356 
M265.456482,150.959259 
	C256.362366,156.249191 248.619095,162.867950 242.600021,171.708038 
	C233.460312,185.131332 229.153427,199.920578 229.054291,215.932053 
	C228.866592,246.249313 228.910034,276.568939 229.045792,306.886749 
	C229.097641,318.464081 229.252136,330.069458 230.099121,341.607788 
	C231.300705,357.977020 238.130798,372.214020 249.511887,383.824402 
	C261.580963,396.136658 276.486816,403.191925 293.662354,406.206268 
	C305.995117,408.370728 318.048553,407.981110 330.016083,405.392303 
	C346.089294,401.915497 359.815369,394.078247 370.928467,381.658508 
	C382.183136,369.080566 388.512848,354.216034 388.780090,337.737488 
	C389.447266,296.601898 388.929871,255.448044 389.041656,214.301910 
	C389.076324,201.544601 385.887146,189.676208 379.881439,178.590836 
	C366.886444,154.604538 345.515656,143.053360 319.377899,140.196152 
	C300.815826,138.167068 282.639526,140.946564 265.456482,150.959259 
M468.697388,401.965820 
	C470.662109,402.642487 472.594635,403.439148 474.596710,403.976593 
	C489.069427,407.861847 503.649200,408.914001 518.482361,406.040833 
	C532.693542,403.288208 545.555969,397.721802 556.357605,388.053314 
	C573.360962,372.833740 580.901855,353.334137 580.971008,330.812958 
	C581.079956,295.335388 581.050110,259.857086 580.954895,224.379410 
	C580.937256,217.803009 580.684509,211.187714 579.931274,204.660645 
	C578.080811,188.626129 571.638550,174.486160 560.345764,162.976440 
	C547.079163,149.455063 530.475403,141.889969 511.803345,140.188492 
	C493.258240,138.498581 475.077576,140.674835 458.362152,150.180115 
	C434.809540,163.573334 422.059814,184.236160 421.298035,210.700745 
	C420.153137,250.476349 421.141296,290.310059 420.927338,330.117462 
	C420.846893,345.085815 424.140656,358.948944 431.978943,371.697815 
	C440.602814,385.724457 452.639404,395.651672 468.697388,401.965820 
M291.965454,496.381317 
	C278.210266,524.737305 264.455078,553.093262 250.309402,582.254211 
	C230.369064,541.013611 211.067581,501.094360 191.784592,461.213318 
	C177.244171,461.213318 162.804581,461.213318 148.208801,461.213318 
	C148.208801,536.492554 148.208801,611.558350 148.208801,686.788452 
	C162.821960,686.788452 177.225937,686.788452 192.249390,686.788452 
	C192.249390,643.853271 192.249390,601.125916 192.249390,557.135681 
	C198.600266,569.204773 204.433716,579.951721 209.951721,590.858215 
	C217.798035,606.366699 225.347107,622.025696 233.228180,637.516113 
	C234.007767,639.048401 236.180420,640.792725 237.760086,640.845825 
	C247.070297,641.158936 256.396362,640.999878 265.349335,640.999878 
	C279.355499,613.505920 293.190582,586.347778 307.025665,559.189636 
	C307.399933,559.286194 307.774170,559.382751 308.148438,559.479248 
	C308.148438,601.958069 308.148438,644.436890 308.148438,686.645813 
	C323.444489,686.645813 338.184631,686.645813 352.650330,686.645813 
	C352.650330,611.225037 352.650330,536.158630 352.650330,461.124908 
	C337.652679,461.124908 323.005035,461.124908 308.743774,461.124908 
	C303.093903,472.786957 297.564911,484.199493 291.965454,496.381317 
M690.544067,593.000000 
	C717.300598,593.000000 744.057129,593.000000 770.695923,593.000000 
	C770.695923,579.323242 770.695923,566.251038 770.695923,552.787903 
	C740.988525,552.787903 711.583496,552.787903 682.307007,552.787903 
	C682.307007,535.317383 682.307007,518.245850 682.307007,500.782593 
	C717.016968,500.782593 751.421204,500.782593 785.690125,500.782593 
	C785.690125,487.311523 785.690125,474.239136 785.690125,461.308105 
	C735.978333,461.308105 686.576904,461.308105 637.303101,461.308105 
	C637.303101,536.682251 637.303101,611.748047 637.303101,686.698730 
	C687.007996,686.698730 736.409302,686.698730 785.696045,686.698730 
	C785.696045,673.323425 785.696045,660.251282 785.696045,646.788696 
	C750.989197,646.788696 716.584961,646.788696 682.306396,646.788696 
	C682.306396,628.652527 682.306396,610.914551 682.306396,593.000000 
	C684.927856,593.000000 687.244141,593.000000 690.544067,593.000000 
M150.000000,279.500000 
	C150.000000,321.254486 150.000000,363.008972 150.000000,404.722839 
	C166.625549,404.722839 182.695023,404.722839 198.742798,404.722839 
	C198.742798,317.056458 198.742798,229.667511 198.742798,142.000244 
	C183.187836,142.000244 167.888474,141.970795 152.589798,142.058655 
	C151.422592,142.065353 150.036728,142.636719 149.132431,143.397324 
	C143.995590,147.718002 139.001968,152.208313 133.908173,156.580963 
	C122.363144,166.491516 110.726616,176.297256 99.307358,186.350159 
	C97.961548,187.534958 97.120415,189.991501 97.098335,191.870911 
	C96.922134,206.864899 96.992393,221.861877 97.016663,236.857895 
	C97.018341,237.894806 97.257736,238.931335 97.457436,240.538040 
	C115.177055,225.161133 132.385361,210.227936 150.000000,194.942123 
	C150.000000,223.228638 150.000000,250.864319 150.000000,279.500000 
M504.000000,525.500000 
	C504.000000,504.071655 504.000000,482.643311 504.000000,461.289581 
	C489.019714,461.289581 474.614624,461.289581 460.285950,461.289581 
	C460.285950,536.646118 460.285950,611.712708 460.285950,686.714844 
	C509.307617,686.714844 558.042847,686.714844 606.711914,686.714844 
	C606.711914,673.355896 606.711914,660.283997 606.711914,646.819824 
	C572.354065,646.819824 538.282715,646.819824 504.000000,646.819824 
	C504.000000,606.448425 504.000000,566.474243 504.000000,525.500000 
M416.482910,461.000000 
	C405.720184,461.000000 394.957489,461.000000 384.291992,461.000000 
	C384.291992,536.656189 384.291992,611.723816 384.291992,686.704834 
	C398.991180,686.704834 413.396576,686.704834 427.706970,686.704834 
	C427.706970,611.341736 427.706970,536.274170 427.706970,461.000000 
	C424.102753,461.000000 420.789856,461.000000 416.482910,461.000000 
M203.000000,803.478271 
	C203.000000,806.918396 203.000000,810.358582 203.000000,813.724731 
	C206.626312,813.724731 209.698807,813.724731 213.147217,813.724731 
	C213.147217,807.377380 213.147217,801.297180 213.147217,795.000000 
	C216.010742,795.000000 218.523941,794.728699 220.944504,795.085632 
	C222.886703,795.372070 225.170013,795.952759 226.493652,797.236328 
	C228.662750,799.339783 230.627731,801.913147 231.879807,804.653442 
	C235.469864,812.510559 241.226105,815.699829 249.690201,813.987427 
	C249.966797,813.931396 250.229416,813.806458 250.823380,813.601624 
	C246.096848,806.892029 241.435715,800.275269 236.692780,793.542358 
	C242.238510,791.994446 246.091339,789.259644 247.013290,783.802002 
	C247.606918,780.287964 247.887665,776.691223 247.955872,773.124817 
	C248.073990,766.947937 243.159668,759.828186 237.137634,759.237366 
	C225.888672,758.133606 214.539413,758.052246 203.000000,757.535034 
	C203.000000,773.219299 203.000000,787.851013 203.000000,803.478271 
M587.462524,793.000366 
	C589.653625,791.227844 592.014221,789.625549 594.000610,787.647400 
	C598.102783,783.562134 596.918884,778.214417 596.975220,773.229248 
	C597.045105,767.035645 593.366394,761.010132 587.403076,760.187866 
	C575.793518,758.587097 564.033386,758.078552 552.000000,757.090027 
	C552.000000,767.689148 552.000000,776.830444 552.000000,785.971680 
	C552.000000,795.243164 552.000000,804.514648 552.000000,813.720398 
	C555.630676,813.720398 558.705383,813.720398 562.161621,813.720398 
	C562.161621,807.364075 562.161621,801.281738 562.161621,795.237732 
	C572.710144,794.594604 573.017517,794.373657 578.874329,802.521729 
	C583.902344,809.516785 588.259399,816.857056 598.767883,813.981873 
	C599.031738,813.909790 599.273071,813.755737 599.786926,813.519470 
	C596.803711,809.442749 593.718750,805.533813 590.992188,801.389038 
	C589.281006,798.787842 588.108154,795.832520 587.462524,793.000366 
M665.004639,778.615417 
	C664.248047,776.178406 663.917236,773.494263 662.668335,771.343018 
	C651.616638,752.306335 623.822571,752.033447 612.008545,768.505432 
	C603.922241,779.780029 604.898682,797.266846 615.430908,806.739990 
	C628.643311,818.623779 648.643188,817.436584 659.832886,803.557007 
	C665.485657,796.545349 667.586914,788.438660 665.004639,778.615417 
M407.303345,781.000183 
	C399.221100,781.000183 391.138885,781.000183 383.359894,781.000183 
	C383.359894,775.875122 383.359894,771.466370 383.359894,766.682007 
	C393.463776,766.682007 403.209595,766.682007 412.820923,766.682007 
	C412.820923,763.663208 412.820923,761.040588 412.820923,758.361694 
	C399.447296,758.361694 386.255920,758.361694 373.361816,758.361694 
	C373.361816,777.129089 373.361816,795.533875 373.361816,813.641479 
	C387.132782,813.641479 400.540771,813.641479 413.819275,813.641479 
	C413.819275,810.659180 413.819275,808.037048 413.819275,804.846802 
	C403.448120,804.846802 393.242645,804.846802 383.367249,804.846802 
	C383.367249,799.524048 383.367249,794.779358 383.367249,789.838867 
	C392.357361,789.838867 400.980957,789.838867 410.811951,789.838867 
	C409.878723,786.601868 409.071716,783.802673 407.303345,781.000183 
M273.999939,767.510925 
	C267.264832,782.853577 260.529724,798.196228 253.658310,813.849365 
	C257.797699,813.849365 261.269470,813.849365 264.503754,813.849365 
	C266.065613,810.082886 267.337067,806.421204 269.132416,803.037598 
	C269.696503,801.974487 271.651917,801.130859 272.998138,801.089600 
	C279.489594,800.890747 286.000549,801.217163 292.484192,800.916809 
	C296.241760,800.742737 297.984650,801.955444 299.107849,805.631348 
	C301.694458,814.096802 304.090240,815.238037 313.139893,813.269714 
	C312.087524,810.900085 311.057404,808.577698 310.025055,806.256348 
	C304.357452,793.511780 298.412384,780.881348 293.109375,767.986877 
	C288.903687,757.760559 289.252838,757.616943 278.483826,758.175781 
	C277.000183,761.024048 275.516541,763.872253 273.999939,767.510925 
M493.443939,769.036682 
	C488.178070,775.049744 488.327454,782.455688 489.219116,789.509277 
	C489.837646,794.402344 491.485687,799.840637 494.458038,803.639099 
	C505.029999,817.149353 522.988037,817.695068 536.505371,809.673950 
	C538.494019,808.493958 540.568420,805.810181 540.796997,803.632568 
	C541.397034,797.916870 540.999695,792.096558 540.999695,786.223694 
	C537.474792,786.223694 534.405090,786.223694 531.023804,786.223694 
	C531.023804,791.548218 531.023804,796.657593 531.023804,802.822876 
	C525.949402,803.566223 521.055420,804.863953 516.161133,804.864929 
	C508.676483,804.866455 503.123077,800.850586 500.505920,793.890137 
	C497.900940,786.962036 497.655365,779.954529 502.813934,773.673462 
	C509.238190,765.851257 519.801086,763.606628 529.953308,769.253479 
	C531.144470,769.916016 533.700317,769.219666 535.005432,768.287659 
	C536.430420,767.270142 537.136169,765.245483 538.135681,763.690613 
	C529.882935,757.489136 528.141479,756.903931 515.164307,757.029968 
	C506.147064,757.117493 499.200439,761.288513 493.443939,769.036682 
M739.666138,813.995728 
	C742.413696,813.995728 745.161255,813.995728 748.000000,813.995728 
	C748.000000,810.822144 748.000000,808.332642 748.000000,805.843140 
	C748.000000,803.257568 748.000000,800.671936 748.000000,798.000000 
	C752.169617,798.000000 755.764709,798.539246 759.124756,797.880005 
	C764.889465,796.748962 770.960388,795.737549 776.066772,793.073669 
	C781.828979,790.067322 783.916565,778.942444 781.605347,771.077820 
	C779.634460,764.371277 775.163269,759.893005 768.402100,759.158386 
	C758.467651,758.078979 748.420898,758.033569 738.050537,757.529602 
	C738.050537,776.664795 738.047974,794.932434 738.077820,813.199951 
	C738.078247,813.446777 738.489136,813.692932 739.666138,813.995728 
M689.364502,799.891479 
	C688.242981,794.730530 686.467712,789.609863 686.146851,784.399597 
	C685.609558,775.674500 686.000000,766.892273 686.000000,758.311890 
	C682.640259,758.311890 679.901184,758.311890 677.000000,758.311890 
	C677.000000,770.762817 676.654175,782.918518 677.206848,795.033264 
	C677.376709,798.755798 679.246155,802.994446 681.657104,805.902893 
	C687.472961,812.919250 694.929443,815.805298 704.518555,814.204041 
	C713.511047,812.702515 720.801453,808.476868 722.099060,800.007751 
	C724.200989,786.288757 723.963013,772.211243 724.713806,758.269592 
	C720.385559,758.269592 717.313110,758.269592 714.000000,758.269592 
	C714.000000,769.169250 714.036255,779.801392 713.986389,790.433105 
	C713.956726,796.747681 711.722290,801.682434 705.390015,804.185303 
	C700.116394,806.269714 694.507751,805.023071 689.364502,799.891479 
M321.341736,758.897705 
	C317.622742,758.515320 313.903748,758.132935 309.806549,757.711609 
	C316.820251,775.562988 323.399933,792.391052 330.076965,809.180481 
	C330.759033,810.895569 331.657471,813.145081 333.086578,813.830078 
	C338.003113,816.186890 344.031921,813.236694 345.985840,808.135864 
	C349.648163,798.575134 353.371979,789.037659 357.121643,779.510803 
	C359.863342,772.544861 362.687408,765.611389 365.656250,758.211121 
	C361.404205,758.211121 357.959412,758.211121 354.549744,758.211121 
	C349.165497,771.231323 343.786560,784.238770 337.863800,798.561279 
	C335.629639,793.378357 333.925201,789.632690 332.389313,785.819214 
	C328.863342,777.064514 325.419525,768.276794 321.341736,758.897705 
M182.482727,758.000000 
	C171.716385,758.000000 160.950027,758.000000 150.290619,758.000000 
	C150.290619,761.318787 150.290619,764.057983 150.290619,767.183105 
	C155.986008,767.183105 161.397964,767.183105 167.184143,767.183105 
	C167.184143,782.992859 167.184143,798.403381 167.184143,813.706848 
	C170.657745,813.706848 173.732758,813.706848 177.186462,813.706848 
	C177.186462,798.010681 177.186462,782.604126 177.186462,766.814087 
	C182.997009,766.814087 188.412369,766.814087 193.705475,766.814087 
	C193.705475,763.674255 193.705475,760.935120 193.705475,758.000000 
	C190.100708,758.000000 186.788620,758.000000 182.482727,758.000000 
M425.000000,807.426270 
	C425.000000,809.531311 425.000000,811.636353 425.000000,813.740112 
	C436.935211,813.740112 448.343719,813.740112 459.737976,813.740112 
	C459.737976,810.735840 459.737976,807.997986 459.737976,804.870789 
	C451.404816,804.870789 443.328278,804.870789 434.868713,804.870789 
	C434.868713,789.062988 434.868713,773.652161 434.868713,758.266113 
	C431.395782,758.266113 428.323273,758.266113 425.000000,758.266113 
	C425.000000,774.500427 425.000000,790.470032 425.000000,807.426270 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M775.000000,325.972565 
	C775.072998,340.753021 772.761719,354.542419 766.160522,367.364807 
	C757.037598,385.085388 742.735168,397.005890 723.857361,402.793060 
	C714.821777,405.563049 705.170410,407.736115 695.799438,407.730469 
	C669.771973,407.714752 646.822998,399.138977 630.061218,378.317566 
	C620.795105,366.807159 615.349243,353.151001 615.110046,338.239929 
	C614.456726,297.520844 613.826050,256.788635 614.195496,216.070801 
	C614.407654,192.682632 622.918152,172.128799 641.619995,157.058533 
	C652.686157,148.141174 665.567810,142.923523 679.778809,140.946854 
	C694.739563,138.865906 709.653503,139.015701 723.801575,144.256836 
	C732.259766,147.390182 740.516479,152.004440 747.682007,157.495270 
	C762.625488,168.946228 770.394287,185.097107 773.906067,203.320374 
	C774.769409,207.800125 774.944824,212.466400 774.955078,217.048019 
	C775.035889,253.190979 775.000000,289.334198 775.000000,325.972565 
M723.977295,343.241669 
	C724.651550,339.611664 725.885193,335.985504 725.909912,332.351074 
	C726.080811,307.214569 726.002502,282.076355 725.992371,256.938690 
	C725.991882,255.744156 725.879700,254.549637 725.781067,252.591003 
	C724.469421,254.133240 723.614929,254.907516 723.036865,255.851135 
	C704.827026,285.576630 686.616516,315.301880 668.556274,345.118103 
	C667.824341,346.326477 667.920349,348.820740 668.697571,350.030579 
	C673.474365,357.466797 680.279297,361.259308 689.120422,363.299286 
	C702.650696,366.421234 719.634338,357.317108 723.977295,343.241669 
M696.965027,239.398666 
	C704.636658,226.763702 712.376953,214.169159 719.872742,201.430710 
	C720.614136,200.170700 720.422058,197.746033 719.710938,196.345459 
	C714.702087,186.480042 698.453064,180.733170 685.679199,184.658264 
	C670.522156,189.315598 663.376709,201.539032 663.117981,215.996902 
	C662.685730,240.142303 663.000000,264.301086 663.000000,288.454224 
	C663.000000,289.837006 663.000000,291.219788 663.000000,292.602600 
	C663.356628,292.693268 663.713318,292.783966 664.069946,292.874634 
	C674.881897,275.248291 685.693848,257.621979 696.965027,239.398666 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M265.743713,150.708923 
	C282.639526,140.946564 300.815826,138.167068 319.377899,140.196152 
	C345.515656,143.053360 366.886444,154.604538 379.881439,178.590836 
	C385.887146,189.676208 389.076324,201.544601 389.041656,214.301910 
	C388.929871,255.448044 389.447266,296.601898 388.780090,337.737488 
	C388.512848,354.216034 382.183136,369.080566 370.928467,381.658508 
	C359.815369,394.078247 346.089294,401.915497 330.016083,405.392303 
	C318.048553,407.981110 305.995117,408.370728 293.662354,406.206268 
	C276.486816,403.191925 261.580963,396.136658 249.511887,383.824402 
	C238.130798,372.214020 231.300705,357.977020 230.099121,341.607788 
	C229.252136,330.069458 229.097641,318.464081 229.045792,306.886749 
	C228.910034,276.568939 228.866592,246.249313 229.054291,215.932053 
	C229.153427,199.920578 233.460312,185.131332 242.600021,171.708038 
	C248.619095,162.867950 256.362366,156.249191 265.743713,150.708923 
M340.052582,335.599182 
	C340.368408,333.859558 340.954987,332.120575 340.959198,330.380188 
	C341.019104,305.747650 341.000000,281.114929 341.000000,256.482239 
	C341.000000,255.117615 341.000000,253.753006 341.000000,251.250595 
	C339.191864,253.709167 338.050415,255.039719 337.150696,256.517426 
	C319.111755,286.144714 301.086487,315.780396 283.146393,345.467499 
	C282.553284,346.448975 282.329895,348.314392 282.864532,349.216217 
	C289.256683,359.998322 302.790649,366.525299 317.390106,363.033905 
	C329.497040,360.138519 338.879425,349.338806 340.052582,335.599182 
M317.860474,184.333435 
	C313.395294,184.222229 308.889587,183.671768 304.472198,184.079071 
	C288.869751,185.517700 278.448456,198.402740 278.143860,213.281036 
	C277.622681,238.741348 277.996521,264.220032 278.013000,289.691010 
	C278.013641,290.680176 278.205750,291.669189 278.365936,293.209167 
	C280.831329,289.522400 282.994629,286.522766 284.916901,283.375885 
	C301.495270,256.236420 318.059113,229.087906 334.490906,201.859802 
	C335.295837,200.526016 335.598358,197.911179 334.830719,196.770355 
	C330.872620,190.887909 325.631683,186.495209 317.860474,184.333435 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M468.358765,401.745941 
	C452.639404,395.651672 440.602814,385.724457 431.978943,371.697815 
	C424.140656,358.948944 420.846893,345.085815 420.927338,330.117462 
	C421.141296,290.310059 420.153137,250.476349 421.298035,210.700745 
	C422.059814,184.236160 434.809540,163.573334 458.362152,150.180115 
	C475.077576,140.674835 493.258240,138.498581 511.803345,140.188492 
	C530.475403,141.889969 547.079163,149.455063 560.345764,162.976440 
	C571.638550,174.486160 578.080811,188.626129 579.931274,204.660645 
	C580.684509,211.187714 580.937256,217.803009 580.954895,224.379410 
	C581.050110,259.857086 581.079956,295.335388 580.971008,330.812958 
	C580.901855,353.334137 573.360962,372.833740 556.357605,388.053314 
	C545.555969,397.721802 532.693542,403.288208 518.482361,406.040833 
	C503.649200,408.914001 489.069427,407.861847 474.596710,403.976593 
	C472.594635,403.439148 470.662109,402.642487 468.358765,401.745941 
M492.991638,315.514191 
	C487.098938,325.089722 481.062866,334.582672 475.452148,344.320679 
	C474.496704,345.978943 474.542053,349.179901 475.528961,350.826935 
	C480.231934,358.675873 487.980194,362.273071 496.589874,363.732727 
	C505.297455,365.208954 518.618896,361.937653 525.389526,352.378754 
	C531.049377,344.388123 532.771729,335.511932 532.895264,326.299805 
	C533.209900,302.840302 533.003235,279.373810 532.990723,255.910095 
	C532.990051,254.724319 532.853943,253.538589 532.780945,252.352844 
	C532.454041,252.265060 532.127197,252.177277 531.800354,252.089508 
	C519.040588,273.053009 506.280762,294.016510 492.991638,315.514191 
M516.030457,217.664246 
	C519.663818,211.952332 523.377808,206.288422 526.838684,200.473862 
	C527.325378,199.656250 526.926941,197.888977 526.342346,196.921173 
	C519.444214,185.500778 505.266937,180.644455 491.955536,184.578415 
	C476.914978,189.023438 469.491180,201.746353 469.146149,217.169647 
	C468.598419,241.654602 468.996674,266.160797 469.011963,290.657990 
	C469.012604,291.658722 469.188416,292.659302 469.374115,294.631927 
	C485.280090,268.610321 500.622040,243.511414 516.030457,217.664246 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M292.000671,495.996643 
	C297.564911,484.199493 303.093903,472.786957 308.743774,461.124908 
	C323.005035,461.124908 337.652679,461.124908 352.650330,461.124908 
	C352.650330,536.158630 352.650330,611.225037 352.650330,686.645813 
	C338.184631,686.645813 323.444489,686.645813 308.148438,686.645813 
	C308.148438,644.436890 308.148438,601.958069 308.148438,559.479248 
	C307.774170,559.382751 307.399933,559.286194 307.025665,559.189636 
	C293.190582,586.347778 279.355499,613.505920 265.349335,640.999878 
	C256.396362,640.999878 247.070297,641.158936 237.760086,640.845825 
	C236.180420,640.792725 234.007767,639.048401 233.228180,637.516113 
	C225.347107,622.025696 217.798035,606.366699 209.951721,590.858215 
	C204.433716,579.951721 198.600266,569.204773 192.249390,557.135681 
	C192.249390,601.125916 192.249390,643.853271 192.249390,686.788452 
	C177.225937,686.788452 162.821960,686.788452 148.208801,686.788452 
	C148.208801,611.558350 148.208801,536.492554 148.208801,461.213318 
	C162.804581,461.213318 177.244171,461.213318 191.784592,461.213318 
	C211.067581,501.094360 230.369064,541.013611 250.309402,582.254211 
	C264.455078,553.093262 278.210266,524.737305 292.000671,495.996643 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M690.052246,593.000000 
	C687.244141,593.000000 684.927856,593.000000 682.306396,593.000000 
	C682.306396,610.914551 682.306396,628.652527 682.306396,646.788696 
	C716.584961,646.788696 750.989197,646.788696 785.696045,646.788696 
	C785.696045,660.251282 785.696045,673.323425 785.696045,686.698730 
	C736.409302,686.698730 687.007996,686.698730 637.303101,686.698730 
	C637.303101,611.748047 637.303101,536.682251 637.303101,461.308105 
	C686.576904,461.308105 735.978333,461.308105 785.690125,461.308105 
	C785.690125,474.239136 785.690125,487.311523 785.690125,500.782593 
	C751.421204,500.782593 717.016968,500.782593 682.307007,500.782593 
	C682.307007,518.245850 682.307007,535.317383 682.307007,552.787903 
	C711.583496,552.787903 740.988525,552.787903 770.695923,552.787903 
	C770.695923,566.251038 770.695923,579.323242 770.695923,593.000000 
	C744.057129,593.000000 717.300598,593.000000 690.052246,593.000000 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M150.000000,279.000000 
	C150.000000,250.864319 150.000000,223.228638 150.000000,194.942123 
	C132.385361,210.227936 115.177055,225.161133 97.457436,240.538040 
	C97.257736,238.931335 97.018341,237.894806 97.016663,236.857895 
	C96.992393,221.861877 96.922134,206.864899 97.098335,191.870911 
	C97.120415,189.991501 97.961548,187.534958 99.307358,186.350159 
	C110.726616,176.297256 122.363144,166.491516 133.908173,156.580963 
	C139.001968,152.208313 143.995590,147.718002 149.132431,143.397324 
	C150.036728,142.636719 151.422592,142.065353 152.589798,142.058655 
	C167.888474,141.970795 183.187836,142.000244 198.742798,142.000244 
	C198.742798,229.667511 198.742798,317.056458 198.742798,404.722839 
	C182.695023,404.722839 166.625549,404.722839 150.000000,404.722839 
	C150.000000,363.008972 150.000000,321.254486 150.000000,279.000000 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M504.000000,526.000000 
	C504.000000,566.474243 504.000000,606.448425 504.000000,646.819824 
	C538.282715,646.819824 572.354065,646.819824 606.711914,646.819824 
	C606.711914,660.283997 606.711914,673.355896 606.711914,686.714844 
	C558.042847,686.714844 509.307617,686.714844 460.285950,686.714844 
	C460.285950,611.712708 460.285950,536.646118 460.285950,461.289581 
	C474.614624,461.289581 489.019714,461.289581 504.000000,461.289581 
	C504.000000,482.643311 504.000000,504.071655 504.000000,526.000000 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M416.979919,461.000000 
	C420.789856,461.000000 424.102753,461.000000 427.706970,461.000000 
	C427.706970,536.274170 427.706970,611.341736 427.706970,686.704834 
	C413.396576,686.704834 398.991180,686.704834 384.291992,686.704834 
	C384.291992,611.723816 384.291992,536.656189 384.291992,461.000000 
	C394.957489,461.000000 405.720184,461.000000 416.979919,461.000000 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M203.000000,802.980469 
	C203.000000,787.851013 203.000000,773.219299 203.000000,757.535034 
	C214.539413,758.052246 225.888672,758.133606 237.137634,759.237366 
	C243.159668,759.828186 248.073990,766.947937 247.955872,773.124817 
	C247.887665,776.691223 247.606918,780.287964 247.013290,783.802002 
	C246.091339,789.259644 242.238510,791.994446 236.692780,793.542358 
	C241.435715,800.275269 246.096848,806.892029 250.823380,813.601624 
	C250.229416,813.806458 249.966797,813.931396 249.690201,813.987427 
	C241.226105,815.699829 235.469864,812.510559 231.879807,804.653442 
	C230.627731,801.913147 228.662750,799.339783 226.493652,797.236328 
	C225.170013,795.952759 222.886703,795.372070 220.944504,795.085632 
	C218.523941,794.728699 216.010742,795.000000 213.147217,795.000000 
	C213.147217,801.297180 213.147217,807.377380 213.147217,813.724731 
	C209.698807,813.724731 206.626312,813.724731 203.000000,813.724731 
	C203.000000,810.358582 203.000000,806.918396 203.000000,802.980469 
M224.405487,767.000000 
	C220.660202,767.000000 216.914917,767.000000 213.268997,767.000000 
	C213.268997,773.652588 213.268997,779.701904 213.268997,785.999207 
	C218.979309,785.999207 224.452606,786.357117 229.859024,785.908691 
	C236.628357,785.347351 238.324722,781.642944 238.062180,776.233704 
	C237.768799,770.188782 235.628128,767.491455 229.835831,767.018860 
	C228.353821,766.897949 226.853592,767.000244 224.405487,767.000000 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M587.078125,793.018188 
	C588.108154,795.832520 589.281006,798.787842 590.992188,801.389038 
	C593.718750,805.533813 596.803711,809.442749 599.786926,813.519470 
	C599.273071,813.755737 599.031738,813.909790 598.767883,813.981873 
	C588.259399,816.857056 583.902344,809.516785 578.874329,802.521729 
	C573.017517,794.373657 572.710144,794.594604 562.161621,795.237732 
	C562.161621,801.281738 562.161621,807.364075 562.161621,813.720398 
	C558.705383,813.720398 555.630676,813.720398 552.000000,813.720398 
	C552.000000,804.514648 552.000000,795.243164 552.000000,785.971680 
	C552.000000,776.830444 552.000000,767.689148 552.000000,757.090027 
	C564.033386,758.078552 575.793518,758.587097 587.403076,760.187866 
	C593.366394,761.010132 597.045105,767.035645 596.975220,773.229248 
	C596.918884,778.214417 598.102783,783.562134 594.000610,787.647400 
	C592.014221,789.625549 589.653625,791.227844 587.078125,793.018188 
M568.557800,786.000000 
	C571.885925,785.999878 575.254883,786.325256 578.531311,785.913513 
	C582.250305,785.446106 585.910461,784.199219 586.952332,779.902710 
	C588.342712,774.168640 586.770142,769.216675 582.010132,768.215149 
	C575.572388,766.860718 568.806030,767.068665 562.318481,766.615906 
	C562.318481,773.720337 562.318481,779.789062 562.318481,786.000000 
	C564.286377,786.000000 565.936523,786.000000 568.557800,786.000000 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M665.031006,779.052551 
	C667.586914,788.438660 665.485657,796.545349 659.832886,803.557007 
	C648.643188,817.436584 628.643311,818.623779 615.430908,806.739990 
	C604.898682,797.266846 603.922241,779.780029 612.008545,768.505432 
	C623.822571,752.033447 651.616638,752.306335 662.668335,771.343018 
	C663.917236,773.494263 664.248047,776.178406 665.031006,779.052551 
M628.992188,767.015015 
	C620.330139,771.626770 616.091309,778.855042 617.077515,788.610657 
	C617.881287,796.562622 623.000183,801.584106 630.077209,804.531982 
	C636.749084,807.310913 645.682922,804.786926 651.233093,798.129822 
	C657.169128,791.009827 658.123230,782.891357 651.836975,773.749023 
	C646.785095,766.401855 639.717407,764.173157 628.992188,767.015015 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M407.783997,781.001831 
	C409.071716,783.802673 409.878723,786.601868 410.811951,789.838867 
	C400.980957,789.838867 392.357361,789.838867 383.367249,789.838867 
	C383.367249,794.779358 383.367249,799.524048 383.367249,804.846802 
	C393.242645,804.846802 403.448120,804.846802 413.819275,804.846802 
	C413.819275,808.037048 413.819275,810.659180 413.819275,813.641479 
	C400.540771,813.641479 387.132782,813.641479 373.361816,813.641479 
	C373.361816,795.533875 373.361816,777.129089 373.361816,758.361694 
	C386.255920,758.361694 399.447296,758.361694 412.820923,758.361694 
	C412.820923,761.040588 412.820923,763.663208 412.820923,766.682007 
	C403.209595,766.682007 393.463776,766.682007 383.359894,766.682007 
	C383.359894,771.466370 383.359894,775.875122 383.359894,781.000183 
	C391.138885,781.000183 399.221100,781.000183 407.783997,781.001831 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M274.016418,767.115723 
	C275.516541,763.872253 277.000183,761.024048 278.488953,758.178467 
	C289.252838,757.616943 288.903687,757.760559 293.109375,767.986877 
	C298.412384,780.881348 304.357452,793.511780 310.025055,806.256348 
	C311.057404,808.577698 312.087524,810.900085 313.139893,813.269714 
	C304.090240,815.238037 301.694458,814.096802 299.107849,805.631348 
	C297.984650,801.955444 296.241760,800.742737 292.484192,800.916809 
	C286.000549,801.217163 279.489594,800.890747 272.998138,801.089600 
	C271.651917,801.130859 269.696503,801.974487 269.132416,803.037598 
	C267.337067,806.421204 266.065613,810.082886 264.503754,813.849365 
	C261.269470,813.849365 257.797699,813.849365 253.658310,813.849365 
	C260.529724,798.196228 267.264832,782.853577 274.016418,767.115723 
M274.480347,791.905701 
	C280.499573,791.905701 286.518768,791.905701 293.091278,791.905701 
	C289.912567,784.874390 286.968872,778.362854 283.458282,770.597412 
	C280.094666,778.253479 277.277618,784.665588 274.480347,791.905701 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M493.714813,768.778198 
	C499.200439,761.288513 506.147064,757.117493 515.164307,757.029968 
	C528.141479,756.903931 529.882935,757.489136 538.135681,763.690613 
	C537.136169,765.245483 536.430420,767.270142 535.005432,768.287659 
	C533.700317,769.219666 531.144470,769.916016 529.953308,769.253479 
	C519.801086,763.606628 509.238190,765.851257 502.813934,773.673462 
	C497.655365,779.954529 497.900940,786.962036 500.505920,793.890137 
	C503.123077,800.850586 508.676483,804.866455 516.161133,804.864929 
	C521.055420,804.863953 525.949402,803.566223 531.023804,802.822876 
	C531.023804,796.657593 531.023804,791.548218 531.023804,786.223694 
	C534.405090,786.223694 537.474792,786.223694 540.999695,786.223694 
	C540.999695,792.096558 541.397034,797.916870 540.796997,803.632568 
	C540.568420,805.810181 538.494019,808.493958 536.505371,809.673950 
	C522.988037,817.695068 505.029999,817.149353 494.458038,803.639099 
	C491.485687,799.840637 489.837646,794.402344 489.219116,789.509277 
	C488.327454,782.455688 488.178070,775.049744 493.714813,768.778198 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M739.187256,813.967529 
	C738.489136,813.692932 738.078247,813.446777 738.077820,813.199951 
	C738.047974,794.932434 738.050537,776.664795 738.050537,757.529602 
	C748.420898,758.033569 758.467651,758.078979 768.402100,759.158386 
	C775.163269,759.893005 779.634460,764.371277 781.605347,771.077820 
	C783.916565,778.942444 781.828979,790.067322 776.066772,793.073669 
	C770.960388,795.737549 764.889465,796.748962 759.124756,797.880005 
	C755.764709,798.539246 752.169617,798.000000 748.000000,798.000000 
	C748.000000,800.671936 748.000000,803.257568 748.000000,805.843140 
	C748.000000,808.332642 748.000000,810.822144 748.000000,813.995728 
	C745.161255,813.995728 742.413696,813.995728 739.187256,813.967529 
M748.000000,779.477295 
	C748.000000,782.571838 748.000000,785.666443 748.000000,789.451599 
	C753.733643,788.933838 758.891968,788.419617 764.058960,788.015015 
	C769.097412,787.620483 771.314636,784.303467 771.929993,779.922119 
	C772.865112,773.264160 771.056335,769.167542 766.018005,768.175293 
	C760.211365,767.031738 754.168518,767.087891 748.000000,766.605164 
	C748.000000,771.235413 748.000000,774.864685 748.000000,779.477295 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M689.653687,800.150574 
	C694.507751,805.023071 700.116394,806.269714 705.390015,804.185303 
	C711.722290,801.682434 713.956726,796.747681 713.986389,790.433105 
	C714.036255,779.801392 714.000000,769.169250 714.000000,758.269592 
	C717.313110,758.269592 720.385559,758.269592 724.713806,758.269592 
	C723.963013,772.211243 724.200989,786.288757 722.099060,800.007751 
	C720.801453,808.476868 713.511047,812.702515 704.518555,814.204041 
	C694.929443,815.805298 687.472961,812.919250 681.657104,805.902893 
	C679.246155,802.994446 677.376709,798.755798 677.206848,795.033264 
	C676.654175,782.918518 677.000000,770.762817 677.000000,758.311890 
	C679.901184,758.311890 682.640259,758.311890 686.000000,758.311890 
	C686.000000,766.892273 685.609558,775.674500 686.146851,784.399597 
	C686.467712,789.609863 688.242981,794.730530 689.653687,800.150574 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M321.642029,759.200073 
	C325.419525,768.276794 328.863342,777.064514 332.389313,785.819214 
	C333.925201,789.632690 335.629639,793.378357 337.863800,798.561279 
	C343.786560,784.238770 349.165497,771.231323 354.549744,758.211121 
	C357.959412,758.211121 361.404205,758.211121 365.656250,758.211121 
	C362.687408,765.611389 359.863342,772.544861 357.121643,779.510803 
	C353.371979,789.037659 349.648163,798.575134 345.985840,808.135864 
	C344.031921,813.236694 338.003113,816.186890 333.086578,813.830078 
	C331.657471,813.145081 330.759033,810.895569 330.076965,809.180481 
	C323.399933,792.391052 316.820251,775.562988 309.806549,757.711609 
	C313.903748,758.132935 317.622742,758.515320 321.642029,759.200073 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M182.979614,758.000000 
	C186.788620,758.000000 190.100708,758.000000 193.705475,758.000000 
	C193.705475,760.935120 193.705475,763.674255 193.705475,766.814087 
	C188.412369,766.814087 182.997009,766.814087 177.186462,766.814087 
	C177.186462,782.604126 177.186462,798.010681 177.186462,813.706848 
	C173.732758,813.706848 170.657745,813.706848 167.184143,813.706848 
	C167.184143,798.403381 167.184143,782.992859 167.184143,767.183105 
	C161.397964,767.183105 155.986008,767.183105 150.290619,767.183105 
	C150.290619,764.057983 150.290619,761.318787 150.290619,758.000000 
	C160.950027,758.000000 171.716385,758.000000 182.979614,758.000000 
z"
      />
      <path
        fill="none"
        opacity="1.000000"
        stroke="none"
        d="
M425.000000,806.932922 
	C425.000000,790.470032 425.000000,774.500427 425.000000,758.266113 
	C428.323273,758.266113 431.395782,758.266113 434.868713,758.266113 
	C434.868713,773.652161 434.868713,789.062988 434.868713,804.870789 
	C443.328278,804.870789 451.404816,804.870789 459.737976,804.870789 
	C459.737976,807.997986 459.737976,810.735840 459.737976,813.740112 
	C448.343719,813.740112 436.935211,813.740112 425.000000,813.740112 
	C425.000000,811.636353 425.000000,809.531311 425.000000,806.932922 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M723.770935,343.595093 
	C719.634338,357.317108 702.650696,366.421234 689.120422,363.299286 
	C680.279297,361.259308 673.474365,357.466797 668.697571,350.030579 
	C667.920349,348.820740 667.824341,346.326477 668.556274,345.118103 
	C686.616516,315.301880 704.827026,285.576630 723.036865,255.851135 
	C723.614929,254.907516 724.469421,254.133240 725.781067,252.591003 
	C725.879700,254.549637 725.991882,255.744156 725.992371,256.938690 
	C726.002502,282.076355 726.080811,307.214569 725.909912,332.351074 
	C725.885193,335.985504 724.651550,339.611664 723.770935,343.595093 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M696.735413,239.697144 
	C685.693848,257.621979 674.881897,275.248291 664.069946,292.874634 
	C663.713318,292.783966 663.356628,292.693268 663.000000,292.602600 
	C663.000000,291.219788 663.000000,289.837006 663.000000,288.454224 
	C663.000000,264.301086 662.685730,240.142303 663.117981,215.996902 
	C663.376709,201.539032 670.522156,189.315598 685.679199,184.658264 
	C698.453064,180.733170 714.702087,186.480042 719.710938,196.345459 
	C720.422058,197.746033 720.614136,200.170700 719.872742,201.430710 
	C712.376953,214.169159 704.636658,226.763702 696.735413,239.697144 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M340.028381,336.046448 
	C338.879425,349.338806 329.497040,360.138519 317.390106,363.033905 
	C302.790649,366.525299 289.256683,359.998322 282.864532,349.216217 
	C282.329895,348.314392 282.553284,346.448975 283.146393,345.467499 
	C301.086487,315.780396 319.111755,286.144714 337.150696,256.517426 
	C338.050415,255.039719 339.191864,253.709167 341.000000,251.250595 
	C341.000000,253.753006 341.000000,255.117615 341.000000,256.482239 
	C341.000000,281.114929 341.019104,305.747650 340.959198,330.380188 
	C340.954987,332.120575 340.368408,333.859558 340.028381,336.046448 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M318.158020,184.630676 
	C325.631683,186.495209 330.872620,190.887909 334.830719,196.770355 
	C335.598358,197.911179 335.295837,200.526016 334.490906,201.859802 
	C318.059113,229.087906 301.495270,256.236420 284.916901,283.375885 
	C282.994629,286.522766 280.831329,289.522400 278.365936,293.209167 
	C278.205750,291.669189 278.013641,290.680176 278.013000,289.691010 
	C277.996521,264.220032 277.622681,238.741348 278.143860,213.281036 
	C278.448456,198.402740 288.869751,185.517700 304.472198,184.079071 
	C308.889587,183.671768 313.395294,184.222229 318.158020,184.630676 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M493.256287,315.247101 
	C506.280762,294.016510 519.040588,273.053009 531.800354,252.089508 
	C532.127197,252.177277 532.454041,252.265060 532.780945,252.352844 
	C532.853943,253.538589 532.990051,254.724319 532.990723,255.910095 
	C533.003235,279.373810 533.209900,302.840302 532.895264,326.299805 
	C532.771729,335.511932 531.049377,344.388123 525.389526,352.378754 
	C518.618896,361.937653 505.297455,365.208954 496.589874,363.732727 
	C487.980194,362.273071 480.231934,358.675873 475.528961,350.826935 
	C474.542053,349.179901 474.496704,345.978943 475.452148,344.320679 
	C481.062866,334.582672 487.098938,325.089722 493.256287,315.247101 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M515.997192,218.038376 
	C500.622040,243.511414 485.280090,268.610321 469.374115,294.631927 
	C469.188416,292.659302 469.012604,291.658722 469.011963,290.657990 
	C468.996674,266.160797 468.598419,241.654602 469.146149,217.169647 
	C469.491180,201.746353 476.914978,189.023438 491.955536,184.578415 
	C505.266937,180.644455 519.444214,185.500778 526.342346,196.921173 
	C526.926941,197.888977 527.325378,199.656250 526.838684,200.473862 
	C523.377808,206.288422 519.663818,211.952332 515.997192,218.038376 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M224.883606,767.000000 
	C226.853592,767.000244 228.353821,766.897949 229.835831,767.018860 
	C235.628128,767.491455 237.768799,770.188782 238.062180,776.233704 
	C238.324722,781.642944 236.628357,785.347351 229.859024,785.908691 
	C224.452606,786.357117 218.979309,785.999207 213.268997,785.999207 
	C213.268997,779.701904 213.268997,773.652588 213.268997,767.000000 
	C216.914917,767.000000 220.660202,767.000000 224.883606,767.000000 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M568.072266,786.000000 
	C565.936523,786.000000 564.286377,786.000000 562.318481,786.000000 
	C562.318481,779.789062 562.318481,773.720337 562.318481,766.615906 
	C568.806030,767.068665 575.572388,766.860718 582.010132,768.215149 
	C586.770142,769.216675 588.342712,774.168640 586.952332,779.902710 
	C585.910461,784.199219 582.250305,785.446106 578.531311,785.913513 
	C575.254883,786.325256 571.885925,785.999878 568.072266,786.000000 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M629.405396,767.002686 
	C639.717407,764.173157 646.785095,766.401855 651.836975,773.749023 
	C658.123230,782.891357 657.169128,791.009827 651.233093,798.129822 
	C645.682922,804.786926 636.749084,807.310913 630.077209,804.531982 
	C623.000183,801.584106 617.881287,796.562622 617.077515,788.610657 
	C616.091309,778.855042 620.330139,771.626770 629.405396,767.002686 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M274.470459,791.491699 
	C277.277618,784.665588 280.094666,778.253479 283.458282,770.597412 
	C286.968872,778.362854 289.912567,784.874390 293.091278,791.905701 
	C286.518768,791.905701 280.499573,791.905701 274.470459,791.491699 
z"
      />
      <path
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
        d="
M748.000000,778.985657 
	C748.000000,774.864685 748.000000,771.235413 748.000000,766.605164 
	C754.168518,767.087891 760.211365,767.031738 766.018005,768.175293 
	C771.056335,769.167542 772.865112,773.264160 771.929993,779.922119 
	C771.314636,784.303467 769.097412,787.620483 764.058960,788.015015 
	C758.891968,788.419617 753.733643,788.933838 748.000000,789.451599 
	C748.000000,785.666443 748.000000,782.571838 748.000000,778.985657 
z"
      />
    </SVGIcon>
  );
};

export default ThousandMilesIcon;
