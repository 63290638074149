import { useEffect, useState } from "react";

export const AriaLivePageTitle = () => {
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (document.title !== title) setTitle(document.title);
  }, [document.title]);
  return (
    <div className="visually-hidden" aria-live="polite">
      <span>{title}</span>
    </div>
  );
};
