import SVGIcon from "src/components/SVGIcon";

const Satellite = ({ title = "Satellite", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 132.1 131.1" title={title} {...props}>
      <path
        className="ctm-default-text-color"
        d="M52.7,76.8c0.4,0.4,1.2,0.4,1.6,0l3.7-3.7l3,2.9L50.4,86.5c-0.4,0.4-0.4,1.2,0,1.6l2.6,2.6l-2.8,2.8
	c-3.4-0.8-7,0.2-9.4,2.6l-0.6,0.6c-0.4,0.4-0.4,1.2,0,1.6l5.4,5.4l-1.1,1.1c-0.6-0.3-1.2-0.5-1.9-0.5c-2.2,0-4,1.8-4,4s1.8,4,4,4
	s4-1.8,4-4c0-0.7-0.2-1.3-0.5-1.9l1.1-1.1l5.4,5.4c0.4,0.4,1.2,0.4,1.6,0l0.6-0.6c2.5-2.5,3.4-6,2.6-9.4l2.8-2.8l2.6,2.6
	c0.4,0.4,1.2,0.4,1.6,0L74.9,90l2.8,2.8L74,96.5c-0.4,0.4-0.4,1.2,0,1.6L89.9,114c0.4,0.4,1.2,0.4,1.6,0l12.5-12.5
	c0.4-0.4,0.4-1.2,0-1.6L88.3,84.2c-0.4-0.4-1.2-0.4-1.6,0L83,87.9l-2.8-2.8l9.4-9.4c0.4-0.4,0.4-1.2,0-1.6l-1.8-1.8l0.8-3.3l3.9-1
	h0.1h0.1c0.1-0.1,0.2-0.1,0.3-0.2l0,0l4.5-4.5c0.4-0.4,0.4-1.2,0-1.6l-0.9-0.9l2-2c1.7-1.7,1.7-4.4,0-6.1c-1.7-1.7-4.4-1.7-6.1,0
	l-2,2l-0.9-0.9c-0.4-0.4-1.2-0.4-1.6,0l-4.5,4.5l0,0c-0.1,0.1-0.2,0.2-0.2,0.3v0.1v0.1l-1,3.9L79,63.5l-2-1.9
	c-0.4-0.4-1.2-0.4-1.6,0L66,71l-2.9-3l3.7-3.7c0.4-0.4,0.4-1.2,0-1.6L50.9,46.8c-0.4-0.4-1.2-0.4-1.6,0L36.8,59.3
	c-0.4,0.4-0.4,1.2,0,1.6L52.7,76.8z M42.7,110c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7
	C44.4,109.3,43.7,110,42.7,110z M53.4,61.8l4.6-4.6l2.4,2.4l-4.6,4.6L53.4,61.8z M54.2,65.8l-4.6,4.6L47.2,68l4.6-4.6L54.2,65.8z
	 M51.8,60.2l-2.4-2.4l4.6-4.6l2.4,2.4L51.8,60.2z M50.2,61.8l-4.6,4.6L43.2,64l4.6-4.6L50.2,61.8z M53.5,74.4L51.2,72l4.6-4.6
	l2.4,2.4l-0.9,0.9L53.5,74.4z M54.7,92.4l1.2,1.2L54,95.4c-0.5-0.4-1-0.7-1.5-1L54.7,92.4z M53.5,108.4L42.6,97.6
	c3.1-2.9,8-2.8,10.9,0.3C56.3,100.9,56.3,105.5,53.5,108.4z M56.6,98.5c-0.3-0.5-0.6-1-1-1.5l1.8-1.8l1.2,1.2L56.6,98.5z M80,94.1
	c0.2-0.1,0.3-0.1,0.5-0.3l0.9-0.9l2.3,2.3L79,99.8l-2.4-2.4L80,94.1z M90.8,99.2l4.6-4.6l2.4,2.4l-4.6,4.6L90.8,99.2z M91.5,103.1
	l-4.6,4.6l-2.4-2.4l4.6-4.6L91.5,103.1z M89.2,97.6l-2.4-2.4l4.6-4.6l2.4,2.4L89.2,97.6z M87.6,99.2l-4.6,4.6l-2.4-2.4l4.6-4.6
	L87.6,99.2z M90.9,111.7l-2.4-2.4l4.6-4.6l2.4,2.4L90.9,111.7z M97.1,105.5l-2.4-2.4l4.6-4.6l2.4,2.4L97.1,105.5z M87.5,86.6
	l2.4,2.4l-4.6,4.6L83,91.3l0.9-0.9c0.1-0.1,0.2-0.3,0.3-0.5L87.5,86.6z M81.3,89.5l-1.8,1.8l-2.8-2.8l1.8-1.8L81.3,89.5z M94,54.1
	c0.8-0.8,2.1-0.8,2.9,0.1c0.8,0.8,0.8,2.1,0,2.9l-2,2l-2.9-3L94,54.1z M88.7,56l6.3,6.3l-2.9,2.9L85.8,59L88.7,56z M83.3,64.6
	c0.4-0.1,0.7-0.4,0.8-0.8l0.6-2.6l5.1,5.1l-2.6,0.6c-0.4,0.1-0.7,0.4-0.8,0.8l-0.6,2.6l-5.1-5.1L83.3,64.6z M76.2,64l1.5,1.5
	l7.9,7.9l1.5,1.5l-9.4,9.4l-3.4,3.4L63.7,98.2L52.9,87.3l10.5-10.5l3.4-3.4L76.2,64z M64.4,72.6l-1.8,1.8l-2.9-2.9l1.8-1.8
	L64.4,72.6z M60.7,67.2l-0.9,0.9l-2.4-2.4l4.6-4.6l2.4,2.4L60.7,67.2z M50.1,49.2l2.4,2.4l-4.6,4.6l-2.4-2.4L50.1,49.2z M43.9,55.5
	l2.4,2.4l-4.6,4.6l-2.4-2.4L43.9,55.5z"
      />
      <g>
        <g>
          <path
            className="ctm-primary-color"
            d="M77.3,17l-3.1,5.8l-5.7,3.1l5.7,3.1l3.1,5.8l3.1-5.8l5.7-3.1l-5.7-3.1L77.3,17z M96.8,13.5L95.4,16l-2.5,1.4
			l2.5,1.4l1.4,2.6l1.4-2.6l2.5-1.4L98.2,16L96.8,13.5z M121.5,80l-2.2-4.1l-2.2,4.1l-4,2.2l4,2.2l2.2,4.1l2.2-4.1l4-2.2L121.5,80z
			 M12.3,67.9L10.1,72l-4,2.2l4,2.2l2.2,4.1l2.2-4.1l4-2.2l-4-2.2L12.3,67.9z"
          />
        </g>
      </g>
    </SVGIcon>
  );
};

export default Satellite;
