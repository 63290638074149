import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { NEWS } from "src/common/constants";
import { handleErrors } from "src/common";

export const fetchData = () => {
  const url = getConfig(NEWS).url;
  if (url) {
    return get({ url })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${NEWS} url`));
  }
};

export const getReadNewsAlerts = () => {
  const alerts = window.localStorage.getItem("readNewsAlerts");
  return alerts ? JSON.parse(alerts) : [];
};

export const setReadNewsAlerts = (alert) => {
  const readAlerts = getReadNewsAlerts();
  readAlerts.push(alert);
  window.localStorage.setItem("readNewsAlerts", JSON.stringify(readAlerts));
};
