import { UNUSED_TICKETS } from "src/common/constants";
export const UNUSED_TICKETS_FETCH_DATA = `${UNUSED_TICKETS}_GET_DATA`;
export const UNUSED_TICKETS_FETCH_INVOKED = `${UNUSED_TICKETS}_GET_INVOKED`;
export const UNUSED_TICKETS_WITH_AIRLINES_FETCH_SUCCESS = `${UNUSED_TICKETS}_WITH_AIRLINES_GET_SUCCESS`;
export const UNUSED_TICKETS_FETCH_SUCCESS = `${UNUSED_TICKETS}_GET_SUCCESS`;
export const UNUSED_TICKETS_FETCH_FAILED = `${UNUSED_TICKETS}_GET_FAILED`;

export const fetchUnusedTickets = (params) => ({
  type: UNUSED_TICKETS_FETCH_DATA,
  params,
});

export const fetchUnusedTicketsInvoked = () => ({
  type: UNUSED_TICKETS_FETCH_INVOKED,
});
export const fetchUnusedTicketsSuccess = (payload) => ({
  type: UNUSED_TICKETS_FETCH_SUCCESS,
  payload,
});

export const fetchUnusedTicketsWithAirlinesSuccess = (payload) => ({
  type: UNUSED_TICKETS_WITH_AIRLINES_FETCH_SUCCESS,
  payload,
});

export const fetchUnusedTicketsFailed = (error) => ({
  type: UNUSED_TICKETS_FETCH_FAILED,
  error,
});
