import { useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QUERYKEY_CHECK_IMPERSONATION } from "./queryKeys";
import {
  isImpersonated,
  logoutImpersonation,
} from "src/services/impersonation";
import { minutesToMilliseconds } from "../helpers";
import { useDispatch } from "react-redux";
import {
  FETCH_APPLICATION_DEPENDENCIES,
  IMPERSONATION_SET_IMPERSONATOR,
  IMPERSONATION_SET_TIMED_OUT,
  IMPERSONATION_SET_TIMING_OUT,
} from "src/redux/actions";
import { IMPERSONATION_ID, IMPERSONATION_TOKEN } from "../constants";
import { decrementUserSession } from "src/services/logout";
import { removeScoutFromPage } from "src/services/scout";

const CHECK_IMPERSONATION_INTERVAL = minutesToMilliseconds(4);
const IMPERSONATION_TIMEOUT = minutesToMilliseconds(14);
const IMPERSONATION_FINAL_TIMEOUT = minutesToMilliseconds(1);

export function useCheckImpersonation(isImpersonating, loading) {
  return useQuery({
    queryKey: [QUERYKEY_CHECK_IMPERSONATION],
    refetchInterval: CHECK_IMPERSONATION_INTERVAL,
    queryFn: isImpersonated,
    enabled: Boolean(!isImpersonating && !loading),
  });
}

export function useImpersonation() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const impersonationTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const impersonationFinalTimeout = useRef<ReturnType<
    typeof setTimeout
  > | null>(null);

  function navToDashboard() {
    if (location.pathname !== "/") {
      history.push("/");
    }
  }

  const initializeImpersonation = ({ email, id, token }) => {
    removeScoutFromPage();
    // Make sure we are on the dashboard when switching
    navToDashboard();
    // Set Impersonator Redux State
    dispatch({ type: IMPERSONATION_SET_IMPERSONATOR, payload: email });
    // Setup timer to begin logout process in 14 minutes
    impersonationTimeout.current = setTimeout(
      impersonationTimeoutHandler,
      IMPERSONATION_TIMEOUT
    );
    // Add event listeners to reset logout timers, clear impersonation session on app close
    addImpersonationEventListeners();
    // Add our impersonation data to session storage
    sessionStorage.setItem(IMPERSONATION_ID, id);
    sessionStorage.setItem(IMPERSONATION_TOKEN, token);
    // Restart the application which will fetch with our newly added session storage token
    dispatch({ type: FETCH_APPLICATION_DEPENDENCIES });
  };

  const clearImpersonation = () => {
    // Decrement impersonated user session
    decrementUserSession();
    removeScoutFromPage();
    // Make sure we are on the dashboard when switching
    navToDashboard();
    // Clear redux impersonator state
    dispatch({ type: IMPERSONATION_SET_IMPERSONATOR });
    // Clear our B/E impersonation session
    logoutImpersonation();
    // Clear event listeners, timers, session storage
    removeImpersonationEventListeners();
    clearTimeout(impersonationTimeout.current);
    clearTimeout(impersonationFinalTimeout.current);
    sessionStorage.removeItem(IMPERSONATION_ID);
    sessionStorage.removeItem(IMPERSONATION_TOKEN);
    // Restart application which will fetch everything based on the logged in user's jwt
    dispatch({ type: FETCH_APPLICATION_DEPENDENCIES });
  };

  const impersonationTimeoutHandler = () => {
    dispatch({ type: IMPERSONATION_SET_TIMING_OUT, payload: true });
    impersonationFinalTimeout.current = setTimeout(
      impersonationFinalTimeoutHandler,
      IMPERSONATION_FINAL_TIMEOUT
    );
  };

  const impersonationFinalTimeoutHandler = () => {
    dispatch({ type: IMPERSONATION_SET_TIMED_OUT, payload: true });
    clearImpersonation();
  };

  const addImpersonationEventListeners = () => {
    window.addEventListener("click", resetTimers);
    window.addEventListener("visibilitychange", handleVisibilityChange);
  };

  const removeImpersonationEventListeners = () => {
    window.removeEventListener("click", resetTimers);
    window.removeEventListener("visibilitychange", handleVisibilityChange);
  };

  const resetTimers = useCallback(() => {
    clearTimeout(impersonationTimeout.current);
    clearTimeout(impersonationFinalTimeout.current);
    impersonationTimeout.current = setTimeout(
      impersonationTimeoutHandler,
      IMPERSONATION_TIMEOUT
    );
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState !== "visible") {
      impersonationFinalTimeoutHandler();
    }
  }, []);

  return {
    initializeImpersonation,
    clearImpersonation,
  };
}
