import { useEffect, useCallback, useRef } from "react";
import { Route, Redirect } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Loader, Dimmer } from "semantic-ui-react";
import { ACCESS_TOKEN, BYPASS } from "src/common/Auth/auth0-variables";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const userState = rest?.userToken?.state;

  const storedAccessToken = localStorage.getItem(ACCESS_TOKEN);
  const useStoredAccessToken =
    Boolean(localStorage.getItem(BYPASS)) && Boolean(storedAccessToken);
  const isLoggedIn = useStoredAccessToken || isAuthenticated;

  const asyncScriptsLoaded = useRef(false);

  const setBeamer = useCallback(() => {
    const roles = user["https://www.ctmsmart.com/roles"] || [];

    // Condense our roles into a string that Beamer can use to filter. String should look like: traveler;clientmanager
    const roleString = roles.reduce((acc, curr) => {
      let newStr;

      switch (curr.code) {
        case "TA":
          newStr = "travelarranger";
          break;
        case "CM":
          newStr = "clientmanager";
          break;
        case "T":
          newStr = "traveler";
          break;
        default:
          break;
      }

      if (newStr) {
        return acc.length ? `${acc};${newStr}` : newStr;
      } else return acc;
    }, "");

    const fullNameArray = (
      user["https://www.ctmsmart.com/fullName"] || ""
    ).split(" ");
    let firstName, lastName;
    if (fullNameArray.length === 2) {
      firstName = fullNameArray[0];
      lastName = fullNameArray[1];
    }

    // Beamer config needs to be global for the beamer script to access it
    // @ts-ignore
    window.beamer_config = {
      product_id: "iIFQQPdX51905",
      // Allows messages to be filtered by role
      filter: roleString,
      user_email: user["https://www.ctmsmart.com/email"],
      // Allows messages to be filtered by debtor
      user_id:
        user["https://www.ctmsmart.com/elysian/userid"] ||
        user["https://www.ctmsmart.com/email"],
      user_firstname: firstName,
      user_lastname: lastName,
      // 10px off the bottom
      bottom: 10,
      // Offsets the width of the scrollbar
      right: 25,
    };

    const script = document.createElement("script");
    script.src = "https://app.getbeamer.com/js/beamer-embed.js";
    script.defer = true;
    document.head.appendChild(script);
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && userState?.features) {
      if (!asyncScriptsLoaded.current) {
        setBeamer();
        asyncScriptsLoaded.current = true;
      }
    }
  }, [userState, isAuthenticated]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return (
            <Container fluid style={{ height: "100%" }}>
              <Dimmer active>
                <Loader>Please Wait</Loader>
              </Dimmer>
            </Container>
          );
        }
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
