import { TOOLS_SET_DATA } from "src/redux/actions/tools";

import { setToolsDefaults } from "src/common/shared/featureSettings";
import { WidgetI } from "src/types/Widget";

const tools = (state: WidgetI[] = [], action): WidgetI[] => {
  switch (action.type) {
    case TOOLS_SET_DATA:
      return setToolsDefaults(action.payload);
    default:
      return state;
  }
};

export default tools;
