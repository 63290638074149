export const IMPERSONATION_SET_IMPERSONATOR = "IMPERSONATION_SET_IMPERSONATOR";
export const IMPERSONATION_SET_TIMING_OUT = "IMPERSONATION_SET_TIMING_OUT";
export const IMPERSONATION_SET_TIMED_OUT = "IMPERSONATION_SET_TIMED_OUT";
export const IMPERSONATION_RESET = "IMPERSONATION_RESET";

export const setImpersonator = (payload) => ({
  type: IMPERSONATION_SET_IMPERSONATOR,
  payload,
});

export const setImpersonatorTimingOut = (payload) => ({
  type: IMPERSONATION_SET_TIMING_OUT,
  payload,
});

export const setImpersonatorTimedOut = (payload) => ({
  type: IMPERSONATION_SET_TIMED_OUT,
  payload,
});

export const resetImpersonatorState = () => ({
  type: IMPERSONATION_RESET,
});
