import { Button, Divider, Input } from "semantic-ui-react";
import { validateEmail } from "src/common";
import { useSafeSetState } from "src/common/hooks";
import { impersonateUser } from "src/services/impersonation";

const initialState = {
  email: "",
  loading: false,
  error: false,
};

const ImpersonationForm = ({
  isImpersonating,
  initializeImpersonation,
  clearImpersonation,
}) => {
  const [{ email, error, loading }, safeSetState] =
    useSafeSetState(initialState);

  const handleLogin = () => {
    safeSetState({ loading: true, error: false });
    impersonateUser(email)
      .then((r: any) => {
        safeSetState({
          loading: false,
          email,
        });
        initializeImpersonation({ id: r.id, token: r.token, email });
      })
      .catch(() => safeSetState({ loading: false, error: true }));
  };

  const handleLogout = () => {
    clearImpersonation();
  };

  if (isImpersonating) {
    return (
      <>
        <p>Impersonating User: {isImpersonating}</p>
        <Button onClick={handleLogout} color="green">
          Exit
        </Button>
        <Divider />
      </>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleLogin();
      }}
    >
      <Input
        placeholder="Impersonate User"
        fluid
        value={email}
        onChange={(e) => safeSetState({ email: e.target.value })}
        disabled={loading}
      />
      {error && (
        <p className="impersonate-error">
          Impersonate User is disabled for this account.
        </p>
      )}
      <Button
        className="impersonate-button"
        color="green"
        loading={loading}
        disabled={!validateEmail(email) || loading}
        type="submit"
      >
        Log In
      </Button>
      <Divider />
    </form>
  );
};

export default ImpersonationForm;
