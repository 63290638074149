import SVGIcon from "src/components/SVGIcon";

const FareForecasterIcon = ({ title = "Fare Forecaster", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 1200 1200" title={title} {...props}>
      <g>
        <path
          fill="currentColor"
          d="m189.6 414h-126c-18 0-32.398 14.398-32.398 32.398v690c0 18 14.398 32.398 32.398 32.398h126c18 0 32.398-14.398 32.398-32.398v-688.8c0-18-14.398-33.602-32.398-33.602z"
        />
        <path
          fill="currentColor"
          d="m505.2 608.4h-126c-18 0-32.398 14.398-32.398 32.398v495.6c0 18 14.398 32.398 32.398 32.398h126c18 0 32.398-14.398 32.398-32.398l0.003906-495.6c1.1992-18-14.402-32.402-32.402-32.402z"
        />
        <path
          fill="currentColor"
          d="m820.8 850.8h-126c-18 0-32.398 14.398-32.398 32.398v253.2c0 18 14.398 32.398 32.398 32.398h126c18 0 32.398-14.398 32.398-32.398v-253.2c1.1992-18-14.398-32.398-32.398-32.398z"
        />
        <path
          fill="currentColor"
          d="m1136.4 912h-126c-18 0-32.398 14.398-32.398 32.398v192c0 18 14.398 32.398 32.398 32.398h126c18 0 32.398-14.398 32.398-32.398v-192c1.2031-16.797-13.195-32.398-32.398-32.398z"
        />
        <path
          fill="currentColor"
          d="m770.4 661.2c-27.602 30-10.801 79.199 30 86.398l302.4 55.199c38.398 7.1992 70.801-28.801 60-67.199l-90-294c-12-39.602-62.398-50.398-88.801-19.199l-62.395 69.602-268.8-241.2c-26.398-24-67.199-22.801-91.199 3.6016l-84 86.398-330-294c-27.602-24-69.602-21.602-93.602 6-24 27.602-21.602 69.602 6 93.602l376.8 334.8c26.398 24 67.199 21.602 91.199-3.6016l84-86.398 220.8 198z"
        />
      </g>
    </SVGIcon>
  );
};

export default FareForecasterIcon;
