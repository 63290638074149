import SVGIcon from "src/components/SVGIcon";

const GetThereIcon = ({ title = "GetThere", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M18.797 5.125l6.528 4.494-6.332 4.166-1.312-1.181 1.804-1.673c0 0-1.968-0.262-5.118 0.853s-5.905 3.674-6.824 5.971 0.131 3.871 0.918 4.396 2.523 1.27 5.905 0.197c2.69-0.853 4.527-2.887 4.527-2.887h-3.871v-3.018h13.516v2.953h-4.79v7.48h-3.346v-4.462c0 0-3.215 3.018-7.611 3.74s-8.989-0.591-9.317-5.643 4.068-8.792 6.824-10.367c0.997-0.57 4.002-2.165 8.726-2.165l-1.509-1.575 1.279-1.279z"
      ></path>
    </SVGIcon>
  );
};

export default GetThereIcon;
