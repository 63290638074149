import {
  updateWidgetOrder,
  updateWidgetProps,
  UPDATE_WIDGET_ORDER_SAGA,
  UPDATE_WIDGET_PROPS_SAGA,
} from "src/redux/actions/widgets";

import { putWidgetOrder, putWidgetProperties } from "src/services/widget";
import { put, takeLatest, select } from "redux-saga/effects";

const getWidgets = (state) => {
  return state.widgets;
};

const getIsGlobalRiskMap = (state) => {
  return state.application.isGlobalRiskMap;
};

function* updateWidgetOrderSaga({ oldIndex, newIndex }) {
  if (oldIndex !== newIndex) {
    const widgets = yield select(getWidgets);
    const id = widgets[oldIndex].id;
    // Update redux state
    yield put(updateWidgetOrder(oldIndex, newIndex));
    // Save state update to the backend to save
    putWidgetOrder(id, newIndex + 1);
  }
}

function* updateWidgetPropsSaga({ index, props }) {
  // Update redux state
  yield put(updateWidgetProps(index, props));

  // We do not send backend puts for global error map users to save user prefs
  const isGlobalRiskMap = yield select(getIsGlobalRiskMap);
  if (isGlobalRiskMap) return;

  // Get the widget id to send to backend
  const widgets = yield select(getWidgets);
  const identifier = widgets[index].identifier;
  const id = widgets[index].id;
  // Save the widget property state to the backend
  putWidgetProperties(identifier, id, props);
}

function* widgetSaga() {
  yield takeLatest(UPDATE_WIDGET_ORDER_SAGA, updateWidgetOrderSaga);
  yield takeLatest(UPDATE_WIDGET_PROPS_SAGA, updateWidgetPropsSaga);
}

export default widgetSaga;
