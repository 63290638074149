import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { AIRLINES } from "src/common/constants";
import { handleErrors } from "src/common";

const url = getConfig(AIRLINES).url;

export const fetchAirline = (params) => {
  if (url) {
    return get({ url, params })
      .then(handleErrors)
      .then((res) => res.json())
      .catch(() => {
        return Promise.reject(new Error("Error fetching airlines"));
      });
  } else {
    return Promise.reject(new Error(`Missing ${AIRLINES} url`));
  }
};

export const fetchAirlines = () => {
  if (url) {
    const finalUrl = `${url}/GetAll`;
    return get({ url: finalUrl, params: { width: 25 } })
      .then(handleErrors)
      .then((res) => res.json())
      .then(mapAirlineData)
      .catch(() => {
        return Promise.reject(new Error("Error fetching all airlines"));
      });
  } else {
    return Promise.reject(new Error(`Missing ${AIRLINES} url`));
  }
};

function mapAirlineData(airlines) {
  try {
    const airlineData = {
      airlineCodes: {},
    };
    airlines.forEach((airline) => {
      if (airline.Image || airline.SecondaryImage) {
        airlineData.airlineCodes[airline.Code] = {
          Image: airline.Image,
          SecondaryImage: airline.SecondaryImage,
          Name: airline.Name,
        };
      }
    });
    return airlineData;
  } catch (e) {
    throw new Error();
  }
}
