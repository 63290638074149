import ThousandMileIcon from "src/icons/ThousandMileIcon";
import DataHubIcon from "src/icons/DataHubIcon";
import FareForecasterIcon from "src/icons/FareForecasterIcon";
import ProfilesIcon from "src/icons/ProfilesIcon";
import TravelerTrackerIcon from "src/icons/TravelerTrackerIcon";
import {
  CTM_APPROVE_ROUTE,
  DOCS_AND_LINKS_ROUTE,
  TRAVELER_TRACKER_ROUTE,
  FARE_FORECASTER_ROUTE,
  CTM_INVOICES_ROUTE,
} from "src/pages/Routes";
import AirplaneGlobeIcon from "src/icons/AirplaneGlobeIcon";
import LightningIcon from "src/icons/LightningLogo";
import GetThereIcon from "src/icons/GetThereIcon";
import TramadaIcon from "src/icons/TramadaIcon";
import SwiftDataIcon from "src/icons/SwiftDataIcon";
import DeemIcon from "src/icons/DeemIcon";
import ConcurIcon from "src/icons/ConcurIcon";
import GroupTrackIcon from "src/icons/GroupTrackIcon";
import AllStateIcon from "src/icons/AllstateIcon";
import CloudDownloadIcon from "src/icons/CloudDownloadIcon";
import ApproveIcon from "src/icons/ApproveIcon";
import InvoicesIcon from "src/icons/InvoicesIcon";

const widgetSettings = {
  Elysium_Clocks_Widget: {
    properties: {
      isVisible: true,
      colspan: 1,
      clocks: ["America/Los_Angeles", "America/Chicago", "America/New_York"],
    },
  },
  Elysium_BI_Widget: {
    properties: {
      isVisible: true,
      colspan: 3,
    },
  },
  Elysium_Fare_Forecaster_Widget: {
    properties: {
      isVisible: true,
      colspan: 2,
    },
  },
  Elysium_Flights_Widget: {
    properties: {
      isVisible: true,
      colspan: 1,
    },
  },
  Elysium_News_Widget: {
    properties: {
      isVisible: true,
      colspan: 1,
    },
  },
  Elysium_Traveler_Tracker_Widget: {
    properties: {
      isVisible: true,
      colspan: 3,
      timeFrames: {
        previous: 1,
        upcoming: 3,
      },
      displayRiskMessages: false,
      displayGeorisk: false,
    },
  },
  Elysium_Currency_Converter_Widget: {
    properties: {
      isVisible: true,
      colspan: 1,
    },
  },
  ut: {
    properties: {
      isVisible: true,
      colspan: 2,
    },
  },
  invoices: {
    properties: {
      isVisible: true,
      colspan: 2,
    },
  },
  "travel-requirements": {
    properties: {
      isVisible: true,
      colspan: 2,
    },
  },
  approve: {
    properties: {
      isVisible: true,
      colspan: 2,
    },
  },
  provisional_traveler: {
    properties: {
      isVisible: true,
      colspan: 2,
    },
  },
};

export function setWidgetDefaults(widgets) {
  return widgets
    .filter((widget) => widgetSettings[widget.identifier])
    .map((widget) => {
      const newWidget = { ...widget };
      if (!newWidget.properties)
        newWidget.properties = widgetSettings[newWidget.identifier].properties;
      return newWidget;
    });
}

// Let's start moving this way and get rid of icomoon, inline svgs are better
const leftNavSvgComponentIcons = {
  "ctm-smartdata-sso": DataHubIcon,
  Umbrella_Faces: ProfilesIcon,
  "ctm-thousand-mile": ThousandMileIcon,
  Tramada: AirplaneGlobeIcon,
  Lightning: LightningIcon,
  GetThere: GetThereIcon,
  "tramada-sso": TramadaIcon,
  "swiftdata-sso": SwiftDataIcon,
  "deem-sso": DeemIcon,
  Concur: ConcurIcon,
  "concur-saml-sso": ConcurIcon,
  "grouptrack-sso": GroupTrackIcon,
  "policy-question": AllStateIcon,
};

// Allows us to customize user app icons to be different from left nav icons

export function setSsoDefaults(ssoLinks) {
  return ssoLinks.map((ssoLink) => {
    const newLink = { ...ssoLink };
    newLink.component = leftNavSvgComponentIcons[newLink.identifier];
    return newLink;
  });
}

const toolsSettings = {
  Elysium_Traveler_Tracker_Widget: {
    href: `/${TRAVELER_TRACKER_ROUTE}`,
    component: TravelerTrackerIcon,
  },
  Elysium_Fare_Forecaster_Widget: {
    href: `/${FARE_FORECASTER_ROUTE}`,
    component: FareForecasterIcon,
  },
  Elysium_Docs_And_Link: {
    component: CloudDownloadIcon,
    href: `/${DOCS_AND_LINKS_ROUTE}`,
  },
  approve: {
    href: `/${CTM_APPROVE_ROUTE}`,
    component: ApproveIcon,
  },
  ctminvoices: {
    component: InvoicesIcon,
    href: `/${CTM_INVOICES_ROUTE}`,
  },
};

export function setToolsDefaults(tools) {
  const result = tools.map((tool) => ({
    ...tool,
    label: tool.name,
    ...toolsSettings[tool.identifier],
  }));
  return result;
}

export function setLinksDefaults(links) {
  return links.map((link) => ({
    ...link,
    isDownloadable: link.type === "Document",
  }));
}
