import SVGIcon from "src/components/SVGIcon";

const GroupTrackIcon = ({ title = "GroupTrack", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 32 32" title={title} {...props}>
      <path
        fill="currentColor"
        d="M29.909 16.224c-0.158 0.468-0.501 0.609-0.974 0.639-1.623 0.107-3.244 0.121-4.867-0.005-0.124-0.009-0.248-0.016-0.371-0.036-0.344-0.054-0.597-0.31-0.602-0.654-0.011-0.745-0.025-1.492 0.007-2.237 0.035-0.804 0.713-1.63 1.843-1.672 0.063-0.002 0.165 0.094 0.188 0.164 0.212 0.627 0.41 1.259 0.614 1.889 0.026 0.081 0.063 0.158 0.094 0.236 0.027-0.002 0.055-0.003 0.082-0.005 0.037-0.219 0.081-0.438 0.11-0.659 0.072-0.541 0.069-0.542 0.609-0.538 0.081 0.001 0.163 0.008 0.259 0.012 0.064 0.414 0.126 0.817 0.189 1.219 0.023 0.004 0.046 0.008 0.070 0.012 0.030-0.077 0.065-0.152 0.090-0.23 0.201-0.623 0.407-1.243 0.594-1.87 0.059-0.197 0.141-0.258 0.349-0.223 0.946 0.158 1.465 0.634 1.67 1.556 0.009 0.039 0.031 0.076 0.047 0.113v2.289z"
      />
      <path
        fill="currentColor"
        d="M16.323 0.299c0.129 0.117 0.256 0.237 0.389 0.351 0.034 0.029 0.085 0.055 0.128 0.055 0.617 0.002 0.87 0.485 0.963 0.931 0.052 0.249 0.063 0.511 0.057 0.766-0.021 0.924 0.081 1.807 0.773 2.526-0.501 0.31-1.007 0.476-1.545 0.537-0.177 0.020-0.236 0.061-0.296 0.257-0.121 0.4-0.331 0.773-0.507 1.156-0.058 0.127-0.124 0.25-0.22 0.444-0.285-0.63-0.545-1.207-0.827-1.83-0.145-0.008-0.361-0.002-0.57-0.037-0.218-0.037-0.435-0.099-0.643-0.176-0.198-0.074-0.384-0.181-0.614-0.292 0.778-0.654 0.745-1.522 0.781-2.371 0.020-0.49 0.076-0.974 0.31-1.418 0.214-0.406 0.522-0.701 0.987-0.802 0.082-0.018 0.158-0.064 0.237-0.097 0.199 0 0.398 0 0.597 0z"
      />
      <path
        fill="currentColor"
        d="M2.19 14.93c0.145-0.323 0.275-0.662 0.611-0.84 0.195-0.104 0.407-0.179 0.614-0.258 0.269-0.102 0.54-0.196 0.875-0.316-0.136 0.327-0.25 0.601-0.384 0.921 0.243 0 0.446 0.009 0.648-0.003 0.163-0.010 0.254 0.040 0.32 0.199 0.133 0.32 0.288 0.632 0.441 0.943 0.037 0.076 0.105 0.137 0.159 0.205 0.054-0.066 0.124-0.124 0.16-0.2 0.145-0.298 0.287-0.597 0.414-0.902 0.077-0.185 0.186-0.27 0.394-0.248 0.18 0.019 0.362 0.004 0.591 0.004-0.13-0.31-0.247-0.587-0.386-0.917 0.272 0.089 0.504 0.152 0.726 0.24 0.35 0.138 0.735 0.204 1.010 0.505 0.216 0.237 0.347 0.504 0.352 0.823 0.006 0.439 0.004 0.879 0.001 1.318-0.003 0.329-0.122 0.442-0.454 0.442-1.857 0.001-3.714-0.003-5.571 0.005-0.241 0.001-0.42-0.051-0.521-0.281v-1.642z"
      />
      <path
        fill="currentColor"
        d="M7.551 25.677c0.061-0.402 0.121-0.803 0.185-1.223h0.792c0.063 0.404 0.127 0.812 0.191 1.221 0.023 0.003 0.047 0.007 0.071 0.011 0.038-0.102 0.081-0.202 0.114-0.305 0.197-0.615 0.399-1.229 0.584-1.847 0.051-0.171 0.125-0.209 0.299-0.191 1.075 0.111 1.762 0.856 1.768 1.938 0.003 0.597 0.003 1.194 0 1.791-0.002 0.572-0.229 0.802-0.801 0.852-1.771 0.154-3.542 0.149-5.312-0.008-0.382-0.034-0.675-0.285-0.681-0.662-0.012-0.778-0.028-1.56 0.030-2.335 0.066-0.876 0.888-1.563 1.772-1.576 0.15-0.003 0.179 0.076 0.213 0.184 0.207 0.646 0.415 1.292 0.624 1.938 0.024 0.073 0.050 0.146 0.076 0.218 0.025-0.002 0.050-0.003 0.075-0.005z"
      />
      <path
        fill="currentColor"
        d="M8.004 12.714c-0.549 0.325-1.13 0.496-1.683 0.539-0.282 0.617-0.547 1.195-0.843 1.843-0.124-0.275-0.223-0.479-0.311-0.687-0.149-0.355-0.283-0.717-0.445-1.066-0.027-0.058-0.159-0.095-0.243-0.096-0.508-0.003-0.98-0.133-1.425-0.371-0.071-0.038-0.134-0.090-0.221-0.149 0.699-0.568 0.728-1.355 0.755-2.134 0.020-0.557 0.055-1.105 0.307-1.618 0.375-0.762 1.112-0.954 1.813-0.868 0.086 0.010 0.206 0.047 0.241 0.11 0.125 0.223 0.335 0.246 0.549 0.299 0.463 0.113 0.612 0.496 0.709 0.9 0.044 0.183 0.052 0.378 0.054 0.567 0.010 1.427 0.040 1.755 0.744 2.732z"
      />
      <path
        fill="currentColor"
        d="M26.463 23.562c-0.545 0.331-1.137 0.494-1.691 0.542-0.279 0.619-0.538 1.193-0.828 1.836-0.184-0.386-0.346-0.705-0.489-1.033-0.101-0.232-0.164-0.481-0.271-0.709-0.028-0.060-0.155-0.102-0.237-0.102-0.508-0.003-0.98-0.131-1.426-0.368-0.071-0.038-0.136-0.088-0.227-0.148 0.738-0.613 0.727-1.454 0.758-2.279 0.023-0.61 0.095-1.203 0.454-1.725 0.401-0.584 1.161-0.704 1.679-0.614 0.086 0.015 0.199 0.051 0.239 0.115 0.121 0.196 0.3 0.234 0.505 0.278 0.476 0.1 0.647 0.486 0.736 0.902 0.055 0.256 0.064 0.527 0.057 0.79-0.022 0.882 0.095 1.718 0.691 2.422 0.020 0.024 0.030 0.057 0.049 0.094z"
      />
      <path
        fill="currentColor"
        d="M17.635 6.673c-0.134-0.316-0.252-0.593-0.402-0.946 0.615 0.287 1.261 0.318 1.733 0.764 0.248 0.234 0.367 0.526 0.369 0.864 0.002 0.431 0.004 0.862-0.001 1.293-0.003 0.318-0.123 0.435-0.435 0.435-1.881 0.001-3.762 0.001-5.643 0-0.348 0-0.44-0.093-0.443-0.445-0.002-0.415 0-0.829-0.001-1.243-0.002-0.481 0.206-0.871 0.621-1.083 0.442-0.226 0.927-0.37 1.463-0.577-0.143 0.344-0.255 0.613-0.391 0.939 0.235 0 0.435 0.008 0.634-0.003 0.169-0.009 0.267 0.037 0.337 0.209 0.138 0.335 0.307 0.658 0.469 0.983 0.030 0.059 0.090 0.103 0.136 0.154 0.046-0.054 0.107-0.101 0.137-0.162 0.162-0.335 0.323-0.67 0.47-1.012 0.060-0.139 0.144-0.18 0.286-0.174 0.205 0.009 0.41 0.003 0.659 0.003z"
      />
      <path
        fill="currentColor"
        d="M22.755 24.363c-0.139 0.333-0.252 0.603-0.386 0.924 0.249 0 0.46 0.009 0.67-0.003 0.158-0.009 0.234 0.056 0.297 0.196 0.154 0.347 0.323 0.687 0.491 1.028 0.023 0.047 0.071 0.082 0.107 0.122 0.045-0.042 0.108-0.076 0.133-0.127 0.17-0.349 0.322-0.707 0.503-1.051 0.041-0.079 0.165-0.149 0.258-0.159 0.204-0.024 0.412-0.008 0.669-0.008-0.131-0.313-0.249-0.594-0.396-0.945 0.611 0.286 1.25 0.317 1.72 0.754 0.257 0.239 0.379 0.536 0.38 0.885 0.001 0.422 0.003 0.845-0.001 1.268-0.003 0.336-0.117 0.45-0.446 0.45-1.873 0.001-3.745 0.001-5.619 0-0.366 0-0.455-0.090-0.457-0.455-0.002-0.406 0-0.812-0.001-1.218 0-0.556 0.267-0.955 0.759-1.177 0.404-0.182 0.833-0.308 1.32-0.483z"
      />
      <path
        fill="currentColor"
        d="M6.434 20.89c-0.003-0.951 0.822-1.758 1.734-1.701 1.064 0.067 1.784 0.922 1.641 1.943-0.081 0.578-0.33 1.072-0.745 1.479-0.552 0.543-1.277 0.547-1.84 0.019-0.475-0.446-0.788-1.136-0.79-1.74z"
      />
      <path
        fill="currentColor"
        d="M26.516 8.11c1.125 0.001 1.896 1.015 1.63 2.161-0.113 0.486-0.342 0.917-0.713 1.259-0.616 0.568-1.339 0.546-1.912-0.065-0.492-0.524-0.771-1.141-0.687-1.874 0.1-0.87 0.808-1.482 1.682-1.481z"
      />
      <path
        fill="currentColor"
        d="M15.969 14.725c0.98-0.006 1.778 0.776 1.789 1.754 0.010 0.989-0.771 1.792-1.764 1.812-0.971 0.020-1.798-0.795-1.8-1.775-0.003-0.994 0.781-1.784 1.776-1.791z"
      />
      <path
        fill="currentColor"
        d="M14.068 17.871c0.413 0.3 0.796 0.579 1.194 0.868-0.297 0.411-0.625 0.866-0.954 1.32-0.404 0.556-0.808 1.113-1.214 1.667-0.236 0.322-0.453 0.351-0.777 0.115-0.107-0.078-0.216-0.154-0.322-0.233-0.287-0.213-0.326-0.428-0.115-0.721 0.561-0.78 1.127-1.557 1.691-2.334 0.175-0.241 0.351-0.481 0.497-0.681z"
      />
      <path
        fill="currentColor"
        d="M16.687 18.743c0.405-0.296 0.787-0.575 1.195-0.873 0.141 0.194 0.314 0.432 0.487 0.67 0.559 0.77 1.12 1.54 1.678 2.312 0.246 0.34 0.208 0.538-0.137 0.789-0.114 0.083-0.225 0.169-0.341 0.249-0.242 0.166-0.484 0.14-0.654-0.092-0.754-1.028-1.502-2.060-2.227-3.055z"
      />
      <path
        fill="currentColor"
        d="M16.701 14.279h-1.463c0-0.038 0-0.118 0-0.198 0-1.16 0-2.319 0-3.478 0-0.42 0.139-0.559 0.567-0.565 0.166-0.002 0.332-0.005 0.497 0.006 0.233 0.017 0.404 0.166 0.404 0.392 0.003 1.283-0.004 2.566-0.009 3.849 0 0.008-0.008 0.014 0.003-0.006z"
      />
      <path
        fill="currentColor"
        d="M14.075 15.125c-0.149 0.462-0.294 0.911-0.448 1.388-0.213-0.068-0.463-0.146-0.711-0.227-0.936-0.304-1.871-0.61-2.807-0.914-0.357-0.116-0.461-0.306-0.351-0.658 0.044-0.142 0.090-0.284 0.139-0.425 0.11-0.318 0.313-0.432 0.634-0.332 0.615 0.193 1.227 0.396 1.84 0.596 0.549 0.18 1.097 0.362 1.645 0.544 0.039 0.013 0.075 0.034 0.061 0.028z"
      />
      <path
        fill="currentColor"
        d="M18.327 16.512c-0.157-0.478-0.303-0.919-0.458-1.392 0.242-0.081 0.535-0.18 0.83-0.276 0.88-0.287 1.76-0.572 2.64-0.857 0.42-0.136 0.585-0.052 0.727 0.364 0.037 0.109 0.073 0.219 0.112 0.328 0.132 0.368 0.045 0.567-0.325 0.689-1.052 0.347-2.106 0.687-3.16 1.030-0.132 0.043-0.265 0.083-0.366 0.114z"
      />
      <path
        fill="currentColor"
        d="M27.302 12.266c-0.123 0.256-0.226 0.488-0.347 0.711-0.027 0.050-0.113 0.091-0.176 0.097-0.644 0.061-0.645 0.059-0.926-0.52-0.042-0.087-0.076-0.177-0.123-0.288h1.572z"
      />
      <path
        fill="currentColor"
        d="M7.351 23.354h1.573c-0.128 0.265-0.237 0.502-0.361 0.731-0.023 0.043-0.106 0.072-0.163 0.077q-0.646 0.056-0.924-0.53c-0.041-0.087-0.079-0.176-0.125-0.278z"
      />
    </SVGIcon>
  );
};

export default GroupTrackIcon;
